import { currencyVar, countryVar } from 'graphql/apolloReactiveVariables';
import {
  DEFAULT_COUNTRY,
  DEFAULT_CURRENCY,
} from 'helpers/constants';

export default function setVendorCurrency(vendorData) {
  if (!vendorData) {
    return null;
  } else {
    const currency = vendorData.preferences?.currency?.[0] || DEFAULT_CURRENCY;
    const country = vendorData.preferences?.countryCode?.[0] || DEFAULT_COUNTRY;

    currencyVar(currency);
    countryVar(country);

    return true;
  }
}
