import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { SEARCH_VENDOR_CUSTOMERS } from 'graphql/query/vendors';
import { useLazyQuery } from '@apollo/client';

import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';

import StyledInput from 'components/shared/Inputs/StyledInput';
import useDebounce from 'helpers/useDebounce';

import CustomPopover from 'components/shared/Popover/CustomPopover';
import { getCustomerAddress } from 'helpers/getCustomerAddress';
import { Tooltip } from '@material-ui/core';

const useMaterialStyles = makeStyles(({ spacing, palette }) => ({
  orderDetailsCustomerSearchPaper: ({ parentWidth }) => ({
    width: parentWidth,
    maxWidth: parentWidth,
    borderRadius: 8,
    padding: spacing(1.5),
    minHeight: 200,
    background: palette.background.paper,
    border: palette.border.grey,
  }),
  customPopoverPaperClass: {
    borderRadius: 8,
    marginTop: spacing(0.5),
  },
  listContainer: {
    width: '100%',
  },
  noProductSearchResults: {
    padding: spacing(1),
  },
  listItemRoot: {
    margin: spacing(0.5, 0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '&:hover': {
      background: 'rgba(255, 81, 0, 0.08)',
      borderRadius: 4,
    },
  },
  loader: {
    position: 'absolute',
    marginTop: spacing(1),
  },
  warningIcon: {
    marginLeft: spacing(1),
    color: palette.warning.main,
    width: 20,
    height: 20,
  },
}));

function OrderDetailsCustomerSearch({
  anchorEl,
  handleClose,
  handleSetCustomer,
}) {
  const classes = useMaterialStyles({
    parentWidth: anchorEl?.offsetWidth || 500,
  });
  const { t } = useTranslation();

  const searchInputRef = useRef(null);

  const debounceRequest = useDebounce(500);

  const [searchInput, setSearchInput] = useState('');
  const [hasSearchResults, setSearchResultsAvailability] = useState(true);

  const [
    getCustomersSearch,
    { data: searchData, loading: searchLoading },
  ] = useLazyQuery(SEARCH_VENDOR_CUSTOMERS, {
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    onCompleted: searchResponse => {
      const customerResults =
        searchResponse?.searchVendorCustomers?.customers || [];
      if (!customerResults.length && hasSearchResults) {
        setSearchResultsAvailability(false);
      } else if (customerResults.length && !hasSearchResults) {
        setSearchResultsAvailability(true);
      }
    },
  });

  const searchResults = searchData?.searchVendorCustomers?.customers || [];

  useEffect(() => {
    if (searchInput.length) {
      debounceRequest(() =>
        getCustomersSearch({
          variables: {
            after: 1,
            searchPhrase: searchInput,
            pageSize: 50,
          },
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInput]);

  useEffect(() => {
    if (anchorEl) {
      const timer = setTimeout(() => {
        // eslint-disable-next-line no-unused-expressions
        searchInputRef?.current?.focus();
        clearTimeout(timer);
      }, 100);
    }
  }, [anchorEl]);

  const handleInputSearch = event => {
    setSearchInput(event.target.value);
  };

  const isOpen = Boolean(anchorEl);

  return (
    <CustomPopover
      selectorIsOpen={isOpen}
      anchorEl={anchorEl}
      handleCloseSelector={handleClose}
      popoverContainerStyling={classes.orderDetailsCustomerSearchPaper}
      customPopoverPaperClass={classes.customPopoverPaperClass}
    >
      <Grid container>
        <StyledInput
          variant="outlined"
          value={searchInput}
          placeholder={t('products.search placeholder')}
          onChange={handleInputSearch}
          fullWidth
          inputRef={searchInputRef}
          startAdornment={
            <InputAdornment position="start">
              <SearchRoundedIcon />
            </InputAdornment>
          }
        />
        <List
          component="nav"
          aria-label="search product titles"
          color="primary"
          className={classes.listContainer}
          disablePadding
        >
          {!hasSearchResults && (
            <Typography
              align="center"
              className={classes.noProductSearchResults}
              variant="body2"
              color="textSecondary"
            >
              {t('common.no results')}
            </Typography>
          )}
          {searchLoading && (
            <Grid container justifyContent="center">
              <CircularProgress className={classes.loader} color="primary" />
            </Grid>
          )}
          {searchResults?.map(
            ({ _id, vendorClientId, contactData, status }) => {
              const customerAddress = getCustomerAddress(contactData);
              return (
                <ListItem
                  button
                  classes={{
                    root: classes.listItemRoot,
                  }}
                  key={_id}
                  onClick={() => handleSetCustomer(_id)}
                >
                  <Grid container alignItems="center">
                    <Typography
                      variant="body2"
                      color="inherit"
                      className={classes.productTitle}
                    >
                      {`${vendorClientId} | ${contactData.businessName}`}
                    </Typography>
                    {status === 'draft' && (
                      <Tooltip
                        title={t('aiOrders.errors.draft customer')}
                        arrow
                        placement="top"
                        classes={{
                          popper: classes.tooltipPopper,
                          tooltip: classes.tooltip,
                          touch: classes.tooltipTouch,
                        }}
                        PopperProps={{
                          disablePortal: true,
                        }}
                      >
                        <WarningRoundedIcon className={classes.warningIcon} />
                      </Tooltip>
                    )}
                  </Grid>
                  <br />
                  <Typography variant="caption" color="textSecondary">
                    {customerAddress}
                  </Typography>
                </ListItem>
              );
            }
          )}
        </List>
      </Grid>
    </CustomPopover>
  );
}

OrderDetailsCustomerSearch.defaultProps = {
  anchorEl: undefined,
};

OrderDetailsCustomerSearch.propTypes = {
  anchorEl: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  handleSetCustomer: PropTypes.func.isRequired,
};

export default OrderDetailsCustomerSearch;
