export default function generateStringToHexColor(input) {
  if (!input || typeof input !== 'string') {
    return '#FFFFFF'; // Return white if input is invalid
  }

  let hash = 0;
  for (let i = 0; i < input.length; i += 1) {
    hash = input.charCodeAt(i) + (hash * 31 - hash); // Generate hash
  }

  // Convert hash to a hex color code
  let color = '#';
  for (let i = 0; i < 3; i += 1) {
    let value = Math.floor(hash / 256 ** i) % 256;
    value = Math.floor((value + 255) / 2); // Adjust to make the color brighter
    color += `00${value.toString(16)}`.slice(-2); // Convert to hex and pad if needed
  }

  return color;
}
