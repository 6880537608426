import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import noop from 'lodash/noop';

import { useLazyQuery } from '@apollo/client';
import { GET_ORDER_CSV, GET_ORDER_PDF } from 'graphql/query/orders';

import { makeStyles } from '@material-ui/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';

import CsvIcon from '@material-ui/icons/CollectionsBookmarkOutlined';
import PdfIcon from '@material-ui/icons/PictureAsPdfOutlined';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import { downloadFile } from 'helpers/utils';
import EmptyTableField from 'components/shared/EmptyTableField';
import OrderStatusReducer from 'components/OrdersTable/OrderStatusReducer';
import { useSharedTableStyles } from 'components/shared/styles/sharedTableStyles';

const useStyles = makeStyles(theme => ({
  orderCustomerTitle: {
    width: 250,
  },
  creationCustomerIdTitle: {
    color: theme.palette.secondary.darkGray,
    whiteSpace: 'nowrap',
  },
  downloadFileButton: {
    background: 'rgba(255, 81, 0, 0.15)',
    color: theme.palette.primary.main,
    minWidth: 0,
    width: 32,
    height: 32,
    boxShadow: theme.palette.customShadows.button,
    borderRadius: 6,
    padding: 0,
    margin: `0px ${theme.spacing(0.5)}px`,
  },
  cash: {
    color: `${theme.palette.secondary.darkGray}`,
  },
  businessName: {
    whiteSpace: 'break-spaces',
  },
}));

const DOC_TYPE_INVOICE = 'invoice';
const MONDU = 'mondu';
export default function OrdersTableRowCells({
  rowData,
  isPendingOrderMondu,
  setPanelOpen,
  isPanelOpen,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const sharedTableStyles = useSharedTableStyles();

  const {
    customer,
    orderNumber,
    deliveryDate,
    orderedAt,
    _id,
    status,
    net,
    totalPrice,
    gross,
  } = rowData;
  const documents = rowData?.paymentMethod?.documents ?? [];
  const deadline = rowData?.paymentMethod?.paymentInfo?.deadline;
  const hasActiveInvoice =
    documents.find(
      item => item.type === DOC_TYPE_INVOICE && !item.deletedAt
    ) !== undefined;
  const isMondu = rowData?.paymentMethod?.name === MONDU;

  const [
    getOrderCsv,
    { data: orderCsvString, loading: orderCsvLoading },
  ] = useLazyQuery(GET_ORDER_CSV);

  const [
    getOrderPdf,
    { data: orderPdf, loading: orderPdfLoading },
  ] = useLazyQuery(
    GET_ORDER_PDF,
    {
      fetchPolicy: 'no-cache',
    }
    /* FIXME: add network-only to disable caching pdf strings when fix is available within new apollo release.
    Ref: https://github.com/apollographql/apollo-client/issues/6636 */
  );

  const downloadOrderCsv = async () => {
    try {
      if (orderCsvLoading) return;
      getOrderCsv({
        variables: {
          orderId: _id,
        },
      });
    } catch (error) {
      console.error('CSV_DOWNLOAD_ERROR', error.message);
    }
  };

  const downloadOrderPdf = async () => {
    try {
      if (orderPdfLoading) return;
      getOrderPdf({
        variables: {
          orderId: _id,
        },
      });
    } catch (error) {
      console.error('CSV_DOWNLOAD_ERROR', error.message);
    }
  };

  React.useEffect(() => {
    try {
      if (!orderCsvString?.downloadOrderCsv?.csvString) return;
      const { fileName, csvString } = orderCsvString?.downloadOrderCsv;
      const csvContent = JSON.parse(csvString);
      const file = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      downloadFile(file, fileName);
    } catch (error) {
      console.error('CSV_DOWNLOAD_ERROR', error.message);
    }
  }, [orderCsvString]);

  React.useEffect(() => {
    try {
      if (
        !orderPdf?.downloadOrderPdf?.pdf ||
        !orderPdf?.downloadOrderPdf?.fileName
      ) return;
      const { fileName, pdf } = orderPdf?.downloadOrderPdf;
      const pdfContent = JSON.parse(pdf);
      const file = new Blob([Buffer.from(pdfContent.data)], {
        type: 'application/pdf',
      });
      downloadFile(file, fileName);
    } catch (error) {
      console.error('CSV_DOWNLOAD_ERROR', error.message);
    }
  }, [orderPdf]);

  let paymentStatusCalculated = '';
  if (isMondu) {
    if (rowData?.paymentMethod?.paymentInfo?.vendorPayoutDate) {
      paymentStatusCalculated = t('orders.paid');
    } else if (!hasActiveInvoice) {
      paymentStatusCalculated = t('orders.waiting for invoice');
    } else {
      paymentStatusCalculated = t('orders.to be paid');
    }
  }
  const netValue = net || totalPrice;
  return (
    <>
      <TableCell
        className={classNames(
          sharedTableStyles.tableCell,
          classes.orderCustomerTitle
        )}
      >
        <Grid container direction="column" wrap="nowrap">
          {isPendingOrderMondu && (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setPanelOpen(!isPanelOpen)}
            >
              {isPanelOpen ? (
                <KeyboardArrowDownIcon />
              ) : (
                <KeyboardArrowRightIcon />
              )}
            </IconButton>
          )}
          <Typography className={classes.businessName} variant="body2">
            {customer?.contactData?.businessName}
          </Typography>
          <Typography
            variant="body2"
            className={classes.creationCustomerIdTitle}
          >
            {t('orders.customer')} {customer?.vendorClientId}
          </Typography>
        </Grid>
      </TableCell>
      <TableCell className={sharedTableStyles.tableCell}>
        <Grid container direction="column" wrap="nowrap">
          {orderNumber ? (
            <Typography variant="body2">{orderNumber}</Typography>
          ) : (
            <EmptyTableField />
          )}
        </Grid>
      </TableCell>
      <TableCell className={sharedTableStyles.tableCell}>
        <Typography variant="body2">
          {moment(deliveryDate).format('DD/MM/YY')}
        </Typography>
      </TableCell>
      <TableCell className={sharedTableStyles.tableCell}>
        <Typography variant="body2">
          {moment(orderedAt).format('DD/MM/YY')}
        </Typography>
      </TableCell>
      <TableCell className={sharedTableStyles.tableCell}>
        <Typography variant="body2">{netValue?.toFixed(2)}</Typography>
      </TableCell>
      <TableCell className={sharedTableStyles.tableCell}>
        <Typography variant="body2">{gross?.toFixed(2)}</Typography>
      </TableCell>
      <TableCell className={sharedTableStyles.tableCell}>
        <Typography variant="body2">{paymentStatusCalculated}</Typography>
      </TableCell>
      <TableCell className={sharedTableStyles.tableCell}>
        <Typography variant="body2">
          {deadline && moment(deadline).format('DD/MM/YY')}
        </Typography>
      </TableCell>
      <TableCell className={sharedTableStyles.tableCell}>
        {isMondu ? (
          <Typography variant="body2">{t('orders.pay later')}</Typography>
        ) : (
          <Typography variant="body2" className={classes.cash}>
            {t('orders.cash')}
          </Typography>
        )}
      </TableCell>
      <TableCell className={sharedTableStyles.tableCell}>
        <Grid container wrap="nowrap">
          <IconButton
            size="small"
            className={classes.downloadFileButton}
            onClick={orderPdfLoading ? noop : downloadOrderPdf}
          >
            {orderPdfLoading ? (
              <CircularProgress size={20} color="primary" />
            ) : (
              <PdfIcon fontSize="small" />
            )}
          </IconButton>
          <IconButton
            size="small"
            className={classes.downloadFileButton}
            onClick={orderCsvLoading ? noop : downloadOrderCsv}
          >
            {orderCsvLoading ? (
              <CircularProgress size={20} color="primary" />
            ) : (
              <CsvIcon fontSize="small" />
            )}
          </IconButton>
        </Grid>
      </TableCell>
      <TableCell className={sharedTableStyles.tableCell}>
        <OrderStatusReducer status={status} />
      </TableCell>
    </>
  );
}

OrdersTableRowCells.propTypes = {
  rowData: PropTypes.object.isRequired,
  isPendingOrderMondu: PropTypes.bool.isRequired,
  setPanelOpen: PropTypes.func.isRequired,
  isPanelOpen: PropTypes.bool.isRequired,
};
