import { createTheme } from '@material-ui/core/styles';

const theme = (type) => {
  const isDark = type === 'dark';
  const originalTheme = createTheme();

  return createTheme({
    palette: {
      type,
      background: {
        default: isDark ? '#1E1E1E' : '#fafafa',
        muted: isDark ? '#1E1E1E' : '#f1f1f1',
        mutedLight: isDark ? '#1E1E1E' : '#F6F6F6',
        paper: isDark ? '#1F1F1F' : '#fff',
        container: isDark ? '#121212' : '#EFEFEF',
        button: isDark ? '#121212' : '#fff',
        border: '#C7C9CC'
      },
      text: {
        primary: isDark ? 'rgba(255, 255, 255, 0.88)' : '#1F1F1F',
        secondary: isDark ? 'rgba(255, 255, 255, 0.6)' : '#797A7C',
        inverted: isDark ? '#1F1F1F' : '#FFF',
      },
      primary: {
        main: '#FF5100',
        contrastText: '#fff',
      },
      secondary: {
        main: isDark ? '#FFF' : '#1F1F1F',
        contrastText: '#fff',
        lightGray: '#EFEFEF',
        darkGray: isDark ? 'rgba(255, 255, 255, 0.88)' : '#868686',
        textBlack: '#4A4A4A',
      },
      error: {
        main: '#CD5058',
        background: '#fce8e6',
      },
      errorLight: {
        main: '#CD5058',
        background: '#fff0f2',
      },
      success: {
        main: '#52C89E',
      },
      action: {
        disabledBackground: 'rgba(0, 0, 0, 0.15)',
      },
      border: {
        grey: isDark
          ? '1px solid rgba(255, 255, 255, 0.24)'
          : '1px solid #C7C9CC',
        lightGrey: isDark
          ? '1px solid rgba(255, 255, 255, 0.12)'
          : '1px solid #EFEFEF',
        lightGreyThinLight: isDark
          ? '0.5px solid rgba(255, 255, 255, 0.12)'
          : '0.5px solid #EFEFEF',
        lightGreyThinDark: isDark
          ? '1px solid rgba(255, 255, 255, 0.12)'
          : '1px solid #C7C9CC',
        lightGreyBold: isDark
          ? '3px solid rgba(255, 255, 255, 0.12)'
          : '3px solid #EFEFEF',
        errorBold: isDark ? '3px solid #ff6e60' : '3px solid #ff6e60',
        darkGrey: '1px solid #D8D8D8',
        lightModeOnly: isDark ? 'none' : '1px solid #FFFFFF',
        contrast: isDark ? '1px solid #fff' : '1px solid #1f1f1f',
      },
      customShadows: {
        button: isDark
          ? '0px 0px 10px rgba(0, 0, 0, 0.2)'
          : '0px 0px 10px rgba(202, 202, 202, 0.25)',
        popover: isDark
          ? '0px 0px 16px rgba(0, 0, 0, 0.6)'
          : '0px 0px 16px rgba(195, 195, 195, 0.25)',
      },
    },
    shadows: originalTheme.shadows.map((shadow, index) => {
      if (index === 1) {
        return isDark
          ? '0px 0px 60px #000000'
          : '0px 0px 60px rgba(202, 202, 202, 0.25)';
      } else return shadow;
    }),
    breakpoints: {
      keys: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'],
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1600,
        xxl: 1920,
      },
    },
    overrides: {
      MuiOutlinedInput: {
        notchedOutline: {
          border: 'none',
        },
      },
      MuiTypography: {
        body1: {
          lineHeight: 1.15,
        },
      },
      MuiDivider: {
        root: {
          backgroundColor: '#EFEFEF',
        },
      },
      MuiButton: {
        root: {
          textTransform: 'none',
          borderRadius: 8
        },
        contained: {
          boxShadow: 'none',
        },
      },
      MuiTooltip: {
        tooltip: {
          // backgroundColor: isDark ? '#1F1F1F' : '#fff',
          padding: '8px',
          // color: isDark ? 'rgba(255, 255, 255, 0.88)' : '#1F1F1F',
          fontSize: 14,
          maxWidth: 300,
        },
      },
      MuiInputLabel: {
        filled: {
          color: isDark ? 'rgba(255, 255, 255, 0.88)' : '#868686',
        },
      },
      MuiFilledInput: {
        root: {
          borderRadius: '8px',
        },
      },
      MuiSelect: {
        root: {
          fontSize: '0.875rem',
        },
        select: {
          fontSize: '0.875rem',
        },
      },
      MuiMenuItem: {
        root: {
          fontSize: '0.875rem',
        },
      },
      MuiSwitch: {
        thumb: {
          border: '1px solid #FF5100',
          boxSizing: 'border-box',
        },
        track: {
          background: isDark ? 'rgba(255, 255, 255, 0.88)' : '#868686',
        },
      },
      MuiPaper: {
        rounded: {
          borderRadius: 8,
        },
      },
    },
  });
};

export default theme;
