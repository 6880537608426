export function getCustomerAddress(contactData) {
  if (!contactData) {
    return '';
  } else {
    const { city = '', street = '', houseNumber = '', zip = '' } = contactData;
    const result = [`${street} ${houseNumber}`, zip, city].reduce(
      (arr, currentValue) => {
        if (!currentValue?.length) return arr;

        if (!arr?.length) {
          arr.push(currentValue);
        } else {
          arr.push(`, ${currentValue}`);
        }
        return arr;
      },
      []
    );
    return result.join('');
  }
}
