import { gql } from '@apollo/client';

export const INIT_CACHE = gql`
  query initCache_local {
    user @client {
      accessToken
      refreshToken
      idToken
      error
      isRememberChecked
      userData {
        name
        email
        userId
        picture
        isAdmin
        accountId
      }
    }
    networkStatus @client {
      isOnline
    }
    notification @client {
      timeout
      message
      type
      isOpen
      isManual
    }
    impersonate @client {
      impersonateId
      name
    }
    theme @client {
      type
    }
  }
`;

export const GET_USER = gql`
  query getUser_local {
    user @client {
      accessToken
      refreshToken
      idToken
      error
      isRememberChecked
      userData {
        name
        email
        userId
        picture
        isAdmin
        accountId
      }
    }
  }
`;

export const NETWORK_STATUS = gql`
  query networkStatus_local {
    networkStatus @client {
      isOnline
    }
  }
`;

export const GET_USER_ID = gql`
  query getUserId_local {
    user @client {
      userData {
        userId
      }
    }
  }
`;

export const GET_NOTIFICATION = gql`
  query getNotification_local {
    notification @client {
      timeout
      message
      type
      isOpen
      isManual
    }
  }
`;

export const GET_VENDOR_ACCOUNTS = gql`
  query vendors($searchPhrase: String, $pageSize: Int, $after: Int) {
    allVendorAccounts(
      searchPhrase: $searchPhrase
      pageSize: $pageSize
      after: $after
    ) {
      vendors {
        _id
        contactData {
          businessName
        }
        contacts {
          email
        }
        branding {
          primaryColor
        }
      }
      totalResults
    }
  }
`;

export const IMPERSONATE_ACCOUNT = gql`
  query impersonateAccount_local {
    impersonate @client {
      impersonateId
      name
    }
  }
`;

export const GET_USER_PROFILE = gql`
  query getUserProfile {
    userProfile {
      _id
      createdAt
      updatedAt
      name
      email {
        email
        verified
      }
      isAdmin
      phone
    }
  }
`;

export const GET_VENDOR_PROFILE = gql`
  query getVendorProfile {
    vendorAccount {
      _id
      contactData {
        businessName
      }
      ftpIntegration {
        enabled
      }
      apiIntegration {
        enabled
      }
      preferences {
        newsletter {
          enabled
          fromName
          fromEmail
          staticInfo {
            workingHours
            workingHoursCaption
            phone
            email
            disclaimer
          }
        }
        expiringProducts {
          enabled
        }
        pdfCatalog {
          enabled
        }
        salesRepsEnabled
        twilio {
          enabled
        }
        defaultProductImage
        currency
        countryCode
      }
    }
  }
`;

export const THEME_TYPE = gql`
  query themeType_local {
    theme @client {
      type
    }
  }
`;
