import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';

import { useMutation, useReactiveVar } from '@apollo/client';
import { SET_NOTIFICATION } from 'graphql/mutation/user';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import TimeRoundedIcon from '@material-ui/icons/AccessTimeRounded';
// import { ReactComponent as InstructionsIcon } from 'assets/instructions.svg';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import { ReactComponent as TrashIcon } from 'assets/aiOrders/trash.svg';

import OrderDetailsMainInfo from 'components/AIOrderReview/OrderDetailsMainInfo';
import OrderDetailsAllProductsList from 'components/AIOrderReview/ProductsList';
import {
  checkCartV2Validity,
  generateNewCartProductV2,
  NEW_PRODUCT_PREFIX,
  NEW_PRODUCT_ROW_PREFIX,
} from 'helpers/cartValidationHelpers';
import { NOTIFICATION_STATUS } from 'helpers/constants';
import ModalLayout from 'components/shared/ModalLayout';
import { isProductTypeDisplayingEnabledVar } from 'graphql/apolloReactiveVariables';
import {
  ACCEPT_INCOMING_ORDER,
  UPDATE_INCOMING_ORDER_PRODUCTS,
} from 'graphql/mutation/incomingOrders';
import parseValidationErrors from 'helpers/parseValidationErrors';

const useStyles = makeStyles(({ spacing, palette }) => ({
  orderDetailsContainer: {
    height: '100%',
    marginLeft: spacing(1),
    paddingTop: spacing(1),
  },
  orderDetailsHeader: {
    marginBottom: spacing(2),
  },
  actionButton: {
    height: 40,
    borderRadius: 40,
    minWidth: 'fit-content',
    background: palette.background.paper,
  },
  timeIcon: {
    marginRight: spacing(1),
  },
  addProductButton: {
    height: 40,
    alignSelf: 'flex-end',
    borderRadius: 40,
    margin: spacing(3, 1),
    color: palette.common.white,
    background: palette.warning.main,
    '&:hover': {
      background: palette.warning.dark,
    },
  },
  aiOrderDetailsActionsContainer: {
    height: 88,
    // width: `calc(100% - ${spacing(3)}px)`,
    marginTop: spacing(1.5),
    marginBottom: spacing(1.5),
    borderRadius: 8,
    background: palette.background.paper,
    border: palette.border.grey,
    position: 'sticky',
    bottom: 24,
    left: 0,
  },
  aiOrdersRemoveOrderButton: {
    height: 40,
    width: 40,
    borderRadius: '50%',
    border: palette.border.grey,
    marginRight: spacing(2),
  },
  actionButtonsDivider: {
    margin: spacing(2),
  },
  aiOrdersAcceptOrderButton: {
    height: 40,
    borderRadius: 40,
    marginRight: spacing(2),
    whiteSpace: 'nowrap',
  },
  modalActionButton: {
    height: 50,
    margin: spacing(4.5, 1.5, 1.5),
    boxShadow: 'none',
    '&:first-child': {
      marginLeft: 0,
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
  displayProductTypesSwitch: {
    marginTop: spacing(1.5),
  },
  rowMarginTop: {
    marginTop: spacing(1),
  },
  rowMarginBottom: {
    marginBottom: spacing(1),
  },
}));

export const CONFIRMATION_MODAL_TYPES = {
  SAVE: 'SAVE',
  ACCEPT: 'ACCEPT',
};

export default function OrderDetails({
  orderId,
  orderNumber,
  customer,
  sender,
  deliveryOptions,
  orderComment,
  orderProducts,
  receivedAt,
  status,
  isUpdateCustomerLoading,
  handleUpdateIncomingOrderCustomer,
  handleUpdateIncomingOrderDeliveryDate,
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  // const history = useHistory();

  const isProductTypeDisplayingEnabled = useReactiveVar(
    isProductTypeDisplayingEnabledVar
  );

  const [
    updateIncomingOrder,
    { loading: isUpdateIncomingOrderLoading },
  ] = useMutation(UPDATE_INCOMING_ORDER_PRODUCTS);
  const [acceptIncomingOrder, { loading: isAcceptOrderLoading }] = useMutation(
    ACCEPT_INCOMING_ORDER
  );
  const [setNotification] = useMutation(SET_NOTIFICATION);

  const [warningModalState, setIsWarningModalOpen] = useState({
    isOpen: false,
    type: null,
  }); // warning modal if the there are an empty products
  const [productsState, setProductsState] = useState({
    products: [], // all incoming order products
    productsData: {}, // complete product data, for variant selection, key === product._id
    productErrors: {}, // errors, key === productRowId
    updatedProducts: {}, // all updated products, key === productRowId
  });

  const hasErrors = Object.keys(productsState.productErrors).length;

  const handleAddProduct = () => {
    const newProduct = generateNewCartProductV2();
    setProductsState(prevState => ({
      ...prevState,
      products: [...prevState.products, newProduct],
    }));
  };

  const handleCloseModal = () => {
    setIsWarningModalOpen({ isOpen: false, type: null });
  };

  const handleRunErrorValidation = () => {
    const invalidProducts = checkCartV2Validity(productsState.products);
    console.log('invalidProducts', invalidProducts);
    // TODO: replace or remove all product row ids that start with 'NEW-PRODUCT'

    const errorIndices = Object.keys(invalidProducts);

    if (errorIndices.length > 0) {
      const productErrors = errorIndices.map(
        index => productsState.products[index]
      );
      setNotification({
        variables: {
          timeout: 4000,
          message: t('aiOrders.errors.products has errors', {
            count: errorIndices.length,
            products: productErrors
              .map((product, index) => {
                const { vendorSpecificId, name } = product?.productCopy || {};
                return `\n${index + 1}. ${vendorSpecificId} - ${name}`;
              })
              .join(''),
          }),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    }
  };

  const handleSaveAiOrderChanges = async () => {
    // DEMO: maybe we need to disable for demo
    // handleRunErrorValidation();
    handleCloseModal();

    const productsInput = Object.values(productsState.updatedProducts)
      .map(productData => {
        if (
          !productData ||
          !productData?.selectedProduct?._id ||
          productData?.selectedProduct?._id?.startsWith(NEW_PRODUCT_PREFIX)
        ) {
          return null;
        }
        return {
          _id: productData.selectedProduct?._id,
          variantId: productData.selectedProduct?.variantId,
          qty: productData.selectedProduct?.qty,
          productRowId: productData._id?.startsWith(NEW_PRODUCT_ROW_PREFIX)
            ? null
            : productData._id,
          attributesKey: null,
        };
      })
      .filter(Boolean);

    console.log('SAVE', {
      updated: productsState.updatedProducts,
      productsInput,
    });

    try {
      const response = await updateIncomingOrder({
        variables: {
          orderId,
          productsInput,
        },
      });
      const responseProducts =
        response?.data?.updateIncomingOrderProducts?.products?.products || [];
      setProductsState(prevState => ({
        ...prevState,
        products: responseProducts,
        productErrors: checkCartV2Validity(responseProducts),
        updatedProducts: {},
      }));
      console.log('response', { response, responseProducts });

      setNotification({
        variables: {
          timeout: 4000,
          message: t('aiOrders.success.order saved'),
          type: NOTIFICATION_STATUS.SUCCESS,
          isOpen: true,
        },
      });
    } catch (error) {
      console.error(error);
      setNotification({
        variables: {
          timeout: 4000,
          message: parseValidationErrors(error) || t('common.something wrong'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    }
  };

  const handleAcceptOrder = async () => {
    // DEMO: maybe we need to disable for demo
    // handleRunErrorValidation();
    handleCloseModal();
    console.log('ACCEPT');
    try {
      await acceptIncomingOrder({
        variables: {
          orderId,
          deliveryOptionIds: deliveryOptions.map(({ _id }) => _id),
          confirmationType: 'manuallyAccepted',
        },
      });
      setNotification({
        variables: {
          timeout: 4000,
          message: t('aiOrders.success.order accepted'),
          type: NOTIFICATION_STATUS.SUCCESS,
          isOpen: true,
        },
      });
    } catch (error) {
      console.error(error);
      setNotification({
        variables: {
          timeout: 4000,
          message: parseValidationErrors(error) || t('common.something wrong'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    }
  };

  // const handleOpenCustomerInstructions = () => {
  //   history.push(
  //     `/orders/ai-instructions?customerId${customer.vendorClientId}`
  //   );
  // };

  const handleOpenConfirmationSaveModal = () => {
    if (hasErrors) {
      setIsWarningModalOpen({
        isOpen: true,
        type: CONFIRMATION_MODAL_TYPES.SAVE,
      });
    } else {
      handleSaveAiOrderChanges();
    }
  };

  const handleOpenConfirmationAcceptModal = () => {
    if (hasErrors) {
      setIsWarningModalOpen({
        isOpen: true,
        type: CONFIRMATION_MODAL_TYPES.ACCEPT,
      });
    } else {
      handleAcceptOrder();
    }
  };

  const handleChangeProductTypeDisplaying = event => {
    isProductTypeDisplayingEnabledVar(event.target.checked);
  };

  const hasMultipleProductTypes =
    [
      ...new Set(
        productsState?.products
          ?.map(p => p.selectedProduct?.productType)
          .filter(Boolean)
      ),
    ].length > 1;

  const disableButtons = isAcceptOrderLoading || isUpdateCustomerLoading;

  return (
    <Grid
      className={classes.orderDetailsContainer}
      container
      item
      xs={6}
      alignItems="flex-start"
    >
      <Grid
        className={classes.orderDetailsHeader}
        container
        item
        xs={12}
        wrap="nowrap"
      >
        <Grid container direction="column" item>
          <Grid
            container
            item
            direction="row"
            wrap="nowrap"
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid container item direction="column">
              <Typography color="textSecondary" variant="caption">
                {t('aiOrders.orderNumber')}
              </Typography>
              <Typography className={classes.documentNumber} variant="h4">
                #{orderNumber}
              </Typography>
            </Grid>
            {customer?.contactData?.businessName ? (
              <Grid container item direction="column">
                <Typography color="textSecondary" variant="caption">
                  {t('aiOrders.customerBusinessName')}
                </Typography>
                <Typography variant="h5" gutterBottom>
                  {customer?.contactData?.businessName}
                </Typography>
              </Grid>
            ) : null}
          </Grid>

          <Grid
            container
            item
            direction="row"
            wrap="nowrap"
            className={classes.rowMarginTop}
          >
            <Grid container item direction="column">
              <Typography color="textSecondary" variant="caption">
                {t('aiOrders.received')}
              </Typography>
              <Grid container alignItems="center">
                {/* <TimeRoundedIcon
                                 className={classes.timeIcon}
                                 color="primary"
                               /> */}
                <Typography variant="body2">
                  {moment(receivedAt).format('MMMM Do YYYY - h:mm:ss a')}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item direction="column">
              <Typography color="textSecondary" variant="caption">
                {t('aiOrders.status')}
              </Typography>
              <Typography variant="body2">
                {t(`aiOrders.statuses.${status}`)}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            wrap="nowrap"
            className={classes.rowMarginTop}
          >
            <Grid container direction="column">
              <Typography color="textSecondary" variant="caption">
                {t('aiOrders.sender')}
              </Typography>
              <Typography variant="body2">{sender?.meta?.email}</Typography>
            </Grid>
            <Grid container direction="column">
              <Typography color="textSecondary" variant="caption">
                {t('aiOrders.subject')}
              </Typography>
              <Typography variant="body2">{sender?.meta?.subject}</Typography>
            </Grid>
          </Grid>
        </Grid>
        {/* <Button
          className={classes.actionButton}
          variant="outlined"
          startIcon={<InstructionsIcon />}
          onClick={handleOpenCustomerInstructions}
        >
          {t('aiOrders.instructions for ai')}
        </Button> */}
      </Grid>

      <OrderDetailsMainInfo
        orderComment={orderComment}
        customer={customer}
        sender={sender}
        deliveryOptions={deliveryOptions}
        isUpdateCustomerLoading={isUpdateCustomerLoading}
        handleUpdateIncomingOrderCustomer={handleUpdateIncomingOrderCustomer}
        handleUpdateIncomingOrderDeliveryDate={
          handleUpdateIncomingOrderDeliveryDate
        }
      />

      <OrderDetailsAllProductsList
        orderProducts={orderProducts}
        productsState={productsState}
        hasMultipleProductTypes={hasMultipleProductTypes}
        setProductsState={setProductsState}
        setNotification={setNotification}
      />

      <Grid
        className={classes.aiOrderDetailsActionsContainer}
        container
        justifyContent="flex-end"
        alignItems="center"
        wrap="nowrap"
      >
        <Grid container wrap="nowrap">
          <Button
            disabled={disableButtons}
            onClick={handleAddProduct}
            variant="contained"
            startIcon={<AddRoundedIcon />}
            className={classes.addProductButton}
          >
            {t('aiOrders.add product')}
          </Button>
          {hasMultipleProductTypes && (
            <FormControlLabel
              className={classes.displayProductTypesSwitch}
              value={isProductTypeDisplayingEnabled}
              control={
                <Switch
                  checked={isProductTypeDisplayingEnabled}
                  onChange={handleChangeProductTypeDisplaying}
                  color="primary"
                />
              }
              label={t('aiOrders.display product types')}
              labelPlacement="bottom"
            />
          )}
        </Grid>
        <Divider
          flexItem
          className={classes.actionButtonsDivider}
          orientation="vertical"
        />
        <Grid container wrap="nowrap" justifyContent="flex-end">
          <IconButton className={classes.aiOrdersRemoveOrderButton}>
            <TrashIcon />
          </IconButton>
          <Button
            className={classes.aiOrdersAcceptOrderButton}
            variant="outlined"
            color="primary"
            disabled={disableButtons}
            onClick={handleOpenConfirmationSaveModal}
          >
            {t('common.modal save changes')}
          </Button>
          <Button
            // DEMO: remove "disable" for demo
            // disabled={hasErrors}
            disabled={disableButtons}
            onClick={handleOpenConfirmationAcceptModal}
            className={classes.aiOrdersAcceptOrderButton}
            variant="contained"
            color="primary"
          >
            {t('common.accept')}
          </Button>
        </Grid>
      </Grid>

      <ModalLayout
        isOpen={warningModalState.isOpen}
        handleClose={handleCloseModal}
        hideCloseButton
        modalTitle=""
      >
        <Typography variant="h5" align="center">
          {warningModalState.type === CONFIRMATION_MODAL_TYPES.ACCEPT
            ? t('aiOrders.errors.empty products accept order', {
                count: hasErrors,
              })
            : t('aiOrders.errors.empty products save changes', {
                count: hasErrors,
              })}
        </Typography>
        <Grid
          className={classes.createCustomGroupModalActions}
          container
          justifyContent="space-between"
          wrap="nowrap"
        >
          <Button
            className={classes.modalActionButton}
            fullWidth
            onClick={handleCloseModal}
            variant="contained"
          >
            {t('common.cancel')}
          </Button>

          <Button
            className={classes.modalActionButton}
            fullWidth
            onClick={
              warningModalState.type === CONFIRMATION_MODAL_TYPES.ACCEPT
                ? handleAcceptOrder
                : handleSaveAiOrderChanges
            }
            variant="contained"
            color="primary"
          >
            {t('common.confirm')}
          </Button>
        </Grid>
      </ModalLayout>
    </Grid>
  );
}

OrderDetails.propTypes = {
  orderId: PropTypes.string.isRequired,
  orderNumber: PropTypes.number.isRequired,
  customer: PropTypes.object,
  sender: PropTypes.object,
  receivedAt: PropTypes.string,
  status: PropTypes.string.isRequired,
  deliveryOptions: PropTypes.array,
  orderComment: PropTypes.string,
  orderProducts: PropTypes.array,
  isUpdateCustomerLoading: PropTypes.bool.isRequired,
  handleUpdateIncomingOrderCustomer: PropTypes.func.isRequired,
  handleUpdateIncomingOrderDeliveryDate: PropTypes.func.isRequired,
};

OrderDetails.defaultProps = {
  customer: {},
  sender: {},
  deliveryOptions: [],
  orderComment: '',
  orderProducts: [],
  receivedAt: '',
};
