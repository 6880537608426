/* eslint-disable max-len */
import { gql } from '@apollo/client';

export const GET_PRODUCTS_FOR_DEALS = gql`
  query productsForDeals($productIds: [ID]!, $dealType: DealType) {
    vendorVariantsForDeals(productIds: $productIds, dealType: $dealType) {
      page
      totalPages
      hasNextPage
      nextPage
      hasPrevPage
      prevPage
      totalResults
      products {
        _id
        name
        vendorSpecificId
        variants {
          _id
          baseUnit
          packaging
          unitsInPackaging
          price
          taxRate
          dealPrice
          dealDetails {
            dealType
          }
          canHandleExpiringDeal
        }
      }
    }
  }
`;

export const GET_DEALS = gql`
  query deals(
    $dealType: DealType
    $pageSize: Int
    $after: Int
    $sortBy: ProductSortingOption
    $sortOrder: Int
  ) {
    vendorDealProducts(
      dealType: $dealType
      pageSize: $pageSize
      after: $after
      sortBy: $sortBy
      sortOrder: $sortOrder
    ) @connection(key: "vendorDealProducts", filter: ["_id"]) {
      page
      totalPages
      hasNextPage
      nextPage
      hasPrevPage
      prevPage
      totalResults
      products {
        _id
        name
        description
        vendorId
        vendorSpecificId
        enabled
        createdAt
        updatedAt
        image
        categories
        dealDetails {
          permanent
          start
          end
        }
        variants {
          _id
          baseUnit
          packaging
          unitsInPackaging
          price
          taxRate
          dealPrice
          dealDetails {
            dealType
            dealPrice
            expiringDetails {
              expiresAt
            }
            enabled
            start
            end
          }
        }
      }
    }
  }
`;
