import { activeAccountIdVar } from 'graphql/apolloReactiveVariables';
import { v4 as uuid } from 'uuid';

export const NEW_PRODUCT_PREFIX = 'NEW-PRODUCT';
export const NEW_PRODUCT_ROW_PREFIX = 'NEW-PRODUCT_ROW';

export function generateNewCartProduct() {
  const productId = `${NEW_PRODUCT_PREFIX}-${uuid()}`;
  return {
    _id: productId,
    variantId: '',
    qty: 0,
    productCopy: {
      _id: productId,
      name: '',
      description: '',
      vendorId: activeAccountIdVar(),
      vendorSpecificId: '',
      image: '',
      imageUrl: null,
      categories: [],
      existsInUserLists: false,
      __typename: 'Product',
    },
  };
}

export function generateNewCartProductV2() {
  const productId = `${NEW_PRODUCT_PREFIX}-${uuid()}`;
  const productRowId = `${NEW_PRODUCT_ROW_PREFIX}-${uuid()}`;
  return {
    _id: productRowId,
    selectedProduct: {
      _id: productId,
      baseUnit: null,
      cartQuantityStep: 1,
      deliveryOptionId: null,
      message: null,
      price: null,
      productType: '__default__',
      qty: 0,
      variantId: null,
      attributesKey: null,
      minOrderQuantity: null,
      isNewProduct: true,
    },
  };
}

export function getFractionDigits(cartStep = 1, minOrderQty = 1) {
  return Math.max(
    cartStep?.toString()?.split('.')?.[1]?.length || 2,
    minOrderQty?.toString()?.split('.')?.[1]?.length || 2
  );
}

export function isDivisionInteger(num1, num2, fractionDigits = 2) {
  return Number.isInteger(parseFloat((num1 / num2).toFixed(fractionDigits)));
}

export function roundCartQty(number, step, fractionDigits = 2) {
  return parseFloat(
    (Math.ceil(number * (1 / step)) / (1 / step)).toFixed(fractionDigits)
  );
}

export const calculateClosestQuantity = (value, cartOptions) => {
  const { minimalQuantity, cartQuantityStep, fractionDigits } = cartOptions;

  const isQtyValid =
    isDivisionInteger(value, cartQuantityStep, fractionDigits) &&
    value >= minimalQuantity;
  const validQty = isQtyValid
    ? value
    : roundCartQty(value, cartQuantityStep, fractionDigits);
  return validQty;
};

export const DEFAULT_CART_RESTRICTIONS = {
  minimalQuantity: 1,
  cartQuantityStep: 1,
  fractionDigits: 0,
  hasRestrictions: false,
  isCartStepInteger: true,
};

export function checkCartV2Validity(products) {
  // console.log('checkCartV2Validity INIT---->', products);
  return products?.reduce((init, productData, productIndex) => {
    const product = productData?.selectedProduct;
    const productRowId = productData?._id;

    if (!product?._id) {
      return {
        ...init,
        [productRowId]: {
          isEmpty: true,
          index: productIndex,
          productRowId,
        },
      };
    }

    // console.log('checkCartV2Validity ---->', {
    //   init,
    //   product,
    //   productIndex,
    //   productData,
    //   productRowId,
    // });
    const variant = product?.variantCopy;
    const quantity = product.qty || 0;

    const { cartQuantityStep = 1, minOrderQuantity } = variant || {
      cartQuantityStep: 1,
      minOrderQuantity: 0,
    };
    const calculatedMinOrderQuantity =
      minOrderQuantity || cartQuantityStep || 0;
    const fractionDigits = getFractionDigits(
      cartQuantityStep,
      minOrderQuantity
    );
    const isInvalidProduct = !(
      isDivisionInteger(quantity, cartQuantityStep, fractionDigits) &&
      quantity >= calculatedMinOrderQuantity
    );

    if (isInvalidProduct) {
      return {
        ...init,
        [productRowId]: {
          minOrderQuantity: calculatedMinOrderQuantity,
          quantity,
          index: productIndex,
          productRowId,
          isInvalidQty: true,
        },
      };
    } else {
      return init;
    }
  }, {});
}

export function getCartRestrictionV2(variants) {
  if (!variants || (!Array.isArray(variants) && typeof variants !== 'object')) {
    return DEFAULT_CART_RESTRICTIONS;
  }

  const productVariants = [].concat(variants);

  const mappedVariants = productVariants.reduce((init, variant) => {
    const { cartQuantityStep = 1, minOrderQuantity, packaging } = variant || {
      cartQuantityStep: 1,
      minOrderQuantity: 0,
    };
    const isCartStepInteger = Number.isInteger(cartQuantityStep);
    const fractionDigits = getFractionDigits(
      cartQuantityStep,
      minOrderQuantity
    );

    return {
      ...init,
      [packaging]: {
        minimalQuantity: minOrderQuantity || cartQuantityStep || 1,
        cartQuantityStep: cartQuantityStep || 1,
        fractionDigits,
        hasRestrictions: minOrderQuantity !== 1 || cartQuantityStep !== 1,
        isCartStepInteger,
      },
    };
  }, {});

  return mappedVariants;
}
