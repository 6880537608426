/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

import DeleteIcon from '@material-ui/icons/DeleteOutlineRounded';
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreRounded';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import VisibilityIcon from '@material-ui/icons/VisibilityRounded';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOffRounded';

import StyledInput from 'components/shared/Inputs/StyledInput';
import IOSSwitch from 'components/shared/IOSSwitch';
import NewsletterProductImageUploader from 'components/Newsletter/Constructor/NewsletterProductImageUpload';
import useNewsletterStyles from 'components/Newsletter/NewsletterStyling';
import NewsletterProductSearch from 'components/shared/ProductSearchPopover';
import { NL_CHARS_LIMIT } from 'components/Newsletter/localConstantsAndHelpers';

const useStyles = makeStyles(({ palette, spacing }) => ({
  newsletterProductContainer: {
    padding: spacing(2),
    border: palette.border.lightGreyThinDark,
    borderRadius: 8,
  },
  newsletterFullWidthProduct: {
    width: '100%',
    maxWidth: '100% !important',
  },
  newsletterImageContainer: {
    marginRight: spacing(2),
  },
  newsletterProductInfoContainer: {
    height: '100%',
  },
  deleteCardButton: {
    display: 'flex',
    alignSelf: 'flex-end',
    width: 'fit-content',
    marginTop: spacing(1.5),
    color: palette.text.secondary,
  },
  deleteCardIconButton: {
    display: 'flex',
    marginTop: spacing(3),
    marginLeft: spacing(2),
    color: palette.text.secondary,
    border: palette.border.lightGreyThinDark,
    borderRadius: 4,
    width: 44,
    height: 44,
  },
  featuredProductMultilineRoot: {
    height: '100%',
  },
  featuredProductMultilineInput: {
    borderRadius: 8,
    border: palette.border.lightGreyThinDark,
    padding: spacing(1.5, 3, 1.5),
    boxSizing: 'border-box',
    height: '100% !important',
    '&:focus': {
      borderRadius: 8,
      borderColor: '#ff5100',
      boxShadow: '0 0 0 0.2rem rgba(255,81,0,.25)',
    },
  },
  featuredProductMultiline: {
    padding: 0,
    height: 120,
  },
  newsletterFeaturedProductDivider: {
    margin: spacing(0, 1),
  },
  newsletterProductPackaging: {
    background: palette.background.mutedLight,
  },
  switchContainer: {
    height: 42,
    width: 'fit-content',
    boxSizing: 'border-box',
  },
  reduceInputOpacity: {
    color: palette.action.disabled,
  },
}));

export default function NewsletterProduct({
  product,
  defaultProductImage,
  isFeatured,
  isMainFeatProduct,
  fullWidth,
  handleChangeProductField,
  handleDeleteProductCard,
  handleDeleteProductImage,
  handleSelectProductFromSearch,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const commonClasses = useNewsletterStyles();

  const [productSearchAnchorEl, setProductSearchAnchorEl] = useState(null);

  const handleMouseDownPackage = event => {
    event.preventDefault();
  };

  const handleOpenProductSearch = event => {
    const targetButton = event.currentTarget;
    const targetInputDiv = targetButton?.closest(
      'div[itemid="productNameInput"]'
    );
    setProductSearchAnchorEl(targetInputDiv || targetButton);
  };

  const handleCloseProductSearch = () => {
    setProductSearchAnchorEl(null);
  };

  const handleSetProduct = productData => {
    if (!productData) return;
    const selectedProduct = {
      _id: productData._id,
      enabled: true,
      name: productData.name,
      imageUrl: productData.image || productData.imageUrl,
      description: productData.description || '',
      label: '',
      isLabelEnabled: true,
      productCopy: {
        ...productData,
      },
      variants: productData.variants
        ? productData.variants.map(
          ({
            baseUnit,
            baseUnitMapped,
            packaging,
            price,
            unitsInPackaging,
            packagingMapped,
          }) => ({
            baseUnit: baseUnitMapped?.de || baseUnit,
            packaging: packagingMapped?.de || packaging,
            originalPackaging: packaging,
            price,
            unitsInPackaging: unitsInPackaging || 1,
            enabled: true,
          })
        )
        : [],
    };

    handleCloseProductSearch();
    handleSelectProductFromSearch(selectedProduct);
  };

  const handleChange = (fieldName, isVariantField = false) => event => {
    if (!event) return;
    event.persist();
    const { value } = event.target;
    handleChangeProductField(fieldName, value, isVariantField);
  };

  const handleChangePackageVisibility = (fieldName, value) => () => {
    handleChangeProductField(fieldName, value, true);
  };

  const handleClickProductName = event => {
    if (!product._id) {
      setProductSearchAnchorEl(event.currentTarget);
    }
  };

  return (
    <Grid
      className={classNames(
        classes.newsletterProductContainer,
        fullWidth && classes.newsletterFullWidthProduct
      )}
      container
      direction={isMainFeatProduct ? 'row' : 'column'}
      wrap="nowrap"
      justifyContent="space-between"
    >
      <Grid className={classes.newsletterImageContainer}>
        <NewsletterProductImageUploader
          disableUploader={!product?._id}
          imageUrl={product.imageUrl}
          defaultProductImage={defaultProductImage}
          handleChangeImage={handleChangeProductField}
          handleDeleteProductImage={handleDeleteProductImage}
        />
      </Grid>

      <Grid container item className={classes.newsletterProductInfoContainer}>
        <Grid container direction="column" wrap="nowrap">
          <Grid className={commonClasses.nlConstructorSection} container>
            <Typography variant="caption" color="textSecondary" gutterBottom>
              {t('newsletter.product search')}
            </Typography>
            <StyledInput
              variant="outlined"
              value={product.name}
              itemID="productNameInput"
              placeholder={t('newsletter.product name placeholder')}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle product search"
                    onClick={handleOpenProductSearch}
                    onMouseDown={handleMouseDownPackage}
                    title="Toggle product search"
                    size="small"
                  >
                    <ExpandMoreIcon />
                  </IconButton>
                </InputAdornment>
              }
              onChange={handleChange('name')}
              onClick={handleClickProductName}
              fullWidth
            />
            <NewsletterProductSearch
              anchorEl={productSearchAnchorEl}
              product={product}
              handleClose={handleCloseProductSearch}
              handleSetProduct={handleSetProduct}
            />
          </Grid>

          <Grid className={commonClasses.nlConstructorSection} container>
            {product.variants?.map((variant, vIndex) => {
              const isPackagingEnabled = variant.enabled;
              const hasMultipleVariants = product.variants.length > 1;
              return (
                <Grid
                  key={`${variant.packaging}-${vIndex}`}
                  container
                  spacing={2}
                  wrap="nowrap"
                  alignItems="flex-end"
                >
                  <Grid item>
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      gutterBottom
                    >
                      {t('newsletter.type of packaging')}
                    </Typography>
                    <StyledInput
                      variant="outlined"
                      defaultValue={variant.packaging}
                      onBlur={handleChange([vIndex, 'packaging'], true)}
                      className={classNames(
                        !isPackagingEnabled && classes.reduceInputOpacity
                      )}
                      fullWidth
                    />
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      wrap="nowrap"
                      justifyContent="space-between"
                    >
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        gutterBottom
                      >
                        {t('newsletter.price')}
                      </Typography>
                      {vIndex === 0 && (
                        <Tooltip title={t('newsletter.product price tooltip')}>
                          <InfoRoundedIcon color="disabled" fontSize="small" />
                        </Tooltip>
                      )}
                    </Grid>
                    <StyledInput
                      variant="outlined"
                      defaultValue={variant.price}
                      type="number"
                      onBlur={handleChange([vIndex, 'price'], true)}
                      inputProps={{
                        min: 0,
                      }}
                      className={classNames(
                        !isPackagingEnabled && classes.reduceInputOpacity
                      )}
                      fullWidth
                    />
                  </Grid>
                  {hasMultipleVariants && (
                    <Grid item>
                      <IconButton
                        aria-label="toggle package visibility"
                        onClick={handleChangePackageVisibility(
                          [vIndex, 'enabled'],
                          !isPackagingEnabled
                        )}
                        onMouseDown={handleMouseDownPackage}
                        title={t('newsletter.hide packaging')}
                      >
                        {isPackagingEnabled ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              );
            })}
          </Grid>
        </Grid>

        <Grid
          className={commonClasses.nlConstructorSection}
          container
          alignItems="flex-end"
          wrap="nowrap"
        >
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            item
            className={classes.switchContainer}
          >
            <FormControlLabel
              control={
                <IOSSwitch
                  checked={product.isLabelEnabled}
                  onChange={event =>
                    handleChangeProductField(
                      'isLabelEnabled',
                      event.target.checked
                    )}
                  name="product label"
                />
              }
              label={t('newsletter.label')}
            />
          </Grid>
          <Grid item container>
            <Tooltip
              placement="top-start"
              title={t('common.chars limitation', { count: 20 })}
            >
              <Typography variant="caption" color="textSecondary" gutterBottom>
                {t('newsletter.label name')}
              </Typography>
            </Tooltip>
            <StyledInput
              variant="outlined"
              value={product.label}
              placeholder={t('newsletter.label name')}
              onChange={handleChange('label')}
              inputProps={{
                maxLength: 20,
              }}
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>

      {isMainFeatProduct && (
        <Grid className={classes.newsletterFeaturedProductDivider} />
      )}

      <Grid container item wrap={isMainFeatProduct ? 'nowrap' : 'wrap'}>
        {isFeatured && (
          <Grid
            className={commonClasses.nlConstructorSection}
            container
            direction="column"
            wrap="nowrap"
            item
          >
            <Tooltip
              placement="top-start"
              title={t('common.chars limitation', { count: NL_CHARS_LIMIT })}
            >
              <Typography variant="caption" color="textSecondary" gutterBottom>
                {t('newsletter.description')}
              </Typography>
            </Tooltip>
            <TextField
              id="featured-multiline"
              multiline
              maxRows={4}
              value={product.description}
              placeholder={t('newsletter.description placeholder')}
              onChange={handleChange('description')}
              classes={{
                root: classes.featuredProductMultilineRoot,
              }}
              InputProps={{
                classes: {
                  input: classes.featuredProductMultilineInput,
                  multiline: classes.featuredProductMultiline,
                },
                disableUnderline: true,
                inputProps: {
                  maxLength: NL_CHARS_LIMIT,
                },
              }}
              fullWidth
            />
          </Grid>
        )}

        {isMainFeatProduct ? (
          <IconButton
            onClick={handleDeleteProductCard}
            className={classes.deleteCardIconButton}
          >
            <DeleteIcon />
          </IconButton>
        ) : (
          <Button
            className={classes.deleteCardButton}
            variant="outlined"
            startIcon={<DeleteIcon />}
            onClick={handleDeleteProductCard}
          >
            {t('newsletter.delete card')}
          </Button>
        )}
      </Grid>
    </Grid>
  );
}

NewsletterProduct.defaultProps = {
  defaultProductImage: undefined,
  isFeatured: false,
  isMainFeatProduct: false,
  fullWidth: false,
};

NewsletterProduct.propTypes = {
  product: PropTypes.object.isRequired,
  defaultProductImage: PropTypes.string,
  isFeatured: PropTypes.bool,
  isMainFeatProduct: PropTypes.bool,
  fullWidth: PropTypes.bool,
  handleChangeProductField: PropTypes.func.isRequired,
  handleDeleteProductCard: PropTypes.func.isRequired,
  handleDeleteProductImage: PropTypes.func.isRequired,
  handleSelectProductFromSearch: PropTypes.func.isRequired,
};
