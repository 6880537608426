import React, { useState, memo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { isEqual, noop } from 'lodash';

import { makeStyles } from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import { ReactComponent as TrashIcon } from 'assets/aiOrders/trash.svg';

import StyledInput from 'components/shared/Inputs/StyledInput';
import ProductsPackagingSelector from 'components/AIOrderReview/ProductsList/ProductsPackagingSelector';
import AiOrderProductSearch from 'components/shared/ProductSearchPopover';
import {
  DEFAULT_CART_RESTRICTIONS,
  NEW_PRODUCT_ROW_PREFIX,
} from 'helpers/cartValidationHelpers';
import ProductQtyInput from 'components/AIOrderReview/ProductsList/ProductQtyInput';
import classNames from 'classnames';
import { useReactiveVar } from '@apollo/client';
import { isProductTypeDisplayingEnabledVar } from 'graphql/apolloReactiveVariables';
import generateStringToHexColor from 'helpers/generateStringToHexColor';

const useStyles = makeStyles(({ spacing, palette, typography }) => ({
  orderDetTableProductRow: {
    minHeight: 56,
    padding: spacing(1.5),
    alignItems: 'center',
    ...typography.body2,
    '& div:not(:first-child)': {
      paddingRight: spacing(1),
    },
  },
  orderDetailProductNumber: {
    width: 48,
    display: 'flex',
    alignItems: 'center',
  },
  orderDetProductDeleteButton: {
    width: 32,
    height: 32,
    borderRadius: '50%',
    border: palette.border.grey,
    marginLeft: spacing(1),
    marginRight: -spacing(2),
  },
  orderDetProductDeleteButtonLabel: {
    height: 16,
    width: 16,
  },
  inputRoot: {
    height: 32,
    borderRadius: 4,
  },
  readOnlyInput: {
    cursor: 'default',
  },
  productSearchButton: {
    width: '100%',
    height: 32,
    border: palette.border.grey,
    padding: spacing(0.75, 1.5),
    display: 'flex',
    alignItems: 'center',
    background: palette.background.paper,
    borderRadius: 4,
    boxSizing: 'border-box',
    cursor: 'pointer',
    ...typography.body2,
    fontWeight: typography.fontWeightRegular,
    '&:hover': {
      background: palette.action.hover,
    },
  },
  orderDetTableProductRowError: {
    background: 'rgba(255, 81, 0, 0.08) !important',
    border: `1px solid ${palette.primary.main} !important`,
  },
  orderDetTableProductRowQuantityCell: {
    minWidth: 100,
  },
  warningIcon: {
    color: palette.warning.main,
    width: 20,
    height: 20,
  },
  productTypePreview: {
    height: 16,
    minWidth: 16,
    borderRadius: '50%',
    marginLeft: spacing(1),
  },
}));

function OrderDetailsProductRow({
  fullProduct,
  product,
  productData,
  error,
  productRowId,
  rowIndex,
  // rawLLMProduct,
  cartRestrictionsPerPackaging,
  hasMultipleProductTypes,
  handlePackagingChange,
  handleChangeQty,
  handleSelectProductFromSearch,
  handleDeleteProduct,
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  const rawLLMProduct = fullProduct?.parsedProduct;

  const isProductTypeDisplayingEnabled = useReactiveVar(
    isProductTypeDisplayingEnabledVar
  );

  const [productSearchAnchorEl, setProductSearchAnchorEl] = useState(null);

  const handleOpenProductSearch = event => {
    const target = event.currentTarget;
    const targetParent = target.closest('[data-attr="product-table"]');
    setProductSearchAnchorEl(targetParent || target);
  };

  const handleCloseProductSearch = () => {
    setProductSearchAnchorEl(null);
  };

  const handleSetProduct = responseProduct => {
    if (!responseProduct) return;

    const { variants = [], ...cartProductFields } = responseProduct;

    const newSelectedProduct = {
      ...cartProductFields,
      variantId: variants[0]?.packaging,
      qty: product?.qty || rawLLMProduct?.qty || 1,
      productCopy: {
        ...cartProductFields,
      },
      variantCopy: {
        ...variants[0],
      },
    };

    const selectedProductData = {
      ...responseProduct,
      variants: variants || [],
    };

    handleCloseProductSearch();
    handleSelectProductFromSearch({
      productRowId,
      newProduct: newSelectedProduct,
      newProductData: selectedProductData,
    });
  };

  // generate product type color and preview

  const ptColor = generateStringToHexColor(product?.productType);

  const displayProductTYpeColor =
    hasMultipleProductTypes && isProductTypeDisplayingEnabled && ptColor;

  const productTypeNode = () => {
    if (!displayProductTYpeColor) return null;
    return (
      <Tooltip title={product?.productType}>
        <div
          className={classes.productTypePreview}
          style={{ background: ptColor }}
        />
      </Tooltip>
    );
  };

  // ----------------------------

  const { variants = [] } = productData || {};
  const activeVariant = variants.find(
    variant => variant.packaging === product.variantId
  );
  const cartRestrictions =
    cartRestrictionsPerPackaging?.[activeVariant?.packaging] ||
    DEFAULT_CART_RESTRICTIONS;

  const hasError = Object.keys(error).length > 0;
  const isNewProduct =
    productRowId?.startsWith(NEW_PRODUCT_ROW_PREFIX) && product?.isNewProduct; // !rawLLMProduct means we add this product
  const isEmptyProduct =
    !product?._id && rawLLMProduct && Object.keys(rawLLMProduct).length > 0;

  if (isEmptyProduct) {
    const {
      name: tempName = '',
      packaging: tempPackaging = '',
      qty: tempQty = 0,
      vendorSpecificId: tempVendorSpecificId = '',
    } = rawLLMProduct || {};

    return (
      <Grid
        data-attr="product-table"
        className={classNames(
          classes.orderDetTableProductRow,
          hasError && classes.orderDetTableProductRowError
        )}
        container
        item
        xs={12}
        direction="row"
        wrap="nowrap"
      >
        <Grid item className={classes.orderDetailProductNumber}>
          {rowIndex + 1}.{productTypeNode()}
        </Grid>
        <Grid item xs={3}>
          <Grid
            className={classes.productSearchButton}
            container
            justifyContent="space-between"
            role="button"
            onClick={handleOpenProductSearch}
          >
            <Typography variant="inherit" noWrap>
              {tempVendorSpecificId}
            </Typography>
            <Tooltip
              title={t('aiOrders.errors.empty product warning')}
              arrow
              placement="top"
              classes={{
                popper: classes.tooltipPopper,
                tooltip: classes.tooltip,
                touch: classes.tooltipTouch,
              }}
              PopperProps={{
                disablePortal: true,
              }}
            >
              <WarningRoundedIcon className={classes.warningIcon} />
            </Tooltip>
          </Grid>
          <AiOrderProductSearch
            anchorEl={productSearchAnchorEl}
            product={product}
            fromEmailConverter
            handleClose={handleCloseProductSearch}
            handleSetProduct={handleSetProduct}
          />
        </Grid>
        <Grid item xs={3}>
          <Tooltip title={tempName}>
            <StyledInput
              variant="outlined"
              value={tempName}
              onChange={noop}
              readOnly
              classes={{
                root: classes.inputRoot,
                input: classes.readOnlyInput,
              }}
              fullWidth
            />
          </Tooltip>
        </Grid>
        <Grid item xs={3}>
          <StyledInput
            variant="outlined"
            value={tempPackaging}
            onChange={noop}
            readOnly
            classes={{
              root: classes.inputRoot,
              input: classes.readOnlyInput,
            }}
            fullWidth
          />
        </Grid>
        <Grid
          className={classes.orderDetTableProductRowQuantityCell}
          item
          xs={2}
          container
          wrap="nowrap"
        >
          <ProductQtyInput
            quantity={tempQty}
            cartRestrictions={DEFAULT_CART_RESTRICTIONS}
            productRowId={productRowId}
            handleChangeQty={handleChangeQty}
          />
          <IconButton
            onClick={handleDeleteProduct(productRowId)}
            className={classes.orderDetProductDeleteButton}
            classes={{
              label: classes.orderDetProductDeleteButtonLabel,
            }}
          >
            <TrashIcon />
          </IconButton>
        </Grid>
      </Grid>
    );
  }

  if (isNewProduct || !activeVariant) {
    return (
      <Grid
        data-attr="product-table"
        className={classes.orderDetTableProductRow}
        container
        item
        xs={12}
        direction="row"
        wrap="nowrap"
      >
        <Grid item className={classes.orderDetailProductNumber}>
          {rowIndex + 1}.{productTypeNode()}
        </Grid>
        <Grid item xs={6}>
          <Grid
            className={classes.productSearchButton}
            role="button"
            onClick={handleOpenProductSearch}
          >
            <Typography variant="caption" color="textSecondary" noWrap>
              {t('aiOrders.add product placeholder')}
            </Typography>
          </Grid>
          <AiOrderProductSearch
            anchorEl={productSearchAnchorEl}
            fromEmailConverter
            handleClose={handleCloseProductSearch}
            handleSetProduct={handleSetProduct}
          />
        </Grid>
        <Grid xs={5} item container justifyContent="flex-end">
          <IconButton
            onClick={handleDeleteProduct(productRowId)}
            className={classes.orderDetProductDeleteButton}
            classes={{
              label: classes.orderDetProductDeleteButtonLabel,
            }}
          >
            <TrashIcon />
          </IconButton>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      data-attr="product-table"
      className={classNames(
        classes.orderDetTableProductRow,
        hasError && classes.orderDetTableProductRowError
      )}
      container
      item
      xs={12}
      direction="row"
      wrap="nowrap"
    >
      <Grid item className={classes.orderDetailProductNumber}>
        {rowIndex + 1}. {productTypeNode()}
      </Grid>
      <Grid item xs={3}>
        <Grid
          className={classes.productSearchButton}
          role="button"
          onClick={handleOpenProductSearch}
        >
          <Typography variant="inherit" noWrap>
            {productData?.vendorSpecificId}
          </Typography>
        </Grid>
        <AiOrderProductSearch
          anchorEl={productSearchAnchorEl}
          product={productData}
          fromEmailConverter
          handleClose={handleCloseProductSearch}
          handleSetProduct={handleSetProduct}
        />
      </Grid>
      <Grid item xs={3}>
        <Tooltip title={productData?.name}>
          <StyledInput
            variant="outlined"
            value={productData.name}
            onChange={noop}
            readOnly
            classes={{
              root: classes.inputRoot,
              input: classes.readOnlyInput,
            }}
            fullWidth
          />
        </Tooltip>
      </Grid>
      <Grid item xs={3}>
        <ProductsPackagingSelector
          productRowId={productRowId}
          variants={variants}
          activeVariant={activeVariant}
          handlePackagingChange={handlePackagingChange}
        />
      </Grid>
      <Grid
        className={classes.orderDetTableProductRowQuantityCell}
        item
        xs={2}
        container
        wrap="nowrap"
      >
        <ProductQtyInput
          quantity={product.qty}
          cartRestrictions={cartRestrictions}
          productRowId={productRowId}
          handleChangeQty={handleChangeQty}
        />
        <IconButton
          onClick={handleDeleteProduct(productRowId)}
          className={classes.orderDetProductDeleteButton}
          classes={{
            label: classes.orderDetProductDeleteButtonLabel,
          }}
        >
          <TrashIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
}

OrderDetailsProductRow.propTypes = {
  fullProduct: PropTypes.object,
  product: PropTypes.object,
  productData: PropTypes.object,
  error: PropTypes.object,
  productRowId: PropTypes.string.isRequired,
  rowIndex: PropTypes.number.isRequired,
  // rawLLMProduct: PropTypes.shape({
  //   attributesKey: PropTypes.string,
  //   documentCoordinates: PropTypes.shape({
  //     x: PropTypes.number,
  //     y: PropTypes.number,
  //     page: PropTypes.number,
  //   }),
  //   name: PropTypes.string,
  //   packaging: PropTypes.string,
  //   qty: PropTypes.number,
  //   vendorSpecificId: PropTypes.string,
  // }).isRequired,
  cartRestrictionsPerPackaging: PropTypes.object.isRequired,
  hasMultipleProductTypes: PropTypes.bool.isRequired,
  handlePackagingChange: PropTypes.func.isRequired,
  handleChangeQty: PropTypes.func.isRequired,
  handleSelectProductFromSearch: PropTypes.func.isRequired,
  handleDeleteProduct: PropTypes.func.isRequired,
};

OrderDetailsProductRow.defaultProps = {
  product: {},
  productData: {},
  error: {},
};

// export default OrderDetailsProductRow;
export default memo(
  OrderDetailsProductRow,
  (prevProps, nextProps) =>
    isEqual(prevProps.product, nextProps.product) &&
    isEqual(prevProps.productData, nextProps.productData) &&
    isEqual(
      prevProps.cartRestrictionsPerPackaging,
      nextProps.cartRestrictionsPerPackaging
    ) &&
    isEqual(prevProps.error, nextProps.error) &&
    prevProps.rowId === nextProps.rowId &&
    prevProps.rowIndex === nextProps.rowIndex &&
    prevProps.hasMultipleProductTypes === nextProps.hasMultipleProductTypes
);
