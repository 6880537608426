import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import noop from 'lodash/noop';
import queryString from 'query-string';
import {
  useQuery,
} from '@apollo/client';
import {
  GET_VENDOR_PROFILE,
} from 'graphql/query/user';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import CustomersIcon from '@material-ui/icons/SupervisedUserCircle';
import OrdersIcon from '@material-ui/icons/ShoppingCart';
import ProductsIcon from '@material-ui/icons/Fastfood';
import ListsIcon from '@material-ui/icons/List';
import DealsIcon from '@material-ui/icons/LocalOffer';
import CategoriesIcon from '@material-ui/icons/Category';
import CampaignIcon from '@material-ui/icons/Chat';
import SalesRepIcon from '@material-ui/icons/ContactPhone';
import VendorIcon from '@material-ui/icons/LocalShippingOutlined';
import IngestIcon from '@material-ui/icons/StorageOutlined';
import TemplatesIcon from '@material-ui/icons/DraftsOutlined';
import AdminIcon from '@material-ui/icons/Cake';
import TimerIcon from '@material-ui/icons/Timer';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import EventIcon from '@material-ui/icons/Event';
import NewsletterIcon from '@material-ui/icons/MailOutlineRounded';
import ContactSupport from '@material-ui/icons/ContactSupport';
import GroupsIcon from '@material-ui/icons/GroupRounded';

const useStyles = makeStyles(({ spacing, palette }) => ({
  menuButton: {
    color: palette.text.secondary,
    height: 60,
    borderRadius: 0,
    margin: 0,
    padding: 0,
    justifyContent: 'flex-start',
  },
  isActivePage: {
    color: palette.primary.main,
    borderRight: `3px solid ${palette.primary.main}`
  },
  menuIcon: {
    margin: spacing(0, 2),
    fontSize: '25px !important'
  }
}));

const MenuSections = ({ isAdmin, handleCloseDrawer }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const queries = queryString.parse(location.search);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpenAdminMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseAdminMenu = () => {
    setAnchorEl(null);
  };

  const { data: vendorData } = useQuery(
    GET_VENDOR_PROFILE,
    { fetchPolicy: 'cache-and-network' }
  );

  const handleAmountOfRows = (pathname) => {
    if (queries.rowsPerPage && +queries.rowsPerPage !== 0) {
      history.push(`${pathname}?rowsPerPage=${+queries.rowsPerPage}`);
      handleCloseDrawer();
    } else {
      history.push(pathname);
      handleCloseDrawer();
    }
  };

  const handleAdminRoute = (route) => () => {
    handleCloseAdminMenu();
    history.push(route);
  };

  const menuItems = [
    {
      title: t('appbar.customers'),
      path: '/customers',
      Icon: CustomersIcon
    },
    {
      title: t('appbar.orders'),
      path: '/orders/ai',
      Icon: OrdersIcon,
    },
    {
      title: t('appbar.products'),
      path: '/products/products',
      Icon: ProductsIcon,
      additionalPath: 'products/attributes'
    },
    {
      title: t('appbar.lists'),
      path: '/lists',
      Icon: ListsIcon,
      additionalPath: 'list'
    },
    {
      title: t('appbar.deals'),
      path: '/deals',
      Icon: DealsIcon
    },
    {
      title: t('groups.menu title'),
      path: '/groups',
      Icon: GroupsIcon
    },
    {
      title: t('appbar.productTypes'),
      path: '/product-types',
      Icon: TimerIcon
    },
    {
      title: t('appbar.delivery-days'),
      path: '/delivery-days',
      Icon: EventIcon
    },
    {
      title: t('appbar.categories'),
      path: '/categories',
      Icon: CategoriesIcon
    },
  ];

  if (vendorData?.vendorAccount?.preferences?.twilio?.enabled) {
    menuItems.push({
      title: t('appbar.campaigns'),
      path: '/campaigns',
      Icon: CampaignIcon,
      additionalPath: 'campaign'
    });
  }
  if (vendorData?.vendorAccount?.preferences?.salesRepsEnabled) {
    menuItems.push({
      title: t('appbar.salesReps'),
      path: '/salesReps',
      Icon: SalesRepIcon
    });
  }
  if (vendorData?.vendorAccount?.preferences?.pdfCatalog?.enabled) {
    menuItems.push({
      title: t('appbar.pdfCatalog'),
      path: '/pdfCatalogs',
      Icon: PictureAsPdfIcon
    });
  }

  if (vendorData?.vendorAccount?.preferences?.newsletter?.enabled !== false) {
    menuItems.push({
      title: t('appbar.newsletter'),
      path: '/newsletters',
      Icon: NewsletterIcon
    });
  }

  return (
    <>
      {menuItems.map(({ title, path, Icon, additionalPath = undefined }) => {
        const isPathActive = location.pathname.includes(path) || location.pathname.includes(additionalPath);
        return (
          <Button
            key={path}
            className={classNames(
              classes.menuButton,
              isPathActive && classes.isActivePage
            )}
            onClick={() => handleAmountOfRows(path)}
            startIcon={Icon && <Icon className={classes.menuIcon} />}
          >
            {title}
          </Button>
        );
      })}
      {isAdmin && (
        <>
          <Divider style={{ marginTop: 24 }} />
          <Button
            className={classes.menuButton}
            startIcon={<AdminIcon color="secondary" className={classes.menuIcon} />}
            onClick={handleOpenAdminMenu}
            color="secondary"
          >
            Admin Routes
          </Button>
          <Menu
            id="admin-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseAdminMenu}
          >
            <MenuItem onClick={handleAdminRoute('/create-vendor')}>
              <ListItemIcon>
                <VendorIcon color="primary" fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Vendors" />
            </MenuItem>
            <MenuItem onClick={handleAdminRoute('/ingest')}>
              <ListItemIcon>
                <IngestIcon color="primary" fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Ingest" />
            </MenuItem>
            <MenuItem onClick={handleAdminRoute('/order-email')}>
              <ListItemIcon>
                <TemplatesIcon color="primary" fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Email templates" />
            </MenuItem>
            <MenuItem onClick={handleAdminRoute('/support')}>
              <ListItemIcon>
                <ContactSupport color="primary" fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Support" />
            </MenuItem>
          </Menu>
        </>
      )}
    </>
  );
};

MenuSections.propTypes = {
  isAdmin: PropTypes.bool,
  handleCloseDrawer: PropTypes.func,
};
MenuSections.defaultProps = {
  isAdmin: false,
  handleCloseDrawer: noop,
};

export default MenuSections;
