/* eslint-disable max-len */
import { gql } from '@apollo/client';

export const GET_PRODUCTS = gql`
  query products(
    $pageSize: Int
    $after: Int
    $sortBy: ProductSortingOption
    $sortOrder: Int
  ) {
    vendorProducts(
      pageSize: $pageSize
      after: $after
      sortBy: $sortBy
      sortOrder: $sortOrder
    ) @connection(key: "vendorProducts", filter: ["_id"]) {
      page
      totalPages
      hasNextPage
      hasPrevPage
      totalResults
      products {
        _id
        name
        vendorId
        vendorSpecificId
        enabled
        image
        imageUrl
        description
        categories
        variants {
          _id
          baseUnit
          packaging
          price
          unitsInPackaging
          attributes {
            _id
            isRequired
            name
            options {
              name
              _id
              translations {
                en
                de
              }
            }
          }
        }
      }
    }
  }
`;

export const SEARCH_VENDOR_PRODUCTS = gql`
  query products(
    $vendorId: ID!
    $searchPhrase: String!
    $pageSize: Int
    $after: Int
    $translateVariants: Boolean = false
    $fromEmailConverter: Boolean = false
  ) {
    searchAllVendorProducts(
      vendorId: $vendorId
      searchPhrase: $searchPhrase
      pageSize: $pageSize
      after: $after
      translateVariants: $translateVariants
    ) {
      page
      totalPages
      hasNextPage
      hasPrevPage
      totalResults
      products {
        _id
        name
        vendorId
        vendorSpecificId
        enabled
        image
        imageUrl
        description
        categories
        variants {
          _id
          baseUnit
          packaging
          price @skip(if: $fromEmailConverter)
          unitsInPackaging
          minOrderQuantity
          cartQuantityStep
          packagingMapped @include(if: $translateVariants) {
            de
            en
          }
          baseUnitMapped @include(if: $translateVariants) {
            de
            en
          }
          attributes @include(if: $fromEmailConverter) {
            _id
            name
            isRequired
            options {
              _id
              name
            }
          }
          productType @include(if: $fromEmailConverter) {
            _id
            readableId
            vendorId
          }
        }
      }
    }
  }
`;

export const GET_PRODUCTS_BY_ID = gql`
  query ProductsById($vendorId: ID!, $productIds: [ID]!, $pageSize: Int) {
    productsById(
      vendorId: $vendorId
      productIds: $productIds
      pageSize: $pageSize
    ) {
      totalResults
      products {
        _id
        name
        vendorId
        vendorSpecificId
        enabled
        imageUrl
        image
        description
        categories
        variants {
          _id
          baseUnit
          packaging
          unitsInPackaging
          minOrderQuantity
          cartQuantityStep
          attributes {
            _id
            name
            isRequired
            options {
              _id
              name
            }
          }
          productType {
            _id
            readableId
            vendorId
          }
          attributes {
            _id
            isRequired
            name
            options {
              name
              _id
              translations {
                en
                de
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_PRODUCT_ATTRIBUTE_BY_ID = gql`
  query ProductAttribute(
    $productAttributeId: ID!
    $productsPageSize: Int = 10
    $productsAfter: Int
  ) {
    productAttribute(_id: $productAttributeId) {
      _id
      isRequired
      name
      options {
        name
        _id
        translations {
          en
          de
        }
      }
      products(pageSize: $productsPageSize, after: $productsAfter) {
        totalResults
        page
        totalPages
        hasNextPage
        products {
          _id
          name
          vendorSpecificId
          variants {
            _id
            packaging
          }
        }
      }
    }
  }
`;

export const GET_PRODUCT_ATTRIBUTES = gql`
  query ProductAttributes(
    $pageSize: Int
    $after: Int
    $productsPageSize: Int = 10
    $productsAfter: Int
  ) {
    productAttributes(pageSize: $pageSize, after: $after) {
      hasNextPage
      hasPrevPage
      nextPage
      prevPage
      page
      results {
        _id
        isRequired
        name
        options {
          name
          _id
          translations {
            en
            de
          }
        }
        products(pageSize: $productsPageSize, after: $productsAfter) {
          totalResults
          page
          totalPages
          hasNextPage
          products {
            _id
            name
            vendorSpecificId
            variants {
              _id
              packaging
            }
          }
        }
      }
      totalPages
      totalResults
    }
  }
`;
