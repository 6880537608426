/* eslint-disable max-len */
import { ORDER_STATUS } from 'helpers/constants';

const TEMP_DELIVERY_OPTIONS = [
  {
    _id: '668be9bd792008001b583956',
    vendorId: 'e76d48970971564e560d4aa4',
    deliveryDate: '2024-07-23T12:00:00.000Z',
    defaultDeliveryDate: '2024-07-23T12:00:00.000Z',
    blockedDeliveryDaysIndexes: [],
    availableDeliveryDates: [
      '2024-07-23T12:00:00.000Z',
      '2024-07-24T12:00:00.000Z',
      '2024-07-26T12:00:00.000Z',
      '2024-07-29T12:00:00.000Z',
      '2024-07-30T12:00:00.000Z',
      '2024-07-31T12:00:00.000Z',
      '2024-08-02T12:00:00.000Z',
      '2024-08-03T12:00:00.000Z',
      '2024-08-05T12:00:00.000Z',
      '2024-08-10T12:00:00.000Z',
      '2024-08-12T12:00:00.000Z',
      '2024-08-13T12:00:00.000Z',
      '2024-08-14T12:00:00.000Z',
      '2024-08-16T12:00:00.000Z',
      '2024-08-17T12:00:00.000Z',
      '2024-08-19T12:00:00.000Z',
      '2024-08-20T12:00:00.000Z',
      '2024-08-21T12:00:00.000Z',
      '2024-08-23T12:00:00.000Z',
      '2024-08-24T12:00:00.000Z',
      '2024-08-26T12:00:00.000Z',
      '2024-08-27T12:00:00.000Z',
      '2024-08-28T12:00:00.000Z',
      '2024-08-30T12:00:00.000Z',
      '2024-08-31T12:00:00.000Z',
      '2024-09-02T12:00:00.000Z',
      '2024-09-03T12:00:00.000Z',
      '2024-09-04T12:00:00.000Z',
      '2024-09-06T12:00:00.000Z',
      '2024-09-07T12:00:00.000Z',
      '2024-09-09T12:00:00.000Z',
      '2024-09-10T12:00:00.000Z',
      '2024-09-11T12:00:00.000Z',
      '2024-09-13T12:00:00.000Z',
      '2024-09-14T12:00:00.000Z',
      '2024-09-16T12:00:00.000Z',
      '2024-09-17T12:00:00.000Z',
      '2024-09-18T12:00:00.000Z',
      '2024-09-20T12:00:00.000Z',
      '2024-09-21T12:00:00.000Z',
      '2024-09-23T12:00:00.000Z',
      '2024-09-24T12:00:00.000Z',
      '2024-09-25T12:00:00.000Z',
      '2024-09-27T12:00:00.000Z',
      '2024-09-28T12:00:00.000Z',
      '2024-09-30T12:00:00.000Z',
      '2024-10-01T12:00:00.000Z',
      '2024-10-02T12:00:00.000Z',
      '2024-10-04T12:00:00.000Z',
      '2024-10-05T12:00:00.000Z',
      '2024-10-07T12:00:00.000Z',
      '2024-10-08T12:00:00.000Z',
      '2024-10-09T12:00:00.000Z',
      '2024-10-11T12:00:00.000Z',
      '2024-10-12T12:00:00.000Z',
      '2024-10-14T12:00:00.000Z',
      '2024-10-15T12:00:00.000Z',
      '2024-10-16T12:00:00.000Z',
      '2024-10-18T12:00:00.000Z',
      '2024-10-19T12:00:00.000Z',
      '2024-10-21T12:00:00.000Z',
      '2024-10-22T12:00:00.000Z',
      '2024-10-23T12:00:00.000Z',
      '2024-10-25T12:00:00.000Z',
      '2024-10-26T12:00:00.000Z',
      '2024-10-28T12:00:00.000Z',
      '2024-10-29T12:00:00.000Z',
      '2024-10-30T12:00:00.000Z',
      '2024-11-01T12:00:00.000Z',
      '2024-11-02T12:00:00.000Z',
      '2024-11-04T12:00:00.000Z',
      '2024-11-05T12:00:00.000Z',
      '2024-11-06T12:00:00.000Z',
      '2024-11-08T12:00:00.000Z',
      '2024-11-09T12:00:00.000Z',
      '2024-11-11T12:00:00.000Z',
      '2024-11-12T12:00:00.000Z',
      '2024-11-13T12:00:00.000Z',
      '2024-11-15T12:00:00.000Z',
      '2024-11-16T12:00:00.000Z',
    ],
    blockedDeliveryDates: [
      '2024-07-27T12:00:00.000Z',
      '2024-08-06T12:00:00.000Z',
      '2024-08-07T12:00:00.000Z',
      '2024-08-09T12:00:00.000Z',
    ],
    productType: {
      _id: '6490302b3331f9001396959b',
      readableId: '3 Days',
      name: {
        en: null,
        de: '3 Days delivery',
        fr: null,
        es: null,
        ve: null,
        __typename: 'Translations',
      },
      subTitle: {
        en: null,
        de: '3_days',
        fr: null,
        es: null,
        ve: null,
        __typename: 'Translations',
      },
      description: {
        en: null,
        de: '3 Days delivery from Frankfurt',
        fr: null,
        es: null,
        ve: null,
        __typename: 'Translations',
      },
      icon:
        'https://storage.googleapis.com/chfslst-product-images/e76d48970971564e560d4aa4/productType-images/3-day-only-vector-illustration-260nw-1992650993.webp',
      iconColor: null,
      hideAtProduct: false,
      isDefault: null,
      cutOffTime: {
        enabled: false,
        generic: {
          hour: 0,
          minute: 0,
          leadTime: {
            days: 0,
            __typename: 'CutOffTimeLeadTime_v2',
          },
          __typename: 'CutOffTimeGenericRule_v2',
        },
        perDay: {
          _0: {
            enabled: false,
            dayOfWeekIndex: 0,
            hour: 0,
            minute: 0,
            leadTime: {
              days: 0,
              __typename: 'CutOffTimeLeadTime_v2',
            },
            __typename: 'CutOffTimePerDayRule_v2',
          },
          _1: {
            enabled: false,
            dayOfWeekIndex: 1,
            hour: 0,
            minute: 0,
            leadTime: {
              days: 0,
              __typename: 'CutOffTimeLeadTime_v2',
            },
            __typename: 'CutOffTimePerDayRule_v2',
          },
          _2: {
            enabled: false,
            dayOfWeekIndex: 2,
            hour: 0,
            minute: 0,
            leadTime: {
              days: 0,
              __typename: 'CutOffTimeLeadTime_v2',
            },
            __typename: 'CutOffTimePerDayRule_v2',
          },
          _3: {
            enabled: false,
            dayOfWeekIndex: 3,
            hour: 0,
            minute: 0,
            leadTime: {
              days: 0,
              __typename: 'CutOffTimeLeadTime_v2',
            },
            __typename: 'CutOffTimePerDayRule_v2',
          },
          _4: {
            enabled: false,
            dayOfWeekIndex: 4,
            hour: 0,
            minute: 0,
            leadTime: {
              days: 0,
              __typename: 'CutOffTimeLeadTime_v2',
            },
            __typename: 'CutOffTimePerDayRule_v2',
          },
          _5: {
            enabled: false,
            dayOfWeekIndex: 5,
            hour: 0,
            minute: 0,
            leadTime: {
              days: 0,
              __typename: 'CutOffTimeLeadTime_v2',
            },
            __typename: 'CutOffTimePerDayRule_v2',
          },
          _6: {
            enabled: false,
            dayOfWeekIndex: 6,
            hour: 0,
            minute: 0,
            leadTime: {
              days: 0,
              __typename: 'CutOffTimeLeadTime_v2',
            },
            __typename: 'CutOffTimePerDayRule_v2',
          },
          __typename: 'CutOffTimePerDayList_v2',
        },
        __typename: 'CutOffTime_v2',
      },
      __typename: 'ProductType',
    },
    message: '',
    isSelected: true,
    deliveryAddress: null,
    deliveryOption: null,
    originalPrice: 10,
    deliveryTerms: {
      discount: null,
      deliveryFee: {
        value: 0,
        description: null,
        __typename: 'DeliveryTerm',
      },
      minSurcharge: {
        value: 0,
        description: null,
        __typename: 'DeliveryTerm',
      },
      __typename: 'VendorDeliveryTerms',
    },
    totalPrice: 10,
    totalItems: 1,
    totalQty: 5,
    __typename: 'CartDeliveryOptionV2',
  },
  {
    _id: '669e495852ab6c001a3151e1',
    vendorId: 'e76d48970971564e560d4aa4',
    deliveryDate: '2024-07-23T12:00:00.000Z',
    defaultDeliveryDate: '2024-07-23T12:00:00.000Z',
    blockedDeliveryDaysIndexes: [],
    availableDeliveryDates: [
      '2024-07-23T12:00:00.000Z',
      '2024-07-24T12:00:00.000Z',
      '2024-07-26T12:00:00.000Z',
      '2024-07-29T12:00:00.000Z',
      '2024-07-30T12:00:00.000Z',
      '2024-07-31T12:00:00.000Z',
      '2024-08-02T12:00:00.000Z',
      '2024-08-03T12:00:00.000Z',
      '2024-08-05T12:00:00.000Z',
      '2024-08-10T12:00:00.000Z',
      '2024-08-12T12:00:00.000Z',
      '2024-08-13T12:00:00.000Z',
      '2024-08-14T12:00:00.000Z',
      '2024-08-16T12:00:00.000Z',
      '2024-08-17T12:00:00.000Z',
      '2024-08-19T12:00:00.000Z',
      '2024-08-20T12:00:00.000Z',
      '2024-08-21T12:00:00.000Z',
      '2024-08-23T12:00:00.000Z',
      '2024-08-24T12:00:00.000Z',
      '2024-08-26T12:00:00.000Z',
      '2024-08-27T12:00:00.000Z',
      '2024-08-28T12:00:00.000Z',
      '2024-08-30T12:00:00.000Z',
      '2024-08-31T12:00:00.000Z',
      '2024-09-02T12:00:00.000Z',
      '2024-09-03T12:00:00.000Z',
      '2024-09-04T12:00:00.000Z',
      '2024-09-06T12:00:00.000Z',
      '2024-09-07T12:00:00.000Z',
      '2024-09-09T12:00:00.000Z',
      '2024-09-10T12:00:00.000Z',
      '2024-09-11T12:00:00.000Z',
      '2024-09-13T12:00:00.000Z',
      '2024-09-14T12:00:00.000Z',
      '2024-09-16T12:00:00.000Z',
      '2024-09-17T12:00:00.000Z',
      '2024-09-18T12:00:00.000Z',
      '2024-09-20T12:00:00.000Z',
      '2024-09-21T12:00:00.000Z',
      '2024-09-23T12:00:00.000Z',
      '2024-09-24T12:00:00.000Z',
      '2024-09-25T12:00:00.000Z',
      '2024-09-27T12:00:00.000Z',
      '2024-09-28T12:00:00.000Z',
      '2024-09-30T12:00:00.000Z',
      '2024-10-01T12:00:00.000Z',
      '2024-10-02T12:00:00.000Z',
      '2024-10-04T12:00:00.000Z',
      '2024-10-05T12:00:00.000Z',
      '2024-10-07T12:00:00.000Z',
      '2024-10-08T12:00:00.000Z',
      '2024-10-09T12:00:00.000Z',
      '2024-10-11T12:00:00.000Z',
      '2024-10-12T12:00:00.000Z',
      '2024-10-14T12:00:00.000Z',
      '2024-10-15T12:00:00.000Z',
      '2024-10-16T12:00:00.000Z',
      '2024-10-18T12:00:00.000Z',
      '2024-10-19T12:00:00.000Z',
      '2024-10-21T12:00:00.000Z',
      '2024-10-22T12:00:00.000Z',
      '2024-10-23T12:00:00.000Z',
      '2024-10-25T12:00:00.000Z',
      '2024-10-26T12:00:00.000Z',
      '2024-10-28T12:00:00.000Z',
      '2024-10-29T12:00:00.000Z',
      '2024-10-30T12:00:00.000Z',
      '2024-11-01T12:00:00.000Z',
      '2024-11-02T12:00:00.000Z',
      '2024-11-04T12:00:00.000Z',
      '2024-11-05T12:00:00.000Z',
      '2024-11-06T12:00:00.000Z',
      '2024-11-08T12:00:00.000Z',
      '2024-11-09T12:00:00.000Z',
      '2024-11-11T12:00:00.000Z',
      '2024-11-12T12:00:00.000Z',
      '2024-11-13T12:00:00.000Z',
      '2024-11-15T12:00:00.000Z',
      '2024-11-16T12:00:00.000Z',
    ],
    blockedDeliveryDates: [
      '2024-07-27T12:00:00.000Z',
      '2024-08-06T12:00:00.000Z',
      '2024-08-07T12:00:00.000Z',
      '2024-08-09T12:00:00.000Z',
    ],
    productType: {
      _id: '63ff57b3556bbe0013801511',
      readableId: 'pre',
      name: {
        en: null,
        de: 'Preorder',
        fr: null,
        es: null,
        ve: null,
        __typename: 'Translations',
      },
      subTitle: {
        en: null,
        de: 'Preorder products will be delivered in 3 days',
        fr: null,
        es: null,
        ve: null,
        __typename: 'Translations',
      },
      description: null,
      icon:
        'https://storage.googleapis.com/chfslst-static-files/preorder%20products.png',
      iconColor: null,
      hideAtProduct: null,
      isDefault: null,
      cutOffTime: {
        enabled: false,
        generic: {
          hour: 0,
          minute: 0,
          leadTime: {
            days: 0,
            __typename: 'CutOffTimeLeadTime_v2',
          },
          __typename: 'CutOffTimeGenericRule_v2',
        },
        perDay: {
          _0: {
            enabled: false,
            dayOfWeekIndex: 0,
            hour: 0,
            minute: 0,
            leadTime: {
              days: 0,
              __typename: 'CutOffTimeLeadTime_v2',
            },
            __typename: 'CutOffTimePerDayRule_v2',
          },
          _1: {
            enabled: false,
            dayOfWeekIndex: 1,
            hour: 0,
            minute: 0,
            leadTime: {
              days: 0,
              __typename: 'CutOffTimeLeadTime_v2',
            },
            __typename: 'CutOffTimePerDayRule_v2',
          },
          _2: {
            enabled: false,
            dayOfWeekIndex: 2,
            hour: 0,
            minute: 0,
            leadTime: {
              days: 0,
              __typename: 'CutOffTimeLeadTime_v2',
            },
            __typename: 'CutOffTimePerDayRule_v2',
          },
          _3: {
            enabled: false,
            dayOfWeekIndex: 3,
            hour: 0,
            minute: 0,
            leadTime: {
              days: 0,
              __typename: 'CutOffTimeLeadTime_v2',
            },
            __typename: 'CutOffTimePerDayRule_v2',
          },
          _4: {
            enabled: false,
            dayOfWeekIndex: 4,
            hour: 0,
            minute: 0,
            leadTime: {
              days: 0,
              __typename: 'CutOffTimeLeadTime_v2',
            },
            __typename: 'CutOffTimePerDayRule_v2',
          },
          _5: {
            enabled: false,
            dayOfWeekIndex: 5,
            hour: 0,
            minute: 0,
            leadTime: {
              days: 0,
              __typename: 'CutOffTimeLeadTime_v2',
            },
            __typename: 'CutOffTimePerDayRule_v2',
          },
          _6: {
            enabled: false,
            dayOfWeekIndex: 6,
            hour: 0,
            minute: 0,
            leadTime: {
              days: 0,
              __typename: 'CutOffTimeLeadTime_v2',
            },
            __typename: 'CutOffTimePerDayRule_v2',
          },
          __typename: 'CutOffTimePerDayList_v2',
        },
        __typename: 'CutOffTime_v2',
      },
      __typename: 'ProductType',
    },
    message: '',
    isSelected: true,
    deliveryAddress: null,
    deliveryOption: null,
    originalPrice: 1.2,
    deliveryTerms: null,
    totalPrice: 1.2,
    totalItems: 2,
    totalQty: 7,
    __typename: 'CartDeliveryOptionV2',
  },
  {
    _id: '669e495952ab6c001a3151ee',
    vendorId: 'e76d48970971564e560d4aa4',
    deliveryDate: '2024-07-23T12:00:00.000Z',
    defaultDeliveryDate: '2024-07-23T12:00:00.000Z',
    blockedDeliveryDaysIndexes: [],
    availableDeliveryDates: [
      '2024-07-23T12:00:00.000Z',
      '2024-07-24T12:00:00.000Z',
      '2024-07-26T12:00:00.000Z',
      '2024-07-29T12:00:00.000Z',
      '2024-07-30T12:00:00.000Z',
      '2024-07-31T12:00:00.000Z',
      '2024-08-02T12:00:00.000Z',
      '2024-08-03T12:00:00.000Z',
      '2024-08-05T12:00:00.000Z',
      '2024-08-10T12:00:00.000Z',
      '2024-08-12T12:00:00.000Z',
      '2024-08-13T12:00:00.000Z',
      '2024-08-14T12:00:00.000Z',
      '2024-08-16T12:00:00.000Z',
      '2024-08-17T12:00:00.000Z',
      '2024-08-19T12:00:00.000Z',
      '2024-08-20T12:00:00.000Z',
      '2024-08-21T12:00:00.000Z',
      '2024-08-23T12:00:00.000Z',
      '2024-08-24T12:00:00.000Z',
      '2024-08-26T12:00:00.000Z',
      '2024-08-27T12:00:00.000Z',
      '2024-08-28T12:00:00.000Z',
      '2024-08-30T12:00:00.000Z',
      '2024-08-31T12:00:00.000Z',
      '2024-09-02T12:00:00.000Z',
      '2024-09-03T12:00:00.000Z',
      '2024-09-04T12:00:00.000Z',
      '2024-09-06T12:00:00.000Z',
      '2024-09-07T12:00:00.000Z',
      '2024-09-09T12:00:00.000Z',
      '2024-09-10T12:00:00.000Z',
      '2024-09-11T12:00:00.000Z',
      '2024-09-13T12:00:00.000Z',
      '2024-09-14T12:00:00.000Z',
      '2024-09-16T12:00:00.000Z',
      '2024-09-17T12:00:00.000Z',
      '2024-09-18T12:00:00.000Z',
      '2024-09-20T12:00:00.000Z',
      '2024-09-21T12:00:00.000Z',
      '2024-09-23T12:00:00.000Z',
      '2024-09-24T12:00:00.000Z',
      '2024-09-25T12:00:00.000Z',
      '2024-09-27T12:00:00.000Z',
      '2024-09-28T12:00:00.000Z',
      '2024-09-30T12:00:00.000Z',
      '2024-10-01T12:00:00.000Z',
      '2024-10-02T12:00:00.000Z',
      '2024-10-04T12:00:00.000Z',
      '2024-10-05T12:00:00.000Z',
      '2024-10-07T12:00:00.000Z',
      '2024-10-08T12:00:00.000Z',
      '2024-10-09T12:00:00.000Z',
      '2024-10-11T12:00:00.000Z',
      '2024-10-12T12:00:00.000Z',
      '2024-10-14T12:00:00.000Z',
      '2024-10-15T12:00:00.000Z',
      '2024-10-16T12:00:00.000Z',
      '2024-10-18T12:00:00.000Z',
      '2024-10-19T12:00:00.000Z',
      '2024-10-21T12:00:00.000Z',
      '2024-10-22T12:00:00.000Z',
      '2024-10-23T12:00:00.000Z',
      '2024-10-25T12:00:00.000Z',
      '2024-10-26T12:00:00.000Z',
      '2024-10-28T12:00:00.000Z',
      '2024-10-29T12:00:00.000Z',
      '2024-10-30T12:00:00.000Z',
      '2024-11-01T12:00:00.000Z',
      '2024-11-02T12:00:00.000Z',
      '2024-11-04T12:00:00.000Z',
      '2024-11-05T12:00:00.000Z',
      '2024-11-06T12:00:00.000Z',
      '2024-11-08T12:00:00.000Z',
      '2024-11-09T12:00:00.000Z',
      '2024-11-11T12:00:00.000Z',
      '2024-11-12T12:00:00.000Z',
      '2024-11-13T12:00:00.000Z',
      '2024-11-15T12:00:00.000Z',
      '2024-11-16T12:00:00.000Z',
    ],
    blockedDeliveryDates: [
      '2024-07-27T12:00:00.000Z',
      '2024-08-06T12:00:00.000Z',
      '2024-08-07T12:00:00.000Z',
      '2024-08-09T12:00:00.000Z',
    ],
    productType: {
      _id: '63eddd72ad4ce800132db416',
      readableId: '__default__',
      name: null,
      subTitle: null,
      description: null,
      icon: null,
      iconColor: null,
      hideAtProduct: true,
      isDefault: true,
      cutOffTime: {
        enabled: false,
        generic: {
          hour: 0,
          minute: 0,
          leadTime: {
            days: 0,
            __typename: 'CutOffTimeLeadTime_v2',
          },
          __typename: 'CutOffTimeGenericRule_v2',
        },
        perDay: {
          _0: {
            enabled: false,
            dayOfWeekIndex: 0,
            hour: 0,
            minute: 0,
            leadTime: {
              days: 0,
              __typename: 'CutOffTimeLeadTime_v2',
            },
            __typename: 'CutOffTimePerDayRule_v2',
          },
          _1: {
            enabled: false,
            dayOfWeekIndex: 1,
            hour: 0,
            minute: 0,
            leadTime: {
              days: 0,
              __typename: 'CutOffTimeLeadTime_v2',
            },
            __typename: 'CutOffTimePerDayRule_v2',
          },
          _2: {
            enabled: false,
            dayOfWeekIndex: 2,
            hour: 0,
            minute: 0,
            leadTime: {
              days: 0,
              __typename: 'CutOffTimeLeadTime_v2',
            },
            __typename: 'CutOffTimePerDayRule_v2',
          },
          _3: {
            enabled: false,
            dayOfWeekIndex: 3,
            hour: 0,
            minute: 0,
            leadTime: {
              days: 0,
              __typename: 'CutOffTimeLeadTime_v2',
            },
            __typename: 'CutOffTimePerDayRule_v2',
          },
          _4: {
            enabled: false,
            dayOfWeekIndex: 4,
            hour: 0,
            minute: 0,
            leadTime: {
              days: 0,
              __typename: 'CutOffTimeLeadTime_v2',
            },
            __typename: 'CutOffTimePerDayRule_v2',
          },
          _5: {
            enabled: false,
            dayOfWeekIndex: 5,
            hour: 0,
            minute: 0,
            leadTime: {
              days: 0,
              __typename: 'CutOffTimeLeadTime_v2',
            },
            __typename: 'CutOffTimePerDayRule_v2',
          },
          _6: {
            enabled: false,
            dayOfWeekIndex: 6,
            hour: 0,
            minute: 0,
            leadTime: {
              days: 0,
              __typename: 'CutOffTimeLeadTime_v2',
            },
            __typename: 'CutOffTimePerDayRule_v2',
          },
          __typename: 'CutOffTimePerDayList_v2',
        },
        __typename: 'CutOffTime_v2',
      },
      __typename: 'ProductType',
    },
    message: '',
    isSelected: true,
    deliveryAddress: null,
    deliveryOption: null,
    originalPrice: 261.16,
    deliveryTerms: null,
    totalPrice: 261.16,
    totalItems: 4,
    totalQty: 11,
    __typename: 'CartDeliveryOptionV2',
  },
  {
    _id: '669e495b52ab6c001a3151fc',
    vendorId: 'e76d48970971564e560d4aa4',
    deliveryDate: '2024-08-11T12:00:00.000Z',
    defaultDeliveryDate: '2024-08-11T12:00:00.000Z',
    blockedDeliveryDaysIndexes: [],
    availableDeliveryDates: [
      '2024-08-11T12:00:00.000Z',
      '2024-08-13T12:00:00.000Z',
      '2024-08-14T12:00:00.000Z',
      '2024-08-18T12:00:00.000Z',
      '2024-08-20T12:00:00.000Z',
      '2024-08-21T12:00:00.000Z',
      '2024-08-25T12:00:00.000Z',
      '2024-08-27T12:00:00.000Z',
      '2024-08-28T12:00:00.000Z',
      '2024-09-01T12:00:00.000Z',
      '2024-09-03T12:00:00.000Z',
      '2024-09-04T12:00:00.000Z',
      '2024-09-08T12:00:00.000Z',
      '2024-09-10T12:00:00.000Z',
      '2024-09-11T12:00:00.000Z',
      '2024-09-15T12:00:00.000Z',
      '2024-09-17T12:00:00.000Z',
      '2024-09-18T12:00:00.000Z',
      '2024-09-22T12:00:00.000Z',
      '2024-09-24T12:00:00.000Z',
      '2024-09-25T12:00:00.000Z',
      '2024-09-29T12:00:00.000Z',
      '2024-10-01T12:00:00.000Z',
      '2024-10-02T12:00:00.000Z',
      '2024-10-06T12:00:00.000Z',
      '2024-10-08T12:00:00.000Z',
      '2024-10-09T12:00:00.000Z',
      '2024-10-13T12:00:00.000Z',
      '2024-10-15T12:00:00.000Z',
      '2024-10-16T12:00:00.000Z',
      '2024-10-20T12:00:00.000Z',
      '2024-10-22T12:00:00.000Z',
      '2024-10-23T12:00:00.000Z',
      '2024-10-27T12:00:00.000Z',
      '2024-10-29T12:00:00.000Z',
      '2024-10-30T12:00:00.000Z',
      '2024-11-03T12:00:00.000Z',
      '2024-11-05T12:00:00.000Z',
      '2024-11-06T12:00:00.000Z',
      '2024-11-10T12:00:00.000Z',
      '2024-11-12T12:00:00.000Z',
      '2024-11-13T12:00:00.000Z',
      '2024-11-17T12:00:00.000Z',
    ],
    blockedDeliveryDates: [
      '2024-08-06T12:00:00.000Z',
      '2024-08-07T12:00:00.000Z',
    ],
    productType: {
      _id: '64901ca23331f900139693f3',
      readableId: '15 Days',
      name: {
        en: null,
        de: '15 days delivery',
        fr: null,
        es: null,
        ve: null,
        __typename: 'Translations',
      },
      subTitle: {
        en: null,
        de: '15_Days',
        fr: null,
        es: null,
        ve: null,
        __typename: 'Translations',
      },
      description: {
        en: null,
        de: 'Delivery takes around 15-18 business days',
        fr: null,
        es: null,
        ve: null,
        __typename: 'Translations',
      },
      icon:
        'https://storage.googleapis.com/chfslst-product-images/e76d48970971564e560d4aa4/productType-images/15-days-no-reason-to-return.png',
      iconColor: null,
      hideAtProduct: null,
      isDefault: null,
      cutOffTime: {
        enabled: true,
        generic: {
          hour: 18,
          minute: 0,
          leadTime: {
            days: 15,
            __typename: 'CutOffTimeLeadTime_v2',
          },
          __typename: 'CutOffTimeGenericRule_v2',
        },
        perDay: {
          _0: {
            enabled: false,
            dayOfWeekIndex: 0,
            hour: 0,
            minute: 0,
            leadTime: {
              days: 0,
              __typename: 'CutOffTimeLeadTime_v2',
            },
            __typename: 'CutOffTimePerDayRule_v2',
          },
          _1: {
            enabled: false,
            dayOfWeekIndex: 1,
            hour: 0,
            minute: 0,
            leadTime: {
              days: 0,
              __typename: 'CutOffTimeLeadTime_v2',
            },
            __typename: 'CutOffTimePerDayRule_v2',
          },
          _2: {
            enabled: false,
            dayOfWeekIndex: 2,
            hour: 0,
            minute: 0,
            leadTime: {
              days: 0,
              __typename: 'CutOffTimeLeadTime_v2',
            },
            __typename: 'CutOffTimePerDayRule_v2',
          },
          _3: {
            enabled: false,
            dayOfWeekIndex: 3,
            hour: 0,
            minute: 0,
            leadTime: {
              days: 0,
              __typename: 'CutOffTimeLeadTime_v2',
            },
            __typename: 'CutOffTimePerDayRule_v2',
          },
          _4: {
            enabled: false,
            dayOfWeekIndex: 4,
            hour: 0,
            minute: 0,
            leadTime: {
              days: 0,
              __typename: 'CutOffTimeLeadTime_v2',
            },
            __typename: 'CutOffTimePerDayRule_v2',
          },
          _5: {
            enabled: false,
            dayOfWeekIndex: 5,
            hour: 0,
            minute: 0,
            leadTime: {
              days: 0,
              __typename: 'CutOffTimeLeadTime_v2',
            },
            __typename: 'CutOffTimePerDayRule_v2',
          },
          _6: {
            enabled: false,
            dayOfWeekIndex: 6,
            hour: 0,
            minute: 0,
            leadTime: {
              days: 0,
              __typename: 'CutOffTimeLeadTime_v2',
            },
            __typename: 'CutOffTimePerDayRule_v2',
          },
          __typename: 'CutOffTimePerDayList_v2',
        },
        __typename: 'CutOffTime_v2',
      },
      __typename: 'ProductType',
    },
    message: '',
    isSelected: true,
    deliveryAddress: null,
    deliveryOption: null,
    originalPrice: 52.97,
    deliveryTerms: {
      discount: null,
      deliveryFee: {
        value: 0,
        description: null,
        __typename: 'DeliveryTerm',
      },
      minSurcharge: {
        value: 0,
        description: null,
        __typename: 'DeliveryTerm',
      },
      __typename: 'VendorDeliveryTerms',
    },
    totalPrice: 52.97,
    totalItems: 2,
    totalQty: 7,
    __typename: 'CartDeliveryOptionV2',
  },
  {
    _id: '669e495d52ab6c001a31520b',
    vendorId: 'e76d48970971564e560d4aa4',
    deliveryDate: '2024-07-23T12:00:00.000Z',
    defaultDeliveryDate: '2024-07-23T12:00:00.000Z',
    blockedDeliveryDaysIndexes: [],
    availableDeliveryDates: [
      '2024-07-23T12:00:00.000Z',
      '2024-07-24T12:00:00.000Z',
      '2024-07-26T12:00:00.000Z',
      '2024-07-29T12:00:00.000Z',
      '2024-07-30T12:00:00.000Z',
      '2024-07-31T12:00:00.000Z',
      '2024-08-02T12:00:00.000Z',
      '2024-08-03T12:00:00.000Z',
      '2024-08-05T12:00:00.000Z',
      '2024-08-10T12:00:00.000Z',
      '2024-08-12T12:00:00.000Z',
      '2024-08-13T12:00:00.000Z',
      '2024-08-14T12:00:00.000Z',
      '2024-08-16T12:00:00.000Z',
      '2024-08-17T12:00:00.000Z',
      '2024-08-19T12:00:00.000Z',
      '2024-08-20T12:00:00.000Z',
      '2024-08-21T12:00:00.000Z',
      '2024-08-23T12:00:00.000Z',
      '2024-08-24T12:00:00.000Z',
      '2024-08-26T12:00:00.000Z',
      '2024-08-27T12:00:00.000Z',
      '2024-08-28T12:00:00.000Z',
      '2024-08-30T12:00:00.000Z',
      '2024-08-31T12:00:00.000Z',
      '2024-09-02T12:00:00.000Z',
      '2024-09-03T12:00:00.000Z',
      '2024-09-04T12:00:00.000Z',
      '2024-09-06T12:00:00.000Z',
      '2024-09-07T12:00:00.000Z',
      '2024-09-09T12:00:00.000Z',
      '2024-09-10T12:00:00.000Z',
      '2024-09-11T12:00:00.000Z',
      '2024-09-13T12:00:00.000Z',
      '2024-09-14T12:00:00.000Z',
      '2024-09-16T12:00:00.000Z',
      '2024-09-17T12:00:00.000Z',
      '2024-09-18T12:00:00.000Z',
      '2024-09-20T12:00:00.000Z',
      '2024-09-21T12:00:00.000Z',
      '2024-09-23T12:00:00.000Z',
      '2024-09-24T12:00:00.000Z',
      '2024-09-25T12:00:00.000Z',
      '2024-09-27T12:00:00.000Z',
      '2024-09-28T12:00:00.000Z',
      '2024-09-30T12:00:00.000Z',
      '2024-10-01T12:00:00.000Z',
      '2024-10-02T12:00:00.000Z',
      '2024-10-04T12:00:00.000Z',
      '2024-10-05T12:00:00.000Z',
      '2024-10-07T12:00:00.000Z',
      '2024-10-08T12:00:00.000Z',
      '2024-10-09T12:00:00.000Z',
      '2024-10-11T12:00:00.000Z',
      '2024-10-12T12:00:00.000Z',
      '2024-10-14T12:00:00.000Z',
      '2024-10-15T12:00:00.000Z',
      '2024-10-16T12:00:00.000Z',
      '2024-10-18T12:00:00.000Z',
      '2024-10-19T12:00:00.000Z',
      '2024-10-21T12:00:00.000Z',
      '2024-10-22T12:00:00.000Z',
      '2024-10-23T12:00:00.000Z',
      '2024-10-25T12:00:00.000Z',
      '2024-10-26T12:00:00.000Z',
      '2024-10-28T12:00:00.000Z',
      '2024-10-29T12:00:00.000Z',
      '2024-10-30T12:00:00.000Z',
      '2024-11-01T12:00:00.000Z',
      '2024-11-02T12:00:00.000Z',
      '2024-11-04T12:00:00.000Z',
      '2024-11-05T12:00:00.000Z',
      '2024-11-06T12:00:00.000Z',
      '2024-11-08T12:00:00.000Z',
      '2024-11-09T12:00:00.000Z',
      '2024-11-11T12:00:00.000Z',
      '2024-11-12T12:00:00.000Z',
      '2024-11-13T12:00:00.000Z',
      '2024-11-15T12:00:00.000Z',
      '2024-11-16T12:00:00.000Z',
    ],
    blockedDeliveryDates: [
      '2024-07-27T12:00:00.000Z',
      '2024-08-06T12:00:00.000Z',
      '2024-08-07T12:00:00.000Z',
      '2024-08-09T12:00:00.000Z',
    ],
    productType: {
      _id: '63eb411d8409640013dddaf0',
      readableId: 'Cut',
      name: {
        en: null,
        de: 'Cut of test',
        fr: null,
        es: null,
        ve: null,
        __typename: 'Translations',
      },
      subTitle: null,
      description: null,
      icon:
        'https://storage.googleapis.com/chfslst-static-files/cut%20products.png',
      iconColor: null,
      hideAtProduct: null,
      isDefault: null,
      cutOffTime: {
        enabled: true,
        generic: {
          hour: 14,
          minute: 30,
          leadTime: {
            days: 1,
            __typename: 'CutOffTimeLeadTime_v2',
          },
          __typename: 'CutOffTimeGenericRule_v2',
        },
        perDay: {
          _0: {
            enabled: false,
            dayOfWeekIndex: 0,
            hour: 0,
            minute: 0,
            leadTime: {
              days: 0,
              __typename: 'CutOffTimeLeadTime_v2',
            },
            __typename: 'CutOffTimePerDayRule_v2',
          },
          _1: {
            enabled: false,
            dayOfWeekIndex: 1,
            hour: 0,
            minute: 0,
            leadTime: {
              days: 0,
              __typename: 'CutOffTimeLeadTime_v2',
            },
            __typename: 'CutOffTimePerDayRule_v2',
          },
          _2: {
            enabled: false,
            dayOfWeekIndex: 2,
            hour: 0,
            minute: 0,
            leadTime: {
              days: 0,
              __typename: 'CutOffTimeLeadTime_v2',
            },
            __typename: 'CutOffTimePerDayRule_v2',
          },
          _3: {
            enabled: false,
            dayOfWeekIndex: 3,
            hour: 0,
            minute: 0,
            leadTime: {
              days: 0,
              __typename: 'CutOffTimeLeadTime_v2',
            },
            __typename: 'CutOffTimePerDayRule_v2',
          },
          _4: {
            enabled: false,
            dayOfWeekIndex: 4,
            hour: 0,
            minute: 0,
            leadTime: {
              days: 0,
              __typename: 'CutOffTimeLeadTime_v2',
            },
            __typename: 'CutOffTimePerDayRule_v2',
          },
          _5: {
            enabled: false,
            dayOfWeekIndex: 5,
            hour: 0,
            minute: 0,
            leadTime: {
              days: 0,
              __typename: 'CutOffTimeLeadTime_v2',
            },
            __typename: 'CutOffTimePerDayRule_v2',
          },
          _6: {
            enabled: false,
            dayOfWeekIndex: 6,
            hour: 0,
            minute: 0,
            leadTime: {
              days: 0,
              __typename: 'CutOffTimeLeadTime_v2',
            },
            __typename: 'CutOffTimePerDayRule_v2',
          },
          __typename: 'CutOffTimePerDayList_v2',
        },
        __typename: 'CutOffTime_v2',
      },
      __typename: 'ProductType',
    },
    message: '',
    isSelected: true,
    deliveryAddress: null,
    deliveryOption: null,
    originalPrice: 0,
    deliveryTerms: null,
    totalPrice: 0,
    totalItems: 1,
    totalQty: 3,
    __typename: 'CartDeliveryOptionV2',
  },
  {
    _id: '669e497a52ab6c001a315237',
    vendorId: 'e76d48970971564e560d4aa4',
    deliveryDate: '2024-07-22T12:00:00.000Z',
    defaultDeliveryDate: '2024-07-22T12:00:00.000Z',
    blockedDeliveryDaysIndexes: [],
    availableDeliveryDates: [
      '2024-07-22T12:00:00.000Z',
      '2024-07-23T12:00:00.000Z',
      '2024-07-24T12:00:00.000Z',
      '2024-07-25T12:00:00.000Z',
      '2024-07-26T12:00:00.000Z',
      '2024-07-28T12:00:00.000Z',
      '2024-07-29T12:00:00.000Z',
      '2024-07-30T12:00:00.000Z',
      '2024-07-31T12:00:00.000Z',
      '2024-08-01T12:00:00.000Z',
      '2024-08-02T12:00:00.000Z',
      '2024-08-03T12:00:00.000Z',
      '2024-08-04T12:00:00.000Z',
      '2024-08-05T12:00:00.000Z',
      '2024-08-10T12:00:00.000Z',
      '2024-08-11T12:00:00.000Z',
      '2024-08-12T12:00:00.000Z',
      '2024-08-13T12:00:00.000Z',
      '2024-08-14T12:00:00.000Z',
      '2024-08-15T12:00:00.000Z',
      '2024-08-16T12:00:00.000Z',
      '2024-08-17T12:00:00.000Z',
      '2024-08-18T12:00:00.000Z',
      '2024-08-19T12:00:00.000Z',
      '2024-08-20T12:00:00.000Z',
      '2024-08-21T12:00:00.000Z',
      '2024-08-22T12:00:00.000Z',
      '2024-08-23T12:00:00.000Z',
      '2024-08-24T12:00:00.000Z',
      '2024-08-25T12:00:00.000Z',
      '2024-08-26T12:00:00.000Z',
      '2024-08-27T12:00:00.000Z',
      '2024-08-28T12:00:00.000Z',
      '2024-08-29T12:00:00.000Z',
      '2024-08-30T12:00:00.000Z',
      '2024-08-31T12:00:00.000Z',
      '2024-09-01T12:00:00.000Z',
      '2024-09-02T12:00:00.000Z',
      '2024-09-03T12:00:00.000Z',
      '2024-09-04T12:00:00.000Z',
      '2024-09-05T12:00:00.000Z',
      '2024-09-06T12:00:00.000Z',
      '2024-09-07T12:00:00.000Z',
      '2024-09-08T12:00:00.000Z',
      '2024-09-09T12:00:00.000Z',
      '2024-09-10T12:00:00.000Z',
      '2024-09-11T12:00:00.000Z',
      '2024-09-12T12:00:00.000Z',
      '2024-09-13T12:00:00.000Z',
      '2024-09-14T12:00:00.000Z',
      '2024-09-15T12:00:00.000Z',
      '2024-09-16T12:00:00.000Z',
      '2024-09-17T12:00:00.000Z',
      '2024-09-18T12:00:00.000Z',
      '2024-09-19T12:00:00.000Z',
      '2024-09-20T12:00:00.000Z',
      '2024-09-21T12:00:00.000Z',
      '2024-09-22T12:00:00.000Z',
      '2024-09-23T12:00:00.000Z',
      '2024-09-24T12:00:00.000Z',
      '2024-09-25T12:00:00.000Z',
      '2024-09-26T12:00:00.000Z',
      '2024-09-27T12:00:00.000Z',
      '2024-09-28T12:00:00.000Z',
      '2024-09-29T12:00:00.000Z',
      '2024-09-30T12:00:00.000Z',
      '2024-10-01T12:00:00.000Z',
      '2024-10-02T12:00:00.000Z',
      '2024-10-03T12:00:00.000Z',
      '2024-10-04T12:00:00.000Z',
      '2024-10-05T12:00:00.000Z',
      '2024-10-06T12:00:00.000Z',
      '2024-10-07T12:00:00.000Z',
      '2024-10-08T12:00:00.000Z',
      '2024-10-09T12:00:00.000Z',
      '2024-10-10T12:00:00.000Z',
      '2024-10-11T12:00:00.000Z',
      '2024-10-12T12:00:00.000Z',
      '2024-10-13T12:00:00.000Z',
      '2024-10-14T12:00:00.000Z',
      '2024-10-15T12:00:00.000Z',
      '2024-10-16T12:00:00.000Z',
      '2024-10-17T12:00:00.000Z',
      '2024-10-18T12:00:00.000Z',
      '2024-10-19T12:00:00.000Z',
      '2024-10-20T12:00:00.000Z',
      '2024-10-21T12:00:00.000Z',
      '2024-10-22T12:00:00.000Z',
      '2024-10-23T12:00:00.000Z',
      '2024-10-24T12:00:00.000Z',
      '2024-10-25T12:00:00.000Z',
      '2024-10-26T12:00:00.000Z',
      '2024-10-27T12:00:00.000Z',
      '2024-10-28T12:00:00.000Z',
      '2024-10-29T12:00:00.000Z',
      '2024-10-30T12:00:00.000Z',
      '2024-10-31T12:00:00.000Z',
      '2024-11-01T12:00:00.000Z',
      '2024-11-02T12:00:00.000Z',
      '2024-11-03T12:00:00.000Z',
      '2024-11-04T12:00:00.000Z',
      '2024-11-05T12:00:00.000Z',
      '2024-11-06T12:00:00.000Z',
      '2024-11-07T12:00:00.000Z',
      '2024-11-08T12:00:00.000Z',
      '2024-11-09T12:00:00.000Z',
      '2024-11-10T12:00:00.000Z',
      '2024-11-11T12:00:00.000Z',
      '2024-11-12T12:00:00.000Z',
      '2024-11-13T12:00:00.000Z',
      '2024-11-14T12:00:00.000Z',
      '2024-11-15T12:00:00.000Z',
      '2024-11-16T12:00:00.000Z',
      '2024-11-17T12:00:00.000Z',
    ],
    blockedDeliveryDates: [
      '2024-07-27T12:00:00.000Z',
      '2024-08-06T12:00:00.000Z',
      '2024-08-07T12:00:00.000Z',
      '2024-08-08T12:00:00.000Z',
      '2024-08-09T12:00:00.000Z',
    ],
    productType: {
      _id: '6490205f3331f90013969466',
      readableId: 'Berlin',
      name: {
        en: null,
        de: 'Berlin Warehouse',
        fr: null,
        es: null,
        ve: null,
        __typename: 'Translations',
      },
      subTitle: {
        en: null,
        de: 'Berlin_warehouse 11',
        fr: null,
        es: null,
        ve: null,
        __typename: 'Translations',
      },
      description: {
        en: null,
        de: 'Warehouse is placed in Berlin, please consider this',
        fr: null,
        es: null,
        ve: null,
        __typename: 'Translations',
      },
      icon:
        'https://storage.googleapis.com/chfslst-product-images/e76d48970971564e560d4aa4/productType-images/475572.png',
      iconColor: null,
      hideAtProduct: null,
      isDefault: null,
      cutOffTime: {
        enabled: true,
        generic: {
          hour: 16,
          minute: 0,
          leadTime: {
            days: 2,
            __typename: 'CutOffTimeLeadTime_v2',
          },
          __typename: 'CutOffTimeGenericRule_v2',
        },
        perDay: {
          _0: {
            enabled: false,
            dayOfWeekIndex: 0,
            hour: 0,
            minute: 0,
            leadTime: {
              days: 0,
              __typename: 'CutOffTimeLeadTime_v2',
            },
            __typename: 'CutOffTimePerDayRule_v2',
          },
          _1: {
            enabled: true,
            dayOfWeekIndex: 1,
            hour: 15,
            minute: 0,
            leadTime: {
              days: 0,
              __typename: 'CutOffTimeLeadTime_v2',
            },
            __typename: 'CutOffTimePerDayRule_v2',
          },
          _2: {
            enabled: true,
            dayOfWeekIndex: 2,
            hour: 11,
            minute: 20,
            leadTime: {
              days: 1,
              __typename: 'CutOffTimeLeadTime_v2',
            },
            __typename: 'CutOffTimePerDayRule_v2',
          },
          _3: {
            enabled: true,
            dayOfWeekIndex: 3,
            hour: 11,
            minute: 20,
            leadTime: {
              days: 1,
              __typename: 'CutOffTimeLeadTime_v2',
            },
            __typename: 'CutOffTimePerDayRule_v2',
          },
          _4: {
            enabled: true,
            dayOfWeekIndex: 4,
            hour: 8,
            minute: 50,
            leadTime: {
              days: 2,
              __typename: 'CutOffTimeLeadTime_v2',
            },
            __typename: 'CutOffTimePerDayRule_v2',
          },
          _5: {
            enabled: false,
            dayOfWeekIndex: 5,
            hour: 0,
            minute: 0,
            leadTime: {
              days: 0,
              __typename: 'CutOffTimeLeadTime_v2',
            },
            __typename: 'CutOffTimePerDayRule_v2',
          },
          _6: {
            enabled: false,
            dayOfWeekIndex: 6,
            hour: 0,
            minute: 0,
            leadTime: {
              days: 0,
              __typename: 'CutOffTimeLeadTime_v2',
            },
            __typename: 'CutOffTimePerDayRule_v2',
          },
          __typename: 'CutOffTimePerDayList_v2',
        },
        __typename: 'CutOffTime_v2',
      },
      __typename: 'ProductType',
    },
    message: '',
    isSelected: true,
    deliveryAddress: null,
    deliveryOption: null,
    originalPrice: 5.7,
    deliveryTerms: {
      discount: null,
      deliveryFee: {
        value: 0,
        description: null,
        __typename: 'DeliveryTerm',
      },
      minSurcharge: {
        value: 0,
        description: null,
        __typename: 'DeliveryTerm',
      },
      __typename: 'VendorDeliveryTerms',
    },
    totalPrice: 5.7,
    totalItems: 1,
    totalQty: 3,
    __typename: 'CartDeliveryOptionV2',
  },
];

/* eslint-disable max-len */
export default {
  vendorOrders: {
    page: 1,
    totalPages: 1,
    hasNextPage: true,
    nextPage: null,
    hasPrevPage: false,
    prevPage: null,
    totalResults: 4,
    ordersOriginal: [
      {
        _id: '6662d00e40a84daa496cded3',
        customer: {
          _id: '212f5e9aafd09cbdaf3f9567',
          vendorClientId: '002',
          contactData: {
            businessName: 'rlt restaurant',
            __typename: 'AccountMeta',
          },
          __typename: 'Customer',
        },
        vendorId: 'e76d48970971564e560d4aa4',
        customerId: '3e57c5b7b338f6ddd76bb475',
        status: 'pending',
        deliveryDate: '2024-06-23T12:00:00.000Z',
        orderedAt: '2024-06-07T09:17:02.954Z',
        deliveryDetails: {
          message:
            'In dieser Bestellung befinden sich folgende Artikel aus „MHD-Angeboten“: 1160.',
          __typename: 'DeliveryDetails',
        },
        orderNumber: 36991,
        deliveryAddress: {
          zip: '10315',
          city: 'Berlin',
          houseNumber: '1',
          street: 'Teststrase',
          country: 'DE',
          __typename: 'DeliveryAddress',
        },
        userProfileName: 'n/a',
        products: [
          {
            _id: '63ff4bee556bbe0013801267',
            name: 'Vaca (MHD-Angebot)',
            variantId: 'KG Deal',
            packaging: 'KG Deal',
            qty: 5,
            price: 1,
            __typename: 'OrderProduct',
          },
        ],
        net: null,
        gross: null,
        paymentMethod: {
          name: 'default',
          documents: null,
          paymentInfo: null,
          __typename: 'PaymentMethod',
        },
        totalPrice: 5,
        __typename: 'Order',
      },
      {
        _id: '6662d00e40a84daa496cdece',
        customer: {
          _id: '212f5e9aafd09cbdaf3f9567',
          vendorClientId: '002',
          contactData: {
            businessName: 'rlt restaurant',
            __typename: 'AccountMeta',
          },
          __typename: 'Customer',
        },
        vendorId: 'e76d48970971564e560d4aa4',
        customerId: '3e57c5b7b338f6ddd76bb475',
        status: 'pending',
        deliveryDate: '2024-06-08T12:00:00.000Z',
        orderedAt: '2024-06-07T09:17:02.947Z',
        deliveryDetails: {
          message:
            'In dieser Bestellung befinden sich folgende Artikel aus „MHD-Angeboten“: 0998.',
          __typename: 'DeliveryDetails',
        },
        orderNumber: 36990,
        deliveryAddress: {
          zip: '10315',
          city: 'Berlin',
          houseNumber: '1',
          street: 'Teststrase',
          country: 'DE',
          __typename: 'DeliveryAddress',
        },
        userProfileName: 'n/a',
        products: [
          {
            _id: '63ff4bb8556bbe0013801253',
            name: 'Trüffel Box (MHD-Angebot)',
            variantId: '15 KG Deal',
            packaging: '15 KG Deal',
            qty: 9,
            price: 50,
            __typename: 'OrderProduct',
          },
          {
            _id: '5f0ebf0a77689600122b39a5',
            name: 'Kartoffeln',
            variantId: 'Sack',
            packaging: 'Sack',
            qty: 9,
            price: 5.99,
            __typename: 'OrderProduct',
          },
          {
            _id: '63ff4bb8556bbe0013801253',
            name: 'Trüffel Box',
            variantId: '25 KG',
            packaging: '25 KG',
            qty: 10,
            price: 2200,
            __typename: 'OrderProduct',
          },
          {
            _id: '5f0ebf0a77689600122b39a9',
            name: 'Joghurt ',
            variantId: 'Packung',
            packaging: 'Packung',
            qty: 3,
            price: 1,
            __typename: 'OrderProduct',
          },
          {
            _id: '5f11a39b2a34290012536888',
            name: 'Zitronen',
            variantId: 'Stück (0.1KG)',
            packaging: 'Stück (0.1KG)',
            qty: 2,
            price: 1,
            __typename: 'OrderProduct',
          },
          {
            _id: '5f27ddc1e8d1d50019797985',
            name: 'Koffeinfreier kaffee',
            variantId: 'Packung (1KG)',
            packaging: 'Packung (1KG)',
            qty: 9,
            price: 8.99,
            __typename: 'OrderProduct',
          },
        ],
        net: null,
        gross: null,
        paymentMethod: {
          name: 'default',
          documents: null,
          paymentInfo: null,
          __typename: 'PaymentMethod',
        },
        totalPrice: 22589.82,
        __typename: 'Order',
      },
      {
        _id: '6662c26040a84daa496cd9f9',
        customer: {
          _id: '632841c0bdfb34001a077d9e',
          vendorClientId: '001',
          contactData: {
            businessName: 'Ladurée',
            __typename: 'AccountMeta',
          },
          __typename: 'Customer',
        },
        vendorId: 'e76d48970971564e560d4aa4',
        customerId: '632841c0bdfb34001a077d9e',
        status: 'pending',
        deliveryDate: '2024-06-08T12:00:00.000Z',
        orderedAt: '2024-06-07T08:18:40.191Z',
        deliveryDetails: {
          message: '',
          __typename: 'DeliveryDetails',
        },
        orderNumber: 36989,
        deliveryAddress: {
          zip: '75008',
          city: 'Paris',
          houseNumber: '75',
          street: 'Av. des Champs-Élysées',
          country: 'FR',
          __typename: 'DeliveryAddress',
        },
        userProfileName: 'n/a',
        products: [
          {
            _id: '5f27ddc1e8d1d5001979795c',
            name: 'Maisels weisse  0,3 l',
            variantId: 'Flasche (0.3L)',
            packaging: 'Flasche (0.3L)',
            qty: 8,
            price: 0.7,
            __typename: 'OrderProduct',
          },
          {
            _id: '5f27ddc1e8d1d50019797980',
            name: 'Green himalaya',
            variantId: 'Packung (0.5KG)',
            packaging: 'Packung (0.5KG)',
            qty: 4,
            price: 12,
            __typename: 'OrderProduct',
          },
          {
            _id: '5f27ddc1e8d1d500197979a5',
            name: 'Bierbecher 0,3 l',
            variantId: 'Packung (50STK)',
            packaging: 'Packung (50STK)',
            qty: 4,
            price: 9,
            __typename: 'OrderProduct',
          },
          {
            _id: '5f27ddc1e8d1d500197979a6',
            name: '1/8 servietten',
            variantId: 'Packung (50STK)',
            packaging: 'Packung (50STK)',
            qty: 10,
            price: 9,
            __typename: 'OrderProduct',
          },
        ],
        net: null,
        gross: null,
        paymentMethod: {
          name: 'default',
          documents: null,
          paymentInfo: null,
          __typename: 'PaymentMethod',
        },
        totalPrice: 179.6,
        __typename: 'Order',
      },
      {
        _id: '66622a23836f3c25c683a341',
        customer: {
          _id: '212f5e9aafd09cbdaf3f9567',
          vendorClientId: '002',
          contactData: {
            businessName: 'rlt restaurant',
            __typename: 'AccountMeta',
          },
          __typename: 'Customer',
        },
        vendorId: 'e76d48970971564e560d4aa4',
        customerId: '3e57c5b7b338f6ddd76bb475',
        status: 'pending',
        deliveryDate: '2024-06-08T12:00:00.000Z',
        orderedAt: '2024-06-06T21:29:07.588Z',
        deliveryDetails: {
          message: '',
          __typename: 'DeliveryDetails',
        },
        orderNumber: 36988,
        deliveryAddress: {
          zip: '10315',
          city: 'Berlin',
          houseNumber: '1',
          street: 'Teststrase',
          country: 'DE',
          __typename: 'DeliveryAddress',
        },
        userProfileName: 'n/a',
        products: [
          {
            _id: '63ff4bb8556bbe0013801253',
            name: 'Trüffel Box',
            variantId: '15 KG',
            packaging: '15 KG',
            qty: 1,
            price: 720,
            __typename: 'OrderProduct',
          },
        ],
        net: null,
        gross: null,
        paymentMethod: {
          name: 'default',
          documents: null,
          paymentInfo: null,
          __typename: 'PaymentMethod',
        },
        totalPrice: 720,
        __typename: 'Order',
      },
      {
        _id: '66622a23836f3c25c683a33e',
        customer: {
          _id: '212f5e9aafd09cbdaf3f9567',
          vendorClientId: '002',
          contactData: {
            businessName: 'rlt restaurant',
            __typename: 'AccountMeta',
          },
          __typename: 'Customer',
        },
        vendorId: 'e76d48970971564e560d4aa4',
        customerId: '3e57c5b7b338f6ddd76bb475',
        status: 'pending',
        deliveryDate: '2024-06-07T12:00:00.000Z',
        orderedAt: '2024-06-06T21:29:07.573Z',
        deliveryDetails: {
          message: '',
          __typename: 'DeliveryDetails',
        },
        orderNumber: 36987,
        deliveryAddress: {
          zip: '10315',
          city: 'Berlin',
          houseNumber: '1',
          street: 'Teststrase',
          country: 'DE',
          __typename: 'DeliveryAddress',
        },
        userProfileName: 'n/a',
        products: [
          {
            _id: '63ff4bb8556bbe0013801253',
            name: 'Trüffel Box',
            variantId: '25 KG',
            packaging: '25 KG',
            qty: 16,
            price: 2200,
            __typename: 'OrderProduct',
          },
          {
            _id: '5f0ebf0a77689600122b39a5',
            name: 'Kartoffeln',
            variantId: 'Sack',
            packaging: 'Sack',
            qty: 10,
            price: 5.99,
            __typename: 'OrderProduct',
          },
          {
            _id: '5f27ddc1e8d1d5001979795f',
            name: 'Zwickl krug 0,5 l',
            variantId: 'Flasche (0.5L)',
            packaging: 'Flasche (0.5L)',
            qty: 5,
            price: 0.4,
            __typename: 'OrderProduct',
          },
        ],
        net: null,
        gross: null,
        paymentMethod: {
          name: 'default',
          documents: null,
          paymentInfo: null,
          __typename: 'PaymentMethod',
        },
        totalPrice: 35261.9,
        __typename: 'Order',
      },
      {
        _id: '6660541b2e6bf350b13fe09f',
        customer: {
          _id: '62ab0d53f9edc4001b86008e',
          vendorClientId: '263385',
          contactData: {
            businessName: 'Café Nomad',
            __typename: 'AccountMeta',
          },
          __typename: 'Customer',
        },
        vendorId: 'e76d48970971564e560d4aa4',
        customerId: '62ab0d53f9edc4001b86008e',
        status: 'pending',
        deliveryDate: '2024-06-06T12:00:00.000Z',
        orderedAt: '2024-06-05T12:03:39.842Z',
        deliveryDetails: {
          message:
            'In dieser Bestellung befinden sich folgende Artikel aus „MHD-Angeboten“: 0998.',
          __typename: 'DeliveryDetails',
        },
        orderNumber: 36985,
        deliveryAddress: {
          zip: '69115',
          city: 'Heidelberg',
          houseNumber: '49',
          street: 'Rohrbacherstraße',
          country: 'DE',
          __typename: 'DeliveryAddress',
        },
        userProfileName: 'n/a',
        products: [
          {
            _id: '5f0ebf0a77689600122b39ab',
            name: 'Bergsalat',
            variantId: 'stk',
            packaging: 'stk',
            qty: 3,
            price: 0.79,
            __typename: 'OrderProduct',
          },
          {
            _id: '5f27ddc1e8d1d5001979795c',
            name: 'Maisels weisse  0,3 l',
            variantId: 'Flasche (0.3L)',
            packaging: 'Flasche (0.3L)',
            qty: 2,
            price: 0.34,
            __typename: 'OrderProduct',
          },
          {
            _id: '5f27ddc1e8d1d500197979a5',
            name: 'Bierbecher 0,3 l',
            variantId: 'Packung (50STK)',
            packaging: 'Packung (50STK)',
            qty: 1,
            price: 9,
            __typename: 'OrderProduct',
          },
          {
            _id: '5f27ddc1e8d1d50019797988',
            name: 'Hausmanns rot (6x0,7 L)',
            variantId: 'Packung (6x0,7L)',
            packaging: 'Packung (6x0,7L)',
            qty: 3,
            price: 7,
            __typename: 'OrderProduct',
          },
          {
            _id: '63ff4bb8556bbe0013801253',
            name: 'Trüffel Box (MHD-Angebot)',
            variantId: '15 KG Deal',
            packaging: '15 KG Deal',
            qty: 5,
            price: 50,
            __typename: 'OrderProduct',
          },
        ],
        net: null,
        gross: null,
        paymentMethod: {
          name: 'default',
          documents: null,
          paymentInfo: null,
          __typename: 'PaymentMethod',
        },
        totalPrice: 283.05,
        __typename: 'Order',
      },
      {
        _id: '66604fdc2e6bf350b13fdf86',
        customer: {
          _id: '212f5e9aafd09cbdaf3f9567',
          vendorClientId: '002',
          contactData: {
            businessName: 'rlt restaurant',
            __typename: 'AccountMeta',
          },
          __typename: 'Customer',
        },
        vendorId: 'e76d48970971564e560d4aa4',
        customerId: '3e57c5b7b338f6ddd76bb475',
        status: 'pending',
        deliveryDate: '2024-06-06T12:00:00.000Z',
        orderedAt: '2024-06-05T11:45:32.004Z',
        deliveryDetails: {
          message:
            'In dieser Bestellung befinden sich folgende Artikel aus „MHD-Angeboten“: 0998.',
          __typename: 'DeliveryDetails',
        },
        orderNumber: 36984,
        deliveryAddress: {
          zip: '10315',
          city: 'Berlin',
          houseNumber: '1',
          street: 'Teststrase',
          country: 'DE',
          __typename: 'DeliveryAddress',
        },
        userProfileName: 'n/a',
        products: [
          {
            _id: '63ff4bb8556bbe0013801253',
            name: 'Trüffel Box (MHD-Angebot)',
            variantId: '25 KG Deal',
            packaging: '25 KG Deal',
            qty: 3,
            price: 120,
            __typename: 'OrderProduct',
          },
        ],
        net: null,
        gross: null,
        paymentMethod: {
          name: 'default',
          documents: null,
          paymentInfo: null,
          __typename: 'PaymentMethod',
        },
        totalPrice: 360,
        __typename: 'Order',
      },
    ],
    __typename: 'AiOrderConnection',
    orders: [
      {
        _id: '6454d93e356cf79767e08467',
        customer: {
          orderedCustomerName: 'Bon Jameson',
          vendorClientId: '001',
          contactData: {
            businessName: 'Ladurée',
            street: 'Av. des Champs-Élysées',
            houseNumber: '75',
            zip: '75008',
            city: 'Paris',
            country: 'FR',
            __typename: 'AccountMeta',
          },
          customerId: '632841c0bdfb34001a077d9e',
        },
        aiConfidenceLevel: 0.65,
        orderedAt: '2024-07-07T08:31:00.141Z',
        orderNumber: 88001,
        orderComment:
          'More than 10 items in the order, please make sure to deliver all items on time. Thanks',
        documents: [
          {
            _id: 'asji1o2jrl1rrmfk2oilm',
            type: 'order pdf',
            documentNumber: '123456',
            documentName: 'order_daily.pdf',
            url:
              'https://storage.googleapis.com/pdf-catalogs/61e570cdadae96001bd87b6c/1702277424162-Platte_Valley_Sortiment.pdf',
          },
          {
            _id: 'asji1o2jrl1rrmfk2oxawl',
            type: 'image',
            documentNumber: '123444',
            documentName: 'order.png',
            url:
              'https://www.researchgate.net/publication/46428085/figure/fig1/AS:277392628174848@1443146964139/Handwritten-dose-order.png',
          },
        ],
        status: ORDER_STATUS.ACCEPTED,
        products: [
          {
            _id: '5f0ebf0a77689600122b39a5',
            variantId: 'Sack',
            qty: 4,
            vendorId: 'e76d48970971564e560d4aa4',
            deliveryOptionId: '6662c2a940a84daa496cda54',
            price: 5.99,
            baseUnit: 'kg',
            unitsInPackaging: 25,
            productType: '__default__',
            productCopy: {
              _id: '5f0ebf0a77689600122b39a5',
              name: 'Kartoffeln',
              description: '',
              vendorId: 'e76d48970971564e560d4aa4',
              vendorSpecificId: '077',
              image:
                'https://storage.googleapis.com/chfslst-product-images/e76d48970971564e560d4aa4/kartoffel.jpeg',
              imageUrl: null,
              categories: ['Gemüse'],
              existsInUserLists: false,
              __typename: 'Product',
            },
            variantCopy: {
              _id: '5f0ebf0a77689600122b3998',
              minOrderQuantity: 1,
              cartQuantityStep: 1,
              recommendedRetailPrice: 0,
              baseUnit: 'kg',
              baseUnitMapped: {
                en: 'Kg',
                de: 'Kg',
                __typename: 'MappedTranslation',
              },
              packagingMapped: {
                en: 'Sack',
                de: 'Sack',
                __typename: 'MappedTranslation',
              },
              packaging: 'Sack',
              packagingWeight: null,
              unitsInPackaging: 25,
              price: 5.99,
              dealPrice: null,
              purchasingPrice: 0,
              dealIsActive: false,
              originalPrice: 5.99,
              discount: null,
              productType: {
                _id: '63eddd72ad4ce800132db416',
                readableId: '__default__',
                name: null,
                subTitle: null,
                description: null,
                icon: null,
                iconColor: null,
                hideAtProduct: true,
                isDefault: true,
                __typename: 'ProductType',
              },
              __typename: 'Variant',
              dealDetails: null,
            },
            __typename: 'CartProductV2',
          },
          {
            _id: '63ff4bb8556bbe0013801253',
            variantId: '15 KG Deal',
            qty: 4,
            vendorId: 'e76d48970971564e560d4aa4',
            deliveryOptionId: '6662c54640a84daa496cdb12',
            price: 50,
            baseUnit: 'Packung',
            unitsInPackaging: 15,
            productType: 'Cut',
            productCopy: {
              _id: '63ff4bb8556bbe0013801253',
              name: 'Trüffel Box',
              description: '',
              vendorId: 'e76d48970971564e560d4aa4',
              vendorSpecificId: '0998',
              image:
                'https://storage.googleapis.com/chfslst-product-images/e76d48970971564e560d4aa4%2Fblack-truffle-2000-1b2793bc7cd749d6b5ce23e8eb12cc16.jpg',
              imageUrl: null,
              categories: ['selection_3'],
              existsInUserLists: false,
              __typename: 'Product',
            },
            variantCopy: {
              _id: '9367ca94-6e4d-45e4-9211-fc242031a23f Deal',
              minOrderQuantity: 1,
              cartQuantityStep: 1,
              recommendedRetailPrice: 0,
              baseUnit: 'Packung',
              baseUnitMapped: {
                en: 'Packung',
                de: 'Packung',
                __typename: 'MappedTranslation',
              },
              packagingMapped: null,
              packaging: '15 KG Deal',
              packagingWeight: null,
              unitsInPackaging: 15,
              price: 50,
              dealPrice: null,
              purchasingPrice: 0,
              dealIsActive: true,
              originalPrice: 720,
              discount: null,
              productType: {
                _id: '63eb411d8409640013dddaf0',
                readableId: 'Cut',
                name: {
                  en: null,
                  de: 'Cut of test',
                  fr: null,
                  es: null,
                  ve: null,
                  __typename: 'Translations',
                },
                subTitle: null,
                description: null,
                icon:
                  'https://storage.googleapis.com/chfslst-static-files/cut%20products.png',
                iconColor: null,
                hideAtProduct: null,
                isDefault: null,
                cutOffTime: {
                  enabled: true,
                  generic: {
                    hour: 14,
                    minute: 30,
                    leadTime: {
                      days: 1,
                      __typename: 'CutOffTimeLeadTime_v2',
                    },
                    __typename: 'CutOffTimeGenericRule_v2',
                  },
                  perDay: {
                    _0: {
                      enabled: false,
                      dayOfWeekIndex: 0,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _1: {
                      enabled: false,
                      dayOfWeekIndex: 1,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _2: {
                      enabled: false,
                      dayOfWeekIndex: 2,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _3: {
                      enabled: false,
                      dayOfWeekIndex: 3,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _4: {
                      enabled: false,
                      dayOfWeekIndex: 4,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _5: {
                      enabled: false,
                      dayOfWeekIndex: 5,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _6: {
                      enabled: false,
                      dayOfWeekIndex: 6,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    __typename: 'CutOffTimePerDayList_v2',
                  },
                  __typename: 'CutOffTime_v2',
                },
                __typename: 'ProductType',
              },
              __typename: 'Variant',
              dealDetails: {
                dealPrice: 50,
                dealType: 'expiring',
                enabled: true,
                end: '2025-04-10T23:59:59.999Z',
                expiringDetails: {
                  availableQty: 0,
                  expiresAt: '2025-04-11T23:59:59.999Z',
                  purchasedQty: 0,
                  __typename: 'ExpiringDetails',
                },
                originalPackaging: '15 KG',
                permanent: false,
                start: '2024-05-30T00:00:00.000Z',
                __typename: 'VariantDealDetails',
              },
            },
            __typename: 'CartProductV2',
          },
          {
            _id: '5f11a39b2a34290012536888',
            variantId: 'Stück (0.1KG)',
            qty: 3,
            vendorId: 'e76d48970971564e560d4aa4',
            deliveryOptionId: '6662c2a940a84daa496cda54',
            price: 1,
            baseUnit: 'KG',
            unitsInPackaging: 0.1,
            productType: '__default__',
            productCopy: {
              _id: '5f11a39b2a34290012536888',
              name: 'Zitronen',
              description: 'spanisch',
              vendorId: 'e76d48970971564e560d4aa4',
              vendorSpecificId: '16',
              image: '',
              imageUrl: null,
              categories: [],
              existsInUserLists: false,
              __typename: 'Product',
            },
            variantCopy: {
              _id: '5f4e3266caf4db41288b120e',
              minOrderQuantity: 1,
              cartQuantityStep: 1,
              recommendedRetailPrice: 0,
              baseUnit: 'KG',
              baseUnitMapped: {
                en: 'Kg',
                de: 'Kg',
                __typename: 'MappedTranslation',
              },
              packagingMapped: null,
              packaging: 'Stück (0.1KG)',
              packagingWeight: null,
              unitsInPackaging: 0.1,
              price: 1,
              dealPrice: null,
              purchasingPrice: 0,
              dealIsActive: false,
              originalPrice: 1,
              discount: null,
              productType: {
                _id: '63eddd72ad4ce800132db416',
                readableId: '__default__',
                name: null,
                subTitle: null,
                description: null,
                icon: null,
                iconColor: null,
                hideAtProduct: true,
                isDefault: true,
                cutOffTime: {
                  enabled: false,
                  generic: {
                    hour: 0,
                    minute: 0,
                    leadTime: {
                      days: 0,
                      __typename: 'CutOffTimeLeadTime_v2',
                    },
                    __typename: 'CutOffTimeGenericRule_v2',
                  },
                  perDay: {
                    _0: {
                      enabled: false,
                      dayOfWeekIndex: 0,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _1: {
                      enabled: false,
                      dayOfWeekIndex: 1,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _2: {
                      enabled: false,
                      dayOfWeekIndex: 2,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _3: {
                      enabled: false,
                      dayOfWeekIndex: 3,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _4: {
                      enabled: false,
                      dayOfWeekIndex: 4,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _5: {
                      enabled: false,
                      dayOfWeekIndex: 5,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _6: {
                      enabled: false,
                      dayOfWeekIndex: 6,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    __typename: 'CutOffTimePerDayList_v2',
                  },
                  __typename: 'CutOffTime_v2',
                },
                __typename: 'ProductType',
              },
              __typename: 'Variant',
              dealDetails: null,
            },
            __typename: 'CartProductV2',
          },
          {
            _id: '5f0ebf0a77689600122b39a9',
            variantId: 'Packung',
            qty: 2,
            vendorId: 'e76d48970971564e560d4aa4',
            deliveryOptionId: '6662c2a940a84daa496cda54',
            price: 1,
            baseUnit: 'L',
            unitsInPackaging: 0.5,
            productType: '__default__',
            productCopy: {
              _id: '5f0ebf0a77689600122b39a9',
              name: 'Joghurt ',
              description: '',
              vendorId: 'e76d48970971564e560d4aa4',
              vendorSpecificId: '14',
              image: '',
              imageUrl: null,
              categories: ['Milchprodukt'],
              existsInUserLists: false,
              __typename: 'Product',
            },
            variantCopy: {
              _id: '5f0ebf0a77689600122b399c',
              minOrderQuantity: 1,
              cartQuantityStep: 1,
              recommendedRetailPrice: 0,
              baseUnit: 'L',
              baseUnitMapped: {
                en: 'Liter',
                de: 'Liter',
                __typename: 'MappedTranslation',
              },
              packagingMapped: {
                en: 'Packung',
                de: 'Packung',
                __typename: 'MappedTranslation',
              },
              packaging: 'Packung',
              packagingWeight: null,
              unitsInPackaging: 0.5,
              price: 1,
              dealPrice: null,
              purchasingPrice: 0,
              dealIsActive: true,
              originalPrice: 1.29,
              discount: null,
              productType: {
                _id: '63eddd72ad4ce800132db416',
                readableId: '__default__',
                name: null,
                subTitle: null,
                description: null,
                icon: null,
                iconColor: null,
                hideAtProduct: true,
                isDefault: true,
                cutOffTime: {
                  enabled: false,
                  generic: {
                    hour: 0,
                    minute: 0,
                    leadTime: {
                      days: 0,
                      __typename: 'CutOffTimeLeadTime_v2',
                    },
                    __typename: 'CutOffTimeGenericRule_v2',
                  },
                  perDay: {
                    _0: {
                      enabled: false,
                      dayOfWeekIndex: 0,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _1: {
                      enabled: false,
                      dayOfWeekIndex: 1,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _2: {
                      enabled: false,
                      dayOfWeekIndex: 2,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _3: {
                      enabled: false,
                      dayOfWeekIndex: 3,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _4: {
                      enabled: false,
                      dayOfWeekIndex: 4,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _5: {
                      enabled: false,
                      dayOfWeekIndex: 5,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _6: {
                      enabled: false,
                      dayOfWeekIndex: 6,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    __typename: 'CutOffTimePerDayList_v2',
                  },
                  __typename: 'CutOffTime_v2',
                },
                __typename: 'ProductType',
              },
              __typename: 'Variant',
              dealDetails: null,
            },
            __typename: 'CartProductV2',
          },
          {
            _id: '5f27ddc1e8d1d500197979a5',
            variantId: 'Packung (50STK)',
            qty: 3,
            vendorId: 'e76d48970971564e560d4aa4',
            deliveryOptionId: '6662c2a940a84daa496cda54',
            price: 9,
            baseUnit: 'STK',
            unitsInPackaging: 50,
            productType: '__default__',
            productCopy: {
              _id: '5f27ddc1e8d1d500197979a5',
              name: 'Bierbecher 0,3 l',
              description: 'gemustert',
              vendorId: 'e76d48970971564e560d4aa4',
              vendorSpecificId: '61',
              image: '',
              imageUrl: null,
              categories: ['Nice Meal'],
              existsInUserLists: false,
              __typename: 'Product',
            },
            variantCopy: {
              _id: '5f4e3271caf4db41288b2c63',
              minOrderQuantity: 1,
              cartQuantityStep: 1,
              recommendedRetailPrice: 0,
              baseUnit: 'STK',
              baseUnitMapped: {
                en: 'Stück',
                de: 'Stück',
                __typename: 'MappedTranslation',
              },
              packagingMapped: null,
              packaging: 'Packung (50STK)',
              packagingWeight: null,
              unitsInPackaging: 50,
              price: 9,
              dealPrice: null,
              purchasingPrice: 0,
              dealIsActive: false,
              originalPrice: 9,
              discount: null,
              productType: {
                _id: '63eddd72ad4ce800132db416',
                readableId: '__default__',
                name: null,
                subTitle: null,
                description: null,
                icon: null,
                iconColor: null,
                hideAtProduct: true,
                isDefault: true,
                cutOffTime: {
                  enabled: false,
                  generic: {
                    hour: 0,
                    minute: 0,
                    leadTime: {
                      days: 0,
                      __typename: 'CutOffTimeLeadTime_v2',
                    },
                    __typename: 'CutOffTimeGenericRule_v2',
                  },
                  perDay: {
                    _0: {
                      enabled: false,
                      dayOfWeekIndex: 0,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _1: {
                      enabled: false,
                      dayOfWeekIndex: 1,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _2: {
                      enabled: false,
                      dayOfWeekIndex: 2,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _3: {
                      enabled: false,
                      dayOfWeekIndex: 3,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _4: {
                      enabled: false,
                      dayOfWeekIndex: 4,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _5: {
                      enabled: false,
                      dayOfWeekIndex: 5,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _6: {
                      enabled: false,
                      dayOfWeekIndex: 6,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    __typename: 'CutOffTimePerDayList_v2',
                  },
                  __typename: 'CutOffTime_v2',
                },
                __typename: 'ProductType',
              },
              __typename: 'Variant',
              dealDetails: null,
            },
            __typename: 'CartProductV2',
          },
          {
            _id: '5f27ddc1e8d1d5001979795c',
            variantId: 'Flasche (0.3L)',
            qty: 5,
            vendorId: 'e76d48970971564e560d4aa4',
            deliveryOptionId: '6662c2a940a84daa496cda54',
            price: 0.7,
            baseUnit: 'L',
            unitsInPackaging: 0.3,
            productType: '__default__',
            productCopy: {
              _id: '5f27ddc1e8d1d5001979795c',
              name: 'Maisels weisse  0,3 l',
              description:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. End of the TEXT',
              vendorId: 'e76d48970971564e560d4aa4',
              vendorSpecificId: '101',
              image:
                'https://storage.googleapis.com/chfslst-product-images/e76d48970971564e560d4aa4%2F2823330_digital-image.avif',
              imageUrl: null,
              categories: [],
              existsInUserLists: false,
              __typename: 'Product',
            },
            variantCopy: {
              _id: '5f4e3271caf4db41288b2b4c',
              minOrderQuantity: 1,
              cartQuantityStep: 1,
              recommendedRetailPrice: 0,
              baseUnit: 'L',
              baseUnitMapped: {
                en: 'Liter',
                de: 'Liter',
                __typename: 'MappedTranslation',
              },
              packagingMapped: null,
              packaging: 'Flasche (0.3L)',
              packagingWeight: null,
              unitsInPackaging: 0.3,
              price: 0.7,
              dealPrice: null,
              purchasingPrice: 0,
              dealIsActive: false,
              originalPrice: 0.7,
              discount: null,
              productType: {
                _id: '63eddd72ad4ce800132db416',
                readableId: '__default__',
                name: null,
                subTitle: null,
                description: null,
                icon: null,
                iconColor: null,
                hideAtProduct: true,
                isDefault: true,
                cutOffTime: {
                  enabled: false,
                  generic: {
                    hour: 0,
                    minute: 0,
                    leadTime: {
                      days: 0,
                      __typename: 'CutOffTimeLeadTime_v2',
                    },
                    __typename: 'CutOffTimeGenericRule_v2',
                  },
                  perDay: {
                    _0: {
                      enabled: false,
                      dayOfWeekIndex: 0,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _1: {
                      enabled: false,
                      dayOfWeekIndex: 1,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _2: {
                      enabled: false,
                      dayOfWeekIndex: 2,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _3: {
                      enabled: false,
                      dayOfWeekIndex: 3,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _4: {
                      enabled: false,
                      dayOfWeekIndex: 4,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _5: {
                      enabled: false,
                      dayOfWeekIndex: 5,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _6: {
                      enabled: false,
                      dayOfWeekIndex: 6,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    __typename: 'CutOffTimePerDayList_v2',
                  },
                  __typename: 'CutOffTime_v2',
                },
                __typename: 'ProductType',
              },
              __typename: 'Variant',
              dealDetails: null,
            },
            __typename: 'CartProductV2',
          },
          {
            _id: '5f27ddc1e8d1d5001979795f',
            variantId: 'Flasche (0.5L)',
            qty: 1,
            vendorId: 'e76d48970971564e560d4aa4',
            deliveryOptionId: '668bdbf5792008001b583877',
            price: 0.4,
            baseUnit: 'L',
            unitsInPackaging: 0.5,
            productType: '3 Days',
            productCopy: {
              _id: '5f27ddc1e8d1d5001979795f',
              name: 'Zwickl krug 0,5 l',
              description: 'einzeln',
              vendorId: 'e76d48970971564e560d4aa4',
              vendorSpecificId: '104',
              image:
                'https://storage.googleapis.com/chfslst-product-images/e76d48970971564e560d4aa4/Zwickl_krug_0%2C5_l.webp',
              imageUrl: null,
              categories: ['beer'],
              existsInUserLists: false,
              __typename: 'Product',
            },
            variantCopy: {
              _id: '5f4e3271caf4db41288b2b5b',
              minOrderQuantity: 1,
              cartQuantityStep: 1,
              recommendedRetailPrice: 0,
              baseUnit: 'L',
              baseUnitMapped: {
                en: 'Liter',
                de: 'Liter',
                __typename: 'MappedTranslation',
              },
              packagingMapped: null,
              packaging: 'Flasche (0.5L)',
              packagingWeight: null,
              unitsInPackaging: 0.5,
              price: 0.4,
              dealPrice: null,
              purchasingPrice: 0,
              dealIsActive: false,
              originalPrice: 0.4,
              discount: null,
              productType: {
                _id: '6490302b3331f9001396959b',
                readableId: '3 Days',
                name: {
                  en: null,
                  de: '3 Days delivery',
                  fr: null,
                  es: null,
                  ve: null,
                  __typename: 'Translations',
                },
                subTitle: {
                  en: null,
                  de: '3_days',
                  fr: null,
                  es: null,
                  ve: null,
                  __typename: 'Translations',
                },
                description: {
                  en: null,
                  de: '3 Days delivery from Frankfurt',
                  fr: null,
                  es: null,
                  ve: null,
                  __typename: 'Translations',
                },
                icon:
                  'https://storage.googleapis.com/chfslst-product-images/e76d48970971564e560d4aa4/productType-images/3-day-only-vector-illustration-260nw-1992650993.webp',
                iconColor: null,
                hideAtProduct: false,
                isDefault: null,
                cutOffTime: {
                  enabled: false,
                  generic: {
                    hour: 0,
                    minute: 0,
                    leadTime: {
                      days: 0,
                      __typename: 'CutOffTimeLeadTime_v2',
                    },
                    __typename: 'CutOffTimeGenericRule_v2',
                  },
                  perDay: {
                    _0: {
                      enabled: false,
                      dayOfWeekIndex: 0,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _1: {
                      enabled: false,
                      dayOfWeekIndex: 1,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _2: {
                      enabled: false,
                      dayOfWeekIndex: 2,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _3: {
                      enabled: false,
                      dayOfWeekIndex: 3,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _4: {
                      enabled: false,
                      dayOfWeekIndex: 4,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _5: {
                      enabled: false,
                      dayOfWeekIndex: 5,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _6: {
                      enabled: false,
                      dayOfWeekIndex: 6,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    __typename: 'CutOffTimePerDayList_v2',
                  },
                  __typename: 'CutOffTime_v2',
                },
                __typename: 'ProductType',
              },
              __typename: 'Variant',
              dealDetails: null,
            },
            __typename: 'CartProductV2',
          },
          {
            _id: '5f27ddc1e8d1d5001979795f',
            variantId: 'Kiste (6L)',
            qty: 3,
            vendorId: 'e76d48970971564e560d4aa4',
            deliveryOptionId: '6662c2a940a84daa496cda54',
            price: 14,
            baseUnit: 'L',
            unitsInPackaging: 6,
            productType: '__default__',
            productCopy: {
              _id: '5f27ddc1e8d1d5001979795f',
              name: 'Zwickl krug 0,5 l',
              description: 'einzeln',
              vendorId: 'e76d48970971564e560d4aa4',
              vendorSpecificId: '104',
              image:
                'https://storage.googleapis.com/chfslst-product-images/e76d48970971564e560d4aa4/Zwickl_krug_0%2C5_l.webp',
              imageUrl: null,
              categories: ['beer'],
              existsInUserLists: false,
              __typename: 'Product',
            },
            variantCopy: {
              _id: '5f4e3271caf4db41288b2b5d',
              minOrderQuantity: 1,
              cartQuantityStep: 1,
              recommendedRetailPrice: 0,
              baseUnit: 'L',
              baseUnitMapped: {
                en: 'Liter',
                de: 'Liter',
                __typename: 'MappedTranslation',
              },
              packagingMapped: null,
              packaging: 'Kiste (6L)',
              packagingWeight: null,
              unitsInPackaging: 6,
              price: 14,
              dealPrice: null,
              purchasingPrice: 0,
              dealIsActive: false,
              originalPrice: 14,
              discount: null,
              productType: {
                _id: '63eddd72ad4ce800132db416',
                readableId: '__default__',
                name: null,
                subTitle: null,
                description: null,
                icon: null,
                iconColor: null,
                hideAtProduct: true,
                isDefault: true,
                cutOffTime: {
                  enabled: false,
                  generic: {
                    hour: 0,
                    minute: 0,
                    leadTime: {
                      days: 0,
                      __typename: 'CutOffTimeLeadTime_v2',
                    },
                    __typename: 'CutOffTimeGenericRule_v2',
                  },
                  perDay: {
                    _0: {
                      enabled: false,
                      dayOfWeekIndex: 0,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _1: {
                      enabled: false,
                      dayOfWeekIndex: 1,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _2: {
                      enabled: false,
                      dayOfWeekIndex: 2,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _3: {
                      enabled: false,
                      dayOfWeekIndex: 3,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _4: {
                      enabled: false,
                      dayOfWeekIndex: 4,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _5: {
                      enabled: false,
                      dayOfWeekIndex: 5,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _6: {
                      enabled: false,
                      dayOfWeekIndex: 6,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    __typename: 'CutOffTimePerDayList_v2',
                  },
                  __typename: 'CutOffTime_v2',
                },
                __typename: 'ProductType',
              },
              __typename: 'Variant',
              dealDetails: null,
            },
            __typename: 'CartProductV2',
          },
          {
            _id: '5f27ddc1e8d1d50019797962',
            variantId: 'Flasche (0.2L)',
            qty: 5,
            vendorId: 'e76d48970971564e560d4aa4',
            deliveryOptionId: '6662c2a940a84daa496cda54',
            price: 1,
            baseUnit: 'L',
            unitsInPackaging: 0.2,
            productType: '__default__',
            productCopy: {
              _id: '5f27ddc1e8d1d50019797962',
              name: 'Weißwein 0,2 l hausmanns',
              description: 'französisch',
              vendorId: 'e76d48970971564e560d4aa4',
              vendorSpecificId: '77',
              image:
                'https://storage.googleapis.com/chfslst-product-images/e76d48970971564e560d4aa4/Wei%C3%9Fwein_0%2C2_l_hausmanns.png',
              imageUrl: null,
              categories: ['beer'],
              existsInUserLists: false,
              __typename: 'Product',
            },
            variantCopy: {
              _id: '5f4e3271caf4db41288b2b6c',
              minOrderQuantity: 1,
              cartQuantityStep: 1,
              recommendedRetailPrice: 0,
              baseUnit: 'L',
              baseUnitMapped: {
                en: 'Liter',
                de: 'Liter',
                __typename: 'MappedTranslation',
              },
              packagingMapped: null,
              packaging: 'Flasche (0.2L)',
              packagingWeight: null,
              unitsInPackaging: 0.2,
              price: 1,
              dealPrice: null,
              purchasingPrice: 0,
              dealIsActive: false,
              originalPrice: 1,
              discount: null,
              productType: {
                _id: '63eddd72ad4ce800132db416',
                readableId: '__default__',
                name: null,
                subTitle: null,
                description: null,
                icon: null,
                iconColor: null,
                hideAtProduct: true,
                isDefault: true,
                cutOffTime: {
                  enabled: false,
                  generic: {
                    hour: 0,
                    minute: 0,
                    leadTime: {
                      days: 0,
                      __typename: 'CutOffTimeLeadTime_v2',
                    },
                    __typename: 'CutOffTimeGenericRule_v2',
                  },
                  perDay: {
                    _0: {
                      enabled: false,
                      dayOfWeekIndex: 0,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _1: {
                      enabled: false,
                      dayOfWeekIndex: 1,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _2: {
                      enabled: false,
                      dayOfWeekIndex: 2,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _3: {
                      enabled: false,
                      dayOfWeekIndex: 3,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _4: {
                      enabled: false,
                      dayOfWeekIndex: 4,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _5: {
                      enabled: false,
                      dayOfWeekIndex: 5,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _6: {
                      enabled: false,
                      dayOfWeekIndex: 6,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    __typename: 'CutOffTimePerDayList_v2',
                  },
                  __typename: 'CutOffTime_v2',
                },
                __typename: 'ProductType',
              },
              __typename: 'Variant',
              dealDetails: null,
            },
            __typename: 'CartProductV2',
          },
          {
            _id: '5f27ddc1e8d1d50019797971',
            variantId: 'Packung (100STK)',
            qty: 4,
            vendorId: 'e76d48970971564e560d4aa4',
            deliveryOptionId: '668bdbff792008001b58389d',
            price: 15.99,
            baseUnit: 'STK',
            unitsInPackaging: 100,
            productType:
              '___very____long_____product___type_____on_____product____variant____and____this____is____one_____liner with____second____line',
            productCopy: {
              _id: '5f27ddc1e8d1d50019797971',
              name: 'Suppenlöffel',
              description: 'Nirosta',
              vendorId: 'e76d48970971564e560d4aa4',
              vendorSpecificId: '91',
              image: '',
              imageUrl: null,
              categories: ['Gemüse'],
              existsInUserLists: false,
              __typename: 'Product',
            },
            variantCopy: {
              _id: '5f4e3271caf4db41288b2baf',
              minOrderQuantity: 1,
              cartQuantityStep: 1,
              recommendedRetailPrice: 0,
              baseUnit: 'STK',
              baseUnitMapped: {
                en: 'Stück',
                de: 'Stück',
                __typename: 'MappedTranslation',
              },
              packagingMapped: null,
              packaging: 'Packung (100STK)',
              packagingWeight: null,
              unitsInPackaging: 100,
              price: 15.99,
              dealPrice: null,
              purchasingPrice: 0,
              dealIsActive: false,
              originalPrice: 15.99,
              discount: null,
              productType: {
                _id: '64991b5fbe14210013106eb9',
                readableId:
                  '___very____long_____product___type_____on_____product____variant____and____this____is____one_____liner with____second____line',
                name: null,
                subTitle: null,
                description: null,
                icon:
                  'https://storage.googleapis.com/chfslst-static-files/preorder%20products.png',
                iconColor: null,
                hideAtProduct: null,
                isDefault: null,
                cutOffTime: {
                  enabled: false,
                  generic: {
                    hour: 0,
                    minute: 0,
                    leadTime: {
                      days: 0,
                      __typename: 'CutOffTimeLeadTime_v2',
                    },
                    __typename: 'CutOffTimeGenericRule_v2',
                  },
                  perDay: {
                    _0: {
                      enabled: false,
                      dayOfWeekIndex: 0,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _1: {
                      enabled: false,
                      dayOfWeekIndex: 1,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _2: {
                      enabled: false,
                      dayOfWeekIndex: 2,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _3: {
                      enabled: false,
                      dayOfWeekIndex: 3,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _4: {
                      enabled: false,
                      dayOfWeekIndex: 4,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _5: {
                      enabled: false,
                      dayOfWeekIndex: 5,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _6: {
                      enabled: false,
                      dayOfWeekIndex: 6,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    __typename: 'CutOffTimePerDayList_v2',
                  },
                  __typename: 'CutOffTime_v2',
                },
                __typename: 'ProductType',
              },
              __typename: 'Variant',
              dealDetails: null,
            },
            __typename: 'CartProductV2',
          },
        ],
        deliveryOptions: TEMP_DELIVERY_OPTIONS,
        totalPrice: 370.82,
        totalItems: 10,
        totalQty: 34,
        __typename: 'CartV2',
      },
      {
        _id: '6454d93e356cf79767e08468',
        customer: {
          orderedCustomerName: 'Robert K.',
          vendorClientId: '263385',
          contactData: {
            businessName: 'Café Nomad',
            street: 'Rohrbacherstraße',
            houseNumber: '49',
            zip: '69115',
            city: 'Heidelberg',
            country: 'DE',
            __typename: 'AccountMeta',
          },
          customerId: '62ab0d53f9edc4001b86008e',
        },
        aiConfidenceLevel: 0.9,
        customerNumber: '263385',
        orderedAt: '2024-07-08T12:33:34.857Z',
        orderNumber: 88002,
        orderComment: 'Please deliver to the back door',
        status: ORDER_STATUS.PENDING,
        documents: [
          {
            _id: '6454d93e356cf79767e08469',
            type: 'pdf',
            documentNumber: 'pdf order',
            documentName: 'order for friday',
            url:
              'https://storage.googleapis.com/pdf-catalogs/ygEwhgKjPkJsBZr5d/1721396303368-Scan.pdf',
          },
          {
            _id: 'asji1o2jrl1rrmfk2oxawl',
            type: 'image',
            documentNumber: '123444',
            documentName: 'order.png',
            url:
              'https://www.researchgate.net/publication/46428085/figure/fig1/AS:277392628174848@1443146964139/Handwritten-dose-order.png',
          },
          {
            _id: 'asji1o2jrl1rrmfk2oxa241',
            type: 'doc',
            documentNumber: '123444',
            documentName: 'Kernchen order.doc',
            url:
              'https://storage.googleapis.com/pdf-catalogs/ygEwhgKjPkJsBZr5d/1721396303368-Scan.pdf',
          },
          {
            _id: 'asji1o2jrl1rrmfk2o8990',
            type: 'xls',
            documentNumber: '123449',
            documentName: 'Kernchen order 1.doc',
            url:
              'https://www.researchgate.net/publication/46428085/figure/fig1/AS:277392628174848@1443146964139/Handwritten-dose-order.png',
          },
          {
            _id: 'asji1o2jrl1rrmfk2oxa244',
            type: 'email',
            documentNumber: '123444',
            documentName: 'Neue Bestellung (#143545)',
            url:
              'https://storage.googleapis.com/pdf-catalogs/ygEwhgKjPkJsBZr5d/1721396303368-Scan.pdf',
          },
        ],
        products: [
          {
            _id: 'd6f7d19062476e4c4454d870',
            variantId: '1 kg',
            qty: 4,
            vendorId: 'e76d48970971564e560d4aa4',
            deliveryOptionId: '668bdc8c792008001b5838e7',
            price: 100,
            baseUnit: 'Packung',
            unitsInPackaging: 1,
            productType: '__default__',
            productCopy: {
              _id: 'd6f7d19062476e4c4454d870',
              name: 'Trüffel SP',
              description: '',
              vendorId: 'e76d48970971564e560d4aa4',
              vendorSpecificId: '1000',
              image:
                'https://storage.googleapis.com/chfslst-product-images/e76d48970971564e560d4aa4%2F%D0%B7%D0%B0%D0%B2%D0%B0%D0%BD%D1%82%D0%B0%D0%B6%D0%B5%D0%BD%D0%BD%D1%8F.jpeg',
              imageUrl: null,
              categories: ['selection_3'],
              existsInUserLists: false,
              __typename: 'Product',
            },
            variantCopy: {
              _id: '5eee2504c9067868e457681d',
              minOrderQuantity: 1,
              cartQuantityStep: 1,
              recommendedRetailPrice: 0,
              baseUnit: 'Packung',
              baseUnitMapped: {
                en: 'Packung',
                de: 'Packung',
                __typename: 'MappedTranslation',
              },
              packagingMapped: null,
              packaging: '1 kg',
              packagingWeight: null,
              unitsInPackaging: 1,
              price: 100,
              dealPrice: null,
              purchasingPrice: 0,
              dealIsActive: false,
              originalPrice: 100,
              discount: null,
              productType: {
                _id: '63eddd72ad4ce800132db416',
                readableId: '__default__',
                name: null,
                subTitle: null,
                description: null,
                icon: null,
                iconColor: null,
                hideAtProduct: true,
                isDefault: true,
                cutOffTime: {
                  enabled: false,
                  generic: {
                    hour: 0,
                    minute: 0,
                    leadTime: {
                      days: 0,
                      __typename: 'CutOffTimeLeadTime_v2',
                    },
                    __typename: 'CutOffTimeGenericRule_v2',
                  },
                  perDay: {
                    _0: {
                      enabled: false,
                      dayOfWeekIndex: 0,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _1: {
                      enabled: false,
                      dayOfWeekIndex: 1,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _2: {
                      enabled: false,
                      dayOfWeekIndex: 2,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _3: {
                      enabled: false,
                      dayOfWeekIndex: 3,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _4: {
                      enabled: false,
                      dayOfWeekIndex: 4,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _5: {
                      enabled: false,
                      dayOfWeekIndex: 5,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _6: {
                      enabled: false,
                      dayOfWeekIndex: 6,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    __typename: 'CutOffTimePerDayList_v2',
                  },
                  __typename: 'CutOffTime_v2',
                },
                __typename: 'ProductType',
              },
              __typename: 'Variant',
              dealDetails: null,
            },
            __typename: 'CartProductV2',
          },
          {
            _id: '63ff4ba6556bbe001380124f',
            variantId: '5 KG',
            qty: 3,
            vendorId: 'e76d48970971564e560d4aa4',
            deliveryOptionId: '668bdc8c792008001b5838e7',
            price: 480,
            baseUnit: 'Packung',
            unitsInPackaging: 5,
            productType: '__default__',
            productCopy: {
              _id: '63ff4ba6556bbe001380124f',
              name: 'Trüffel Box',
              description: '',
              vendorId: 'e76d48970971564e560d4aa4',
              vendorSpecificId: '0999',
              image:
                'https://storage.googleapis.com/chfslst-product-images/e76d48970971564e560d4aa4%2Fperigord-black-truffle-50g.jpg',
              imageUrl: null,
              categories: ['selection_3'],
              existsInUserLists: false,
              __typename: 'Product',
            },
            variantCopy: {
              _id: '9367ca94-6e4d-45e4-9211-fc242031a23f',
              minOrderQuantity: 1,
              cartQuantityStep: 1,
              recommendedRetailPrice: 0,
              baseUnit: 'Packung',
              baseUnitMapped: {
                en: 'Packung',
                de: 'Packung',
                __typename: 'MappedTranslation',
              },
              packagingMapped: null,
              packaging: '5 KG',
              packagingWeight: null,
              unitsInPackaging: 5,
              price: 480,
              dealPrice: null,
              purchasingPrice: 0,
              dealIsActive: false,
              originalPrice: 480,
              discount: null,
              productType: {
                _id: '63eddd72ad4ce800132db416',
                readableId: '__default__',
                name: null,
                subTitle: null,
                description: null,
                icon: null,
                iconColor: null,
                hideAtProduct: true,
                isDefault: true,
                cutOffTime: {
                  enabled: false,
                  generic: {
                    hour: 0,
                    minute: 0,
                    leadTime: {
                      days: 0,
                      __typename: 'CutOffTimeLeadTime_v2',
                    },
                    __typename: 'CutOffTimeGenericRule_v2',
                  },
                  perDay: {
                    _0: {
                      enabled: false,
                      dayOfWeekIndex: 0,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _1: {
                      enabled: false,
                      dayOfWeekIndex: 1,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _2: {
                      enabled: false,
                      dayOfWeekIndex: 2,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _3: {
                      enabled: false,
                      dayOfWeekIndex: 3,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _4: {
                      enabled: false,
                      dayOfWeekIndex: 4,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _5: {
                      enabled: false,
                      dayOfWeekIndex: 5,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _6: {
                      enabled: false,
                      dayOfWeekIndex: 6,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    __typename: 'CutOffTimePerDayList_v2',
                  },
                  __typename: 'CutOffTime_v2',
                },
                __typename: 'ProductType',
              },
              __typename: 'Variant',
              dealDetails: null,
            },
            __typename: 'CartProductV2',
          },
          {
            _id: '5f11a39b2a3429001253687d',
            variantId: 'Kiste (20x0,33L)',
            qty: 3,
            vendorId: 'e76d48970971564e560d4aa4',
            deliveryOptionId: '668bdc94792008001b5838fd',
            price: 16.99,
            baseUnit: 'L',
            unitsInPackaging: 6.6,
            productType: '15 Days',
            productCopy: {
              _id: '5f11a39b2a3429001253687d',
              name: 'CREW Drunken Sailor',
              description: '20x0,33 EW',
              vendorId: 'e76d48970971564e560d4aa4',
              vendorSpecificId: '1',
              image: '',
              imageUrl: null,
              categories: ['Nice Meal'],
              existsInUserLists: false,
              __typename: 'Product',
            },
            variantCopy: {
              _id: '5f4e3266caf4db41288b11ed',
              minOrderQuantity: 1,
              cartQuantityStep: 1,
              recommendedRetailPrice: 0,
              baseUnit: 'L',
              baseUnitMapped: {
                en: 'Liter',
                de: 'Liter',
                __typename: 'MappedTranslation',
              },
              packagingMapped: null,
              packaging: 'Kiste (20x0,33L)',
              packagingWeight: null,
              unitsInPackaging: 6.6,
              price: 16.99,
              dealPrice: null,
              purchasingPrice: 0,
              dealIsActive: false,
              originalPrice: 16.99,
              discount: null,
              productType: {
                _id: '64901ca23331f900139693f3',
                readableId: '15 Days',
                name: {
                  en: null,
                  de: '15 days delivery',
                  fr: null,
                  es: null,
                  ve: null,
                  __typename: 'Translations',
                },
                subTitle: {
                  en: null,
                  de: '15_Days',
                  fr: null,
                  es: null,
                  ve: null,
                  __typename: 'Translations',
                },
                description: {
                  en: null,
                  de: 'Delivery takes around 15-18 business days',
                  fr: null,
                  es: null,
                  ve: null,
                  __typename: 'Translations',
                },
                icon:
                  'https://storage.googleapis.com/chfslst-product-images/e76d48970971564e560d4aa4/productType-images/15-days-no-reason-to-return.png',
                iconColor: null,
                hideAtProduct: null,
                isDefault: null,
                cutOffTime: {
                  enabled: true,
                  generic: {
                    hour: 18,
                    minute: 0,
                    leadTime: {
                      days: 15,
                      __typename: 'CutOffTimeLeadTime_v2',
                    },
                    __typename: 'CutOffTimeGenericRule_v2',
                  },
                  perDay: {
                    _0: {
                      enabled: false,
                      dayOfWeekIndex: 0,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _1: {
                      enabled: false,
                      dayOfWeekIndex: 1,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _2: {
                      enabled: false,
                      dayOfWeekIndex: 2,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _3: {
                      enabled: false,
                      dayOfWeekIndex: 3,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _4: {
                      enabled: false,
                      dayOfWeekIndex: 4,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _5: {
                      enabled: false,
                      dayOfWeekIndex: 5,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _6: {
                      enabled: false,
                      dayOfWeekIndex: 6,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    __typename: 'CutOffTimePerDayList_v2',
                  },
                  __typename: 'CutOffTime_v2',
                },
                __typename: 'ProductType',
              },
              __typename: 'Variant',
              dealDetails: null,
            },
            __typename: 'CartProductV2',
          },
          {
            _id: '5f27ddc1e8d1d5001979795c',
            variantId: 'Flasche (0.3L)',
            qty: 2,
            vendorId: 'e76d48970971564e560d4aa4',
            deliveryOptionId: '668bdc8c792008001b5838e7',
            price: 0.7,
            baseUnit: 'L',
            unitsInPackaging: 0.3,
            productType: '__default__',
            productCopy: {
              _id: '5f27ddc1e8d1d5001979795c',
              name: 'Maisels weisse  0,3 l',
              description:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. End of the TEXT',
              vendorId: 'e76d48970971564e560d4aa4',
              vendorSpecificId: '101',
              image:
                'https://storage.googleapis.com/chfslst-product-images/e76d48970971564e560d4aa4%2F2823330_digital-image.avif',
              imageUrl: null,
              categories: [],
              existsInUserLists: false,
              __typename: 'Product',
            },
            variantCopy: {
              _id: '5f4e3271caf4db41288b2b4c',
              minOrderQuantity: 1,
              cartQuantityStep: 1,
              recommendedRetailPrice: 0,
              baseUnit: 'L',
              baseUnitMapped: {
                en: 'Liter',
                de: 'Liter',
                __typename: 'MappedTranslation',
              },
              packagingMapped: null,
              packaging: 'Flasche (0.3L)',
              packagingWeight: null,
              unitsInPackaging: 0.3,
              price: 0.7,
              dealPrice: null,
              purchasingPrice: 0,
              dealIsActive: false,
              originalPrice: 0.7,
              discount: null,
              productType: {
                _id: '63eddd72ad4ce800132db416',
                readableId: '__default__',
                name: null,
                subTitle: null,
                description: null,
                icon: null,
                iconColor: null,
                hideAtProduct: true,
                isDefault: true,
                cutOffTime: {
                  enabled: false,
                  generic: {
                    hour: 0,
                    minute: 0,
                    leadTime: {
                      days: 0,
                      __typename: 'CutOffTimeLeadTime_v2',
                    },
                    __typename: 'CutOffTimeGenericRule_v2',
                  },
                  perDay: {
                    _0: {
                      enabled: false,
                      dayOfWeekIndex: 0,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _1: {
                      enabled: false,
                      dayOfWeekIndex: 1,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _2: {
                      enabled: false,
                      dayOfWeekIndex: 2,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _3: {
                      enabled: false,
                      dayOfWeekIndex: 3,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _4: {
                      enabled: false,
                      dayOfWeekIndex: 4,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _5: {
                      enabled: false,
                      dayOfWeekIndex: 5,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _6: {
                      enabled: false,
                      dayOfWeekIndex: 6,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    __typename: 'CutOffTimePerDayList_v2',
                  },
                  __typename: 'CutOffTime_v2',
                },
                __typename: 'ProductType',
              },
              __typename: 'Variant',
              dealDetails: null,
            },
            __typename: 'CartProductV2',
          },
        ],
        deliveryOptions: TEMP_DELIVERY_OPTIONS,
        totalPrice: 1892.37,
        totalItems: 4,
        totalQty: 12,
        __typename: 'CartV2',
      },
      {
        _id: '6454d93e356cf79767e08469',
        customer: {
          orderedCustomerName: 'Bon Jameson',
          vendorClientId: '001',
          contactData: {
            businessName: 'Ladurée',
            street: 'Av. des Champs-Élysées',
            houseNumber: '75',
            zip: '75008',
            city: 'Paris',
            country: 'FR',
            __typename: 'AccountMeta',
          },
          customerId: '632841c0bdfb34001a077d9e',
        },
        aiConfidenceLevel: 0.95,
        orderedAt: '2024-07-06T18:11:00.141Z',
        orderNumber: 88003,
        orderComment: 'No sugar please',
        status: ORDER_STATUS.PROBLEM,
        products: [],
        deliveryOptions: TEMP_DELIVERY_OPTIONS,
        totalPrice: 0,
        totalItems: 0,
        totalQty: 0,
        __typename: 'CartV2',
      },
      {
        _id: '6454d93e356cf79767e084123',
        customer: {
          orderedCustomerName: 'Bon Jameson',
          vendorClientId: '001',
          contactData: {
            businessName: 'Ladurée',
            street: 'Av. des Champs-Élysées',
            houseNumber: '75',
            zip: '75008',
            city: 'Paris',
            country: 'FR',
            __typename: 'AccountMeta',
          },
          customerId: '632841c0bdfb34001a077d9e',
        },
        aiConfidenceLevel: 0.95,
        orderedAt: '2024-08-01T11:11:00.141Z',
        orderNumber: 88009,
        orderComment: 'WITH DEECIMAL QUANTITY',
        status: ORDER_STATUS.PENDING,
        documents: [
          {
            _id: 'asji1o2jrl1rrmfk2oilm',
            type: 'order pdf',
            documentNumber: '123456',
            documentName: 'order_daily.pdf',
            url:
              'https://storage.googleapis.com/pdf-catalogs/61e570cdadae96001bd87b6c/1702277424162-Platte_Valley_Sortiment.pdf',
          },
          {
            _id: 'asji1o2jrl1rrmfk2oxawl',
            type: 'image',
            documentNumber: '123444',
            documentName: 'order.png',
            url:
              'https://www.researchgate.net/publication/46428085/figure/fig1/AS:277392628174848@1443146964139/Handwritten-dose-order.png',
          },
        ],
        products: [
          {
            _id: '5f0ebf0a77689600122b39a3',
            variantId: 'Netz',
            qty: 0.1,
            vendorId: 'e76d48970971564e560d4aa4',
            deliveryOptionId: '66b0ab20a2a8c8001aa39fc4',
            price: 1,
            baseUnit: 'g',
            unitsInPackaging: 1,
            productType: '__default__',
            productCopy: {
              _id: '5f0ebf0a77689600122b39a3',
              name: 'Zwiebeln',
              description: '',
              vendorId: 'e76d48970971564e560d4aa4',
              vendorSpecificId: '05',
              image:
                'https://storage.googleapis.com/chfslst-product-images/e76d48970971564e560d4aa4/zwiebel.jpeg',
              imageUrl: null,
              categories: ['obst'],
              existsInUserLists: false,
              __typename: 'Product',
            },
            variantCopy: {
              _id: '5f0ebf0a77689600122b3996',
              minOrderQuantity: 0.2,
              cartQuantityStep: 0.2,
              recommendedRetailPrice: 0,
              baseUnit: 'g',
              baseUnitMapped: {
                en: 'Gramm',
                de: 'Gramm',
                __typename: 'MappedTranslation',
              },
              packagingMapped: {
                en: 'Netz',
                de: 'Netz',
                __typename: 'MappedTranslation',
              },
              packaging: 'Netz',
              packagingWeight: null,
              unitsInPackaging: 1,
              price: 1,
              dealPrice: null,
              purchasingPrice: 0,
              dealIsActive: false,
              originalPrice: 1,
              discount: null,
              productType: {
                _id: '63eddd72ad4ce800132db416',
                readableId: '__default__',
                name: null,
                subTitle: null,
                description: null,
                icon: null,
                iconColor: null,
                hideAtProduct: true,
                isDefault: true,
                cutOffTime: {
                  enabled: false,
                  generic: {
                    hour: 0,
                    minute: 0,
                    leadTime: {
                      days: 0,
                      __typename: 'CutOffTimeLeadTime_v2',
                    },
                    __typename: 'CutOffTimeGenericRule_v2',
                  },
                  perDay: {
                    _0: {
                      enabled: false,
                      dayOfWeekIndex: 0,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _1: {
                      enabled: false,
                      dayOfWeekIndex: 1,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _2: {
                      enabled: false,
                      dayOfWeekIndex: 2,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _3: {
                      enabled: false,
                      dayOfWeekIndex: 3,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _4: {
                      enabled: false,
                      dayOfWeekIndex: 4,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _5: {
                      enabled: false,
                      dayOfWeekIndex: 5,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _6: {
                      enabled: false,
                      dayOfWeekIndex: 6,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    __typename: 'CutOffTimePerDayList_v2',
                  },
                  __typename: 'CutOffTime_v2',
                },
                __typename: 'ProductType',
              },
              attributes: [],
              __typename: 'Variant',
              dealDetails: null,
            },
            message: null,
            attributesKey: null,
            __typename: 'CartProductV2',
          },
          {
            _id: '5f27ddc1e8d1d50019797962',
            variantId: 'Kiste (2,5L)',
            qty: 7.5,
            vendorId: 'e76d48970971564e560d4aa4',
            deliveryOptionId: '66b0ab20a2a8c8001aa39fc4',
            price: 4,
            baseUnit: 'L',
            unitsInPackaging: 2.5,
            productType: '__default__',
            productCopy: {
              _id: '5f27ddc1e8d1d50019797962',
              name: 'Weißwein 0,2 l hausmanns',
              description: 'französisch',
              vendorId: 'e76d48970971564e560d4aa4',
              vendorSpecificId: '77',
              image:
                'https://storage.googleapis.com/chfslst-product-images/e76d48970971564e560d4aa4/Wei%C3%9Fwein_0%2C2_l_hausmanns.png',
              imageUrl: null,
              categories: ['beer'],
              existsInUserLists: false,
              __typename: 'Product',
            },
            variantCopy: {
              _id: '5f4e3271caf4db41288b2b6a',
              minOrderQuantity: 5,
              cartQuantityStep: 2.5,
              recommendedRetailPrice: 0,
              baseUnit: 'L',
              baseUnitMapped: {
                en: 'Liter',
                de: 'Liter',
                __typename: 'MappedTranslation',
              },
              packagingMapped: null,
              packaging: 'Kiste (2,5L)',
              packagingWeight: null,
              unitsInPackaging: 2.5,
              price: 4,
              dealPrice: null,
              purchasingPrice: 0,
              dealIsActive: false,
              originalPrice: 4,
              discount: null,
              productType: {
                _id: '63eddd72ad4ce800132db416',
                readableId: '__default__',
                name: null,
                subTitle: null,
                description: null,
                icon: null,
                iconColor: null,
                hideAtProduct: true,
                isDefault: true,
                cutOffTime: {
                  enabled: false,
                  generic: {
                    hour: 0,
                    minute: 0,
                    leadTime: {
                      days: 0,
                      __typename: 'CutOffTimeLeadTime_v2',
                    },
                    __typename: 'CutOffTimeGenericRule_v2',
                  },
                  perDay: {
                    _0: {
                      enabled: false,
                      dayOfWeekIndex: 0,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _1: {
                      enabled: false,
                      dayOfWeekIndex: 1,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _2: {
                      enabled: false,
                      dayOfWeekIndex: 2,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _3: {
                      enabled: false,
                      dayOfWeekIndex: 3,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _4: {
                      enabled: false,
                      dayOfWeekIndex: 4,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _5: {
                      enabled: false,
                      dayOfWeekIndex: 5,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _6: {
                      enabled: false,
                      dayOfWeekIndex: 6,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    __typename: 'CutOffTimePerDayList_v2',
                  },
                  __typename: 'CutOffTime_v2',
                },
                __typename: 'ProductType',
              },
              attributes: [],
              __typename: 'Variant',
              dealDetails: null,
            },
            message: null,
            attributesKey: null,
            __typename: 'CartProductV2',
          },
          {
            _id: '5f27ddc1e8d1d50019797962',
            variantId: 'Flasche (0.25L)',
            qty: 2.5,
            vendorId: 'e76d48970971564e560d4aa4',
            deliveryOptionId: '66b0ab20a2a8c8001aa39fc4',
            price: 1,
            baseUnit: 'L',
            unitsInPackaging: 0.25,
            productType: '__default__',
            productCopy: {
              _id: '5f27ddc1e8d1d50019797962',
              name: 'Weißwein 0,2 l hausmanns',
              description: 'französisch',
              vendorId: 'e76d48970971564e560d4aa4',
              vendorSpecificId: '77',
              image:
                'https://storage.googleapis.com/chfslst-product-images/e76d48970971564e560d4aa4/Wei%C3%9Fwein_0%2C2_l_hausmanns.png',
              imageUrl: null,
              categories: ['beer'],
              existsInUserLists: false,
              __typename: 'Product',
            },
            variantCopy: {
              _id: '5f4e3271caf4db41288b2b6c',
              minOrderQuantity: 0.5,
              cartQuantityStep: 0.5,
              recommendedRetailPrice: 0,
              baseUnit: 'L',
              baseUnitMapped: {
                en: 'Liter',
                de: 'Liter',
                __typename: 'MappedTranslation',
              },
              packagingMapped: null,
              packaging: 'Flasche (0.25L)',
              packagingWeight: null,
              unitsInPackaging: 0.25,
              price: 1,
              dealPrice: null,
              purchasingPrice: 0,
              dealIsActive: false,
              originalPrice: 1,
              discount: null,
              productType: {
                _id: '63eddd72ad4ce800132db416',
                readableId: '__default__',
                name: null,
                subTitle: null,
                description: null,
                icon: null,
                iconColor: null,
                hideAtProduct: true,
                isDefault: true,
                cutOffTime: {
                  enabled: false,
                  generic: {
                    hour: 0,
                    minute: 0,
                    leadTime: {
                      days: 0,
                      __typename: 'CutOffTimeLeadTime_v2',
                    },
                    __typename: 'CutOffTimeGenericRule_v2',
                  },
                  perDay: {
                    _0: {
                      enabled: false,
                      dayOfWeekIndex: 0,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _1: {
                      enabled: false,
                      dayOfWeekIndex: 1,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _2: {
                      enabled: false,
                      dayOfWeekIndex: 2,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _3: {
                      enabled: false,
                      dayOfWeekIndex: 3,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _4: {
                      enabled: false,
                      dayOfWeekIndex: 4,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _5: {
                      enabled: false,
                      dayOfWeekIndex: 5,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _6: {
                      enabled: false,
                      dayOfWeekIndex: 6,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    __typename: 'CutOffTimePerDayList_v2',
                  },
                  __typename: 'CutOffTime_v2',
                },
                __typename: 'ProductType',
              },
              attributes: [],
              __typename: 'Variant',
              dealDetails: null,
            },
            message: null,
            attributesKey: null,
            __typename: 'CartProductV2',
          },
          {
            _id: '63ff4ba6556bbe001380124f',
            variantId: '5 KG',
            qty: 3,
            vendorId: 'e76d48970971564e560d4aa4',
            deliveryOptionId: '668bdc8c792008001b5838e7',
            price: 480,
            baseUnit: 'Packung',
            unitsInPackaging: 5,
            productType: '__default__',
            productCopy: {
              _id: '63ff4ba6556bbe001380124f',
              name: 'Trüffel Box',
              description: '',
              vendorId: 'e76d48970971564e560d4aa4',
              vendorSpecificId: '0999',
              image:
                'https://storage.googleapis.com/chfslst-product-images/e76d48970971564e560d4aa4%2Fperigord-black-truffle-50g.jpg',
              imageUrl: null,
              categories: ['selection_3'],
              existsInUserLists: false,
              __typename: 'Product',
            },
            variantCopy: {
              _id: '9367ca94-6e4d-45e4-9211-fc242031a23f',
              minOrderQuantity: 1,
              cartQuantityStep: 1,
              recommendedRetailPrice: 0,
              baseUnit: 'Packung',
              baseUnitMapped: {
                en: 'Packung',
                de: 'Packung',
                __typename: 'MappedTranslation',
              },
              packagingMapped: null,
              packaging: '5 KG',
              packagingWeight: null,
              unitsInPackaging: 5,
              price: 480,
              dealPrice: null,
              purchasingPrice: 0,
              dealIsActive: false,
              originalPrice: 480,
              discount: null,
              productType: {
                _id: '63eddd72ad4ce800132db416',
                readableId: '__default__',
                name: null,
                subTitle: null,
                description: null,
                icon: null,
                iconColor: null,
                hideAtProduct: true,
                isDefault: true,
                cutOffTime: {
                  enabled: false,
                  generic: {
                    hour: 0,
                    minute: 0,
                    leadTime: {
                      days: 0,
                      __typename: 'CutOffTimeLeadTime_v2',
                    },
                    __typename: 'CutOffTimeGenericRule_v2',
                  },
                  perDay: {
                    _0: {
                      enabled: false,
                      dayOfWeekIndex: 0,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _1: {
                      enabled: false,
                      dayOfWeekIndex: 1,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _2: {
                      enabled: false,
                      dayOfWeekIndex: 2,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _3: {
                      enabled: false,
                      dayOfWeekIndex: 3,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _4: {
                      enabled: false,
                      dayOfWeekIndex: 4,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _5: {
                      enabled: false,
                      dayOfWeekIndex: 5,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _6: {
                      enabled: false,
                      dayOfWeekIndex: 6,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    __typename: 'CutOffTimePerDayList_v2',
                  },
                  __typename: 'CutOffTime_v2',
                },
                __typename: 'ProductType',
              },
              __typename: 'Variant',
              dealDetails: null,
            },
            __typename: 'CartProductV2',
          },
        ],
        deliveryOptions: TEMP_DELIVERY_OPTIONS,
        totalPrice: 1342.4,
        totalItems: 4,
        totalQty: 15.1,
        __typename: 'CartV2',
      },
      {
        _id: '6454d93e356cf79767e08410',
        aiConfidenceLevel: 0.9,
        customer: {
          orderedCustomerName: 'Robert Karate Kid',
          vendorClientId: '263385',
          contactData: {
            businessName: 'Café Nomad',
            street: 'Rohrbacherstraße',
            houseNumber: '49',
            zip: '69115',
            city: 'Heidelberg',
            country: 'DE',
            __typename: 'AccountMeta',
          },
          customerId: '62ab0d53f9edc4001b86008e',
        },
        orderedAt: '2024-07-08T12:33:34.857Z',
        orderNumber: 88004,
        orderComment: 'Oraganic products only',
        status: ORDER_STATUS.CANCELED,
        products: [
          {
            _id: '63ff4ba6556bbe001380124f',
            variantId: '5 KG',
            qty: 3,
            vendorId: 'e76d48970971564e560d4aa4',
            deliveryOptionId: '668bdc8c792008001b5838e7',
            price: 480,
            baseUnit: 'Packung',
            unitsInPackaging: 5,
            productType: '__default__',
            productCopy: {
              _id: '63ff4ba6556bbe001380124f',
              name: 'Trüffel Box',
              description: '',
              vendorId: 'e76d48970971564e560d4aa4',
              vendorSpecificId: '0999',
              image:
                'https://storage.googleapis.com/chfslst-product-images/e76d48970971564e560d4aa4%2Fperigord-black-truffle-50g.jpg',
              imageUrl: null,
              categories: ['selection_3'],
              existsInUserLists: false,
              __typename: 'Product',
            },
            variantCopy: {
              _id: '9367ca94-6e4d-45e4-9211-fc242031a23f',
              minOrderQuantity: 1,
              cartQuantityStep: 1,
              recommendedRetailPrice: 0,
              baseUnit: 'Packung',
              baseUnitMapped: {
                en: 'Packung',
                de: 'Packung',
                __typename: 'MappedTranslation',
              },
              packagingMapped: null,
              packaging: '5 KG',
              packagingWeight: null,
              unitsInPackaging: 5,
              price: 480,
              dealPrice: null,
              purchasingPrice: 0,
              dealIsActive: false,
              originalPrice: 480,
              discount: null,
              productType: {
                _id: '63eddd72ad4ce800132db416',
                readableId: '__default__',
                name: null,
                subTitle: null,
                description: null,
                icon: null,
                iconColor: null,
                hideAtProduct: true,
                isDefault: true,
                cutOffTime: {
                  enabled: false,
                  generic: {
                    hour: 0,
                    minute: 0,
                    leadTime: {
                      days: 0,
                      __typename: 'CutOffTimeLeadTime_v2',
                    },
                    __typename: 'CutOffTimeGenericRule_v2',
                  },
                  perDay: {
                    _0: {
                      enabled: false,
                      dayOfWeekIndex: 0,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _1: {
                      enabled: false,
                      dayOfWeekIndex: 1,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _2: {
                      enabled: false,
                      dayOfWeekIndex: 2,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _3: {
                      enabled: false,
                      dayOfWeekIndex: 3,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _4: {
                      enabled: false,
                      dayOfWeekIndex: 4,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _5: {
                      enabled: false,
                      dayOfWeekIndex: 5,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _6: {
                      enabled: false,
                      dayOfWeekIndex: 6,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    __typename: 'CutOffTimePerDayList_v2',
                  },
                  __typename: 'CutOffTime_v2',
                },
                __typename: 'ProductType',
              },
              __typename: 'Variant',
              dealDetails: null,
            },
            __typename: 'CartProductV2',
          },
          {
            _id: '63ff4ba6556bbe001380124f',
            variantId: '5 KG',
            qty: 3,
            vendorId: 'e76d48970971564e560d4aa4',
            deliveryOptionId: '668bdc8c792008001b5838e7',
            price: 480,
            baseUnit: 'Packung',
            unitsInPackaging: 5,
            productType: '__default__',
            productCopy: {
              _id: '63ff4ba6556bbe001380124f',
              name: 'Trüffel Box',
              description: '',
              vendorId: 'e76d48970971564e560d4aa4',
              vendorSpecificId: '0999',
              image:
                'https://storage.googleapis.com/chfslst-product-images/e76d48970971564e560d4aa4%2Fperigord-black-truffle-50g.jpg',
              imageUrl: null,
              categories: ['selection_3'],
              existsInUserLists: false,
              __typename: 'Product',
            },
            variantCopy: {
              _id: '9367ca94-6e4d-45e4-9211-fc242031a23f',
              minOrderQuantity: 1,
              cartQuantityStep: 1,
              recommendedRetailPrice: 0,
              baseUnit: 'Packung',
              baseUnitMapped: {
                en: 'Packung',
                de: 'Packung',
                __typename: 'MappedTranslation',
              },
              packagingMapped: null,
              packaging: '5 KG',
              packagingWeight: null,
              unitsInPackaging: 5,
              price: 480,
              dealPrice: null,
              purchasingPrice: 0,
              dealIsActive: false,
              originalPrice: 480,
              discount: null,
              productType: {
                _id: '63eddd72ad4ce800132db416',
                readableId: '__default__',
                name: null,
                subTitle: null,
                description: null,
                icon: null,
                iconColor: null,
                hideAtProduct: true,
                isDefault: true,
                cutOffTime: {
                  enabled: false,
                  generic: {
                    hour: 0,
                    minute: 0,
                    leadTime: {
                      days: 0,
                      __typename: 'CutOffTimeLeadTime_v2',
                    },
                    __typename: 'CutOffTimeGenericRule_v2',
                  },
                  perDay: {
                    _0: {
                      enabled: false,
                      dayOfWeekIndex: 0,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _1: {
                      enabled: false,
                      dayOfWeekIndex: 1,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _2: {
                      enabled: false,
                      dayOfWeekIndex: 2,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _3: {
                      enabled: false,
                      dayOfWeekIndex: 3,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _4: {
                      enabled: false,
                      dayOfWeekIndex: 4,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _5: {
                      enabled: false,
                      dayOfWeekIndex: 5,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _6: {
                      enabled: false,
                      dayOfWeekIndex: 6,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    __typename: 'CutOffTimePerDayList_v2',
                  },
                  __typename: 'CutOffTime_v2',
                },
                __typename: 'ProductType',
              },
              __typename: 'Variant',
              dealDetails: null,
            },
            __typename: 'CartProductV2',
          },
          {
            _id: '63ff4ba6556bbe001380124f',
            variantId: '5 KG',
            qty: 3,
            vendorId: 'e76d48970971564e560d4aa4',
            deliveryOptionId: '668bdc8c792008001b5838e7',
            price: 480,
            baseUnit: 'Packung',
            unitsInPackaging: 5,
            productType: '__default__',
            productCopy: {
              _id: '63ff4ba6556bbe001380124f',
              name: 'Trüffel Box',
              description: '',
              vendorId: 'e76d48970971564e560d4aa4',
              vendorSpecificId: '0999',
              image:
                'https://storage.googleapis.com/chfslst-product-images/e76d48970971564e560d4aa4%2Fperigord-black-truffle-50g.jpg',
              imageUrl: null,
              categories: ['selection_3'],
              existsInUserLists: false,
              __typename: 'Product',
            },
            variantCopy: {
              _id: '9367ca94-6e4d-45e4-9211-fc242031a23f',
              minOrderQuantity: 1,
              cartQuantityStep: 1,
              recommendedRetailPrice: 0,
              baseUnit: 'Packung',
              baseUnitMapped: {
                en: 'Packung',
                de: 'Packung',
                __typename: 'MappedTranslation',
              },
              packagingMapped: null,
              packaging: '5 KG',
              packagingWeight: null,
              unitsInPackaging: 5,
              price: 480,
              dealPrice: null,
              purchasingPrice: 0,
              dealIsActive: false,
              originalPrice: 480,
              discount: null,
              productType: {
                _id: '63eddd72ad4ce800132db416',
                readableId: '__default__',
                name: null,
                subTitle: null,
                description: null,
                icon: null,
                iconColor: null,
                hideAtProduct: true,
                isDefault: true,
                cutOffTime: {
                  enabled: false,
                  generic: {
                    hour: 0,
                    minute: 0,
                    leadTime: {
                      days: 0,
                      __typename: 'CutOffTimeLeadTime_v2',
                    },
                    __typename: 'CutOffTimeGenericRule_v2',
                  },
                  perDay: {
                    _0: {
                      enabled: false,
                      dayOfWeekIndex: 0,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _1: {
                      enabled: false,
                      dayOfWeekIndex: 1,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _2: {
                      enabled: false,
                      dayOfWeekIndex: 2,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _3: {
                      enabled: false,
                      dayOfWeekIndex: 3,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _4: {
                      enabled: false,
                      dayOfWeekIndex: 4,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _5: {
                      enabled: false,
                      dayOfWeekIndex: 5,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _6: {
                      enabled: false,
                      dayOfWeekIndex: 6,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    __typename: 'CutOffTimePerDayList_v2',
                  },
                  __typename: 'CutOffTime_v2',
                },
                __typename: 'ProductType',
              },
              __typename: 'Variant',
              dealDetails: null,
            },
            __typename: 'CartProductV2',
          },
          {
            _id: '63ff4ba6556bbe001380124f',
            variantId: '5 KG',
            qty: 3,
            vendorId: 'e76d48970971564e560d4aa4',
            deliveryOptionId: '668bdc8c792008001b5838e7',
            price: 480,
            baseUnit: 'Packung',
            unitsInPackaging: 5,
            productType: '__default__',
            productCopy: {
              _id: '63ff4ba6556bbe001380124f',
              name: 'Trüffel Box',
              description: '',
              vendorId: 'e76d48970971564e560d4aa4',
              vendorSpecificId: '0999',
              image:
                'https://storage.googleapis.com/chfslst-product-images/e76d48970971564e560d4aa4%2Fperigord-black-truffle-50g.jpg',
              imageUrl: null,
              categories: ['selection_3'],
              existsInUserLists: false,
              __typename: 'Product',
            },
            variantCopy: {
              _id: '9367ca94-6e4d-45e4-9211-fc242031a23f',
              minOrderQuantity: 1,
              cartQuantityStep: 1,
              recommendedRetailPrice: 0,
              baseUnit: 'Packung',
              baseUnitMapped: {
                en: 'Packung',
                de: 'Packung',
                __typename: 'MappedTranslation',
              },
              packagingMapped: null,
              packaging: '5 KG',
              packagingWeight: null,
              unitsInPackaging: 5,
              price: 480,
              dealPrice: null,
              purchasingPrice: 0,
              dealIsActive: false,
              originalPrice: 480,
              discount: null,
              productType: {
                _id: '63eddd72ad4ce800132db416',
                readableId: '__default__',
                name: null,
                subTitle: null,
                description: null,
                icon: null,
                iconColor: null,
                hideAtProduct: true,
                isDefault: true,
                cutOffTime: {
                  enabled: false,
                  generic: {
                    hour: 0,
                    minute: 0,
                    leadTime: {
                      days: 0,
                      __typename: 'CutOffTimeLeadTime_v2',
                    },
                    __typename: 'CutOffTimeGenericRule_v2',
                  },
                  perDay: {
                    _0: {
                      enabled: false,
                      dayOfWeekIndex: 0,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _1: {
                      enabled: false,
                      dayOfWeekIndex: 1,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _2: {
                      enabled: false,
                      dayOfWeekIndex: 2,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _3: {
                      enabled: false,
                      dayOfWeekIndex: 3,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _4: {
                      enabled: false,
                      dayOfWeekIndex: 4,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _5: {
                      enabled: false,
                      dayOfWeekIndex: 5,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _6: {
                      enabled: false,
                      dayOfWeekIndex: 6,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    __typename: 'CutOffTimePerDayList_v2',
                  },
                  __typename: 'CutOffTime_v2',
                },
                __typename: 'ProductType',
              },
              __typename: 'Variant',
              dealDetails: null,
            },
            __typename: 'CartProductV2',
          },
          {
            _id: '63ff4ba6556bbe001380124f',
            variantId: '5 KG',
            qty: 3,
            vendorId: 'e76d48970971564e560d4aa4',
            deliveryOptionId: '668bdc8c792008001b5838e7',
            price: 480,
            baseUnit: 'Packung',
            unitsInPackaging: 5,
            productType: '__default__',
            productCopy: {
              _id: '63ff4ba6556bbe001380124f',
              name: 'Trüffel Box',
              description: '',
              vendorId: 'e76d48970971564e560d4aa4',
              vendorSpecificId: '0999',
              image:
                'https://storage.googleapis.com/chfslst-product-images/e76d48970971564e560d4aa4%2Fperigord-black-truffle-50g.jpg',
              imageUrl: null,
              categories: ['selection_3'],
              existsInUserLists: false,
              __typename: 'Product',
            },
            variantCopy: {
              _id: '9367ca94-6e4d-45e4-9211-fc242031a23f',
              minOrderQuantity: 1,
              cartQuantityStep: 1,
              recommendedRetailPrice: 0,
              baseUnit: 'Packung',
              baseUnitMapped: {
                en: 'Packung',
                de: 'Packung',
                __typename: 'MappedTranslation',
              },
              packagingMapped: null,
              packaging: '5 KG',
              packagingWeight: null,
              unitsInPackaging: 5,
              price: 480,
              dealPrice: null,
              purchasingPrice: 0,
              dealIsActive: false,
              originalPrice: 480,
              discount: null,
              productType: {
                _id: '63eddd72ad4ce800132db416',
                readableId: '__default__',
                name: null,
                subTitle: null,
                description: null,
                icon: null,
                iconColor: null,
                hideAtProduct: true,
                isDefault: true,
                cutOffTime: {
                  enabled: false,
                  generic: {
                    hour: 0,
                    minute: 0,
                    leadTime: {
                      days: 0,
                      __typename: 'CutOffTimeLeadTime_v2',
                    },
                    __typename: 'CutOffTimeGenericRule_v2',
                  },
                  perDay: {
                    _0: {
                      enabled: false,
                      dayOfWeekIndex: 0,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _1: {
                      enabled: false,
                      dayOfWeekIndex: 1,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _2: {
                      enabled: false,
                      dayOfWeekIndex: 2,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _3: {
                      enabled: false,
                      dayOfWeekIndex: 3,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _4: {
                      enabled: false,
                      dayOfWeekIndex: 4,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _5: {
                      enabled: false,
                      dayOfWeekIndex: 5,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _6: {
                      enabled: false,
                      dayOfWeekIndex: 6,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    __typename: 'CutOffTimePerDayList_v2',
                  },
                  __typename: 'CutOffTime_v2',
                },
                __typename: 'ProductType',
              },
              __typename: 'Variant',
              dealDetails: null,
            },
            __typename: 'CartProductV2',
          },
          {
            _id: '63ff4ba6556bbe001380124f',
            variantId: '5 KG',
            qty: 3,
            vendorId: 'e76d48970971564e560d4aa4',
            deliveryOptionId: '668bdc8c792008001b5838e7',
            price: 480,
            baseUnit: 'Packung',
            unitsInPackaging: 5,
            productType: '__default__',
            productCopy: {
              _id: '63ff4ba6556bbe001380124f',
              name: 'Trüffel Box',
              description: '',
              vendorId: 'e76d48970971564e560d4aa4',
              vendorSpecificId: '0999',
              image:
                'https://storage.googleapis.com/chfslst-product-images/e76d48970971564e560d4aa4%2Fperigord-black-truffle-50g.jpg',
              imageUrl: null,
              categories: ['selection_3'],
              existsInUserLists: false,
              __typename: 'Product',
            },
            variantCopy: {
              _id: '9367ca94-6e4d-45e4-9211-fc242031a23f',
              minOrderQuantity: 1,
              cartQuantityStep: 1,
              recommendedRetailPrice: 0,
              baseUnit: 'Packung',
              baseUnitMapped: {
                en: 'Packung',
                de: 'Packung',
                __typename: 'MappedTranslation',
              },
              packagingMapped: null,
              packaging: '5 KG',
              packagingWeight: null,
              unitsInPackaging: 5,
              price: 480,
              dealPrice: null,
              purchasingPrice: 0,
              dealIsActive: false,
              originalPrice: 480,
              discount: null,
              productType: {
                _id: '63eddd72ad4ce800132db416',
                readableId: '__default__',
                name: null,
                subTitle: null,
                description: null,
                icon: null,
                iconColor: null,
                hideAtProduct: true,
                isDefault: true,
                cutOffTime: {
                  enabled: false,
                  generic: {
                    hour: 0,
                    minute: 0,
                    leadTime: {
                      days: 0,
                      __typename: 'CutOffTimeLeadTime_v2',
                    },
                    __typename: 'CutOffTimeGenericRule_v2',
                  },
                  perDay: {
                    _0: {
                      enabled: false,
                      dayOfWeekIndex: 0,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _1: {
                      enabled: false,
                      dayOfWeekIndex: 1,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _2: {
                      enabled: false,
                      dayOfWeekIndex: 2,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _3: {
                      enabled: false,
                      dayOfWeekIndex: 3,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _4: {
                      enabled: false,
                      dayOfWeekIndex: 4,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _5: {
                      enabled: false,
                      dayOfWeekIndex: 5,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _6: {
                      enabled: false,
                      dayOfWeekIndex: 6,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    __typename: 'CutOffTimePerDayList_v2',
                  },
                  __typename: 'CutOffTime_v2',
                },
                __typename: 'ProductType',
              },
              __typename: 'Variant',
              dealDetails: null,
            },
            __typename: 'CartProductV2',
          },
          {
            _id: '63ff4ba6556bbe001380124f',
            variantId: '5 KG',
            qty: 3,
            vendorId: 'e76d48970971564e560d4aa4',
            deliveryOptionId: '668bdc8c792008001b5838e7',
            price: 480,
            baseUnit: 'Packung',
            unitsInPackaging: 5,
            productType: '__default__',
            productCopy: {
              _id: '63ff4ba6556bbe001380124f',
              name: 'Trüffel Box',
              description: '',
              vendorId: 'e76d48970971564e560d4aa4',
              vendorSpecificId: '0999',
              image:
                'https://storage.googleapis.com/chfslst-product-images/e76d48970971564e560d4aa4%2Fperigord-black-truffle-50g.jpg',
              imageUrl: null,
              categories: ['selection_3'],
              existsInUserLists: false,
              __typename: 'Product',
            },
            variantCopy: {
              _id: '9367ca94-6e4d-45e4-9211-fc242031a23f',
              minOrderQuantity: 1,
              cartQuantityStep: 1,
              recommendedRetailPrice: 0,
              baseUnit: 'Packung',
              baseUnitMapped: {
                en: 'Packung',
                de: 'Packung',
                __typename: 'MappedTranslation',
              },
              packagingMapped: null,
              packaging: '5 KG',
              packagingWeight: null,
              unitsInPackaging: 5,
              price: 480,
              dealPrice: null,
              purchasingPrice: 0,
              dealIsActive: false,
              originalPrice: 480,
              discount: null,
              productType: {
                _id: '63eddd72ad4ce800132db416',
                readableId: '__default__',
                name: null,
                subTitle: null,
                description: null,
                icon: null,
                iconColor: null,
                hideAtProduct: true,
                isDefault: true,
                cutOffTime: {
                  enabled: false,
                  generic: {
                    hour: 0,
                    minute: 0,
                    leadTime: {
                      days: 0,
                      __typename: 'CutOffTimeLeadTime_v2',
                    },
                    __typename: 'CutOffTimeGenericRule_v2',
                  },
                  perDay: {
                    _0: {
                      enabled: false,
                      dayOfWeekIndex: 0,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _1: {
                      enabled: false,
                      dayOfWeekIndex: 1,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _2: {
                      enabled: false,
                      dayOfWeekIndex: 2,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _3: {
                      enabled: false,
                      dayOfWeekIndex: 3,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _4: {
                      enabled: false,
                      dayOfWeekIndex: 4,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _5: {
                      enabled: false,
                      dayOfWeekIndex: 5,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _6: {
                      enabled: false,
                      dayOfWeekIndex: 6,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    __typename: 'CutOffTimePerDayList_v2',
                  },
                  __typename: 'CutOffTime_v2',
                },
                __typename: 'ProductType',
              },
              __typename: 'Variant',
              dealDetails: null,
            },
            __typename: 'CartProductV2',
          },
          {
            _id: '63ff4ba6556bbe001380124f',
            variantId: '5 KG',
            qty: 3,
            vendorId: 'e76d48970971564e560d4aa4',
            deliveryOptionId: '668bdc8c792008001b5838e7',
            price: 480,
            baseUnit: 'Packung',
            unitsInPackaging: 5,
            productType: '__default__',
            productCopy: {
              _id: '63ff4ba6556bbe001380124f',
              name: 'Trüffel Box',
              description: '',
              vendorId: 'e76d48970971564e560d4aa4',
              vendorSpecificId: '0999',
              image:
                'https://storage.googleapis.com/chfslst-product-images/e76d48970971564e560d4aa4%2Fperigord-black-truffle-50g.jpg',
              imageUrl: null,
              categories: ['selection_3'],
              existsInUserLists: false,
              __typename: 'Product',
            },
            variantCopy: {
              _id: '9367ca94-6e4d-45e4-9211-fc242031a23f',
              minOrderQuantity: 1,
              cartQuantityStep: 1,
              recommendedRetailPrice: 0,
              baseUnit: 'Packung',
              baseUnitMapped: {
                en: 'Packung',
                de: 'Packung',
                __typename: 'MappedTranslation',
              },
              packagingMapped: null,
              packaging: '5 KG',
              packagingWeight: null,
              unitsInPackaging: 5,
              price: 480,
              dealPrice: null,
              purchasingPrice: 0,
              dealIsActive: false,
              originalPrice: 480,
              discount: null,
              productType: {
                _id: '63eddd72ad4ce800132db416',
                readableId: '__default__',
                name: null,
                subTitle: null,
                description: null,
                icon: null,
                iconColor: null,
                hideAtProduct: true,
                isDefault: true,
                cutOffTime: {
                  enabled: false,
                  generic: {
                    hour: 0,
                    minute: 0,
                    leadTime: {
                      days: 0,
                      __typename: 'CutOffTimeLeadTime_v2',
                    },
                    __typename: 'CutOffTimeGenericRule_v2',
                  },
                  perDay: {
                    _0: {
                      enabled: false,
                      dayOfWeekIndex: 0,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _1: {
                      enabled: false,
                      dayOfWeekIndex: 1,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _2: {
                      enabled: false,
                      dayOfWeekIndex: 2,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _3: {
                      enabled: false,
                      dayOfWeekIndex: 3,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _4: {
                      enabled: false,
                      dayOfWeekIndex: 4,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _5: {
                      enabled: false,
                      dayOfWeekIndex: 5,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _6: {
                      enabled: false,
                      dayOfWeekIndex: 6,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    __typename: 'CutOffTimePerDayList_v2',
                  },
                  __typename: 'CutOffTime_v2',
                },
                __typename: 'ProductType',
              },
              __typename: 'Variant',
              dealDetails: null,
            },
            __typename: 'CartProductV2',
          },
          {
            _id: '63ff4ba6556bbe001380124f',
            variantId: '5 KG',
            qty: 3,
            vendorId: 'e76d48970971564e560d4aa4',
            deliveryOptionId: '668bdc8c792008001b5838e7',
            price: 480,
            baseUnit: 'Packung',
            unitsInPackaging: 5,
            productType: '__default__',
            productCopy: {
              _id: '63ff4ba6556bbe001380124f',
              name: 'Trüffel Box',
              description: '',
              vendorId: 'e76d48970971564e560d4aa4',
              vendorSpecificId: '0999',
              image:
                'https://storage.googleapis.com/chfslst-product-images/e76d48970971564e560d4aa4%2Fperigord-black-truffle-50g.jpg',
              imageUrl: null,
              categories: ['selection_3'],
              existsInUserLists: false,
              __typename: 'Product',
            },
            variantCopy: {
              _id: '9367ca94-6e4d-45e4-9211-fc242031a23f',
              minOrderQuantity: 1,
              cartQuantityStep: 1,
              recommendedRetailPrice: 0,
              baseUnit: 'Packung',
              baseUnitMapped: {
                en: 'Packung',
                de: 'Packung',
                __typename: 'MappedTranslation',
              },
              packagingMapped: null,
              packaging: '5 KG',
              packagingWeight: null,
              unitsInPackaging: 5,
              price: 480,
              dealPrice: null,
              purchasingPrice: 0,
              dealIsActive: false,
              originalPrice: 480,
              discount: null,
              productType: {
                _id: '63eddd72ad4ce800132db416',
                readableId: '__default__',
                name: null,
                subTitle: null,
                description: null,
                icon: null,
                iconColor: null,
                hideAtProduct: true,
                isDefault: true,
                cutOffTime: {
                  enabled: false,
                  generic: {
                    hour: 0,
                    minute: 0,
                    leadTime: {
                      days: 0,
                      __typename: 'CutOffTimeLeadTime_v2',
                    },
                    __typename: 'CutOffTimeGenericRule_v2',
                  },
                  perDay: {
                    _0: {
                      enabled: false,
                      dayOfWeekIndex: 0,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _1: {
                      enabled: false,
                      dayOfWeekIndex: 1,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _2: {
                      enabled: false,
                      dayOfWeekIndex: 2,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _3: {
                      enabled: false,
                      dayOfWeekIndex: 3,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _4: {
                      enabled: false,
                      dayOfWeekIndex: 4,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _5: {
                      enabled: false,
                      dayOfWeekIndex: 5,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _6: {
                      enabled: false,
                      dayOfWeekIndex: 6,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    __typename: 'CutOffTimePerDayList_v2',
                  },
                  __typename: 'CutOffTime_v2',
                },
                __typename: 'ProductType',
              },
              __typename: 'Variant',
              dealDetails: null,
            },
            __typename: 'CartProductV2',
          },
          {
            _id: '63ff4ba6556bbe001380124f',
            variantId: '5 KG',
            qty: 3,
            vendorId: 'e76d48970971564e560d4aa4',
            deliveryOptionId: '668bdc8c792008001b5838e7',
            price: 480,
            baseUnit: 'Packung',
            unitsInPackaging: 5,
            productType: '__default__',
            productCopy: {
              _id: '63ff4ba6556bbe001380124f',
              name: 'Trüffel Box',
              description: '',
              vendorId: 'e76d48970971564e560d4aa4',
              vendorSpecificId: '0999',
              image:
                'https://storage.googleapis.com/chfslst-product-images/e76d48970971564e560d4aa4%2Fperigord-black-truffle-50g.jpg',
              imageUrl: null,
              categories: ['selection_3'],
              existsInUserLists: false,
              __typename: 'Product',
            },
            variantCopy: {
              _id: '9367ca94-6e4d-45e4-9211-fc242031a23f',
              minOrderQuantity: 1,
              cartQuantityStep: 1,
              recommendedRetailPrice: 0,
              baseUnit: 'Packung',
              baseUnitMapped: {
                en: 'Packung',
                de: 'Packung',
                __typename: 'MappedTranslation',
              },
              packagingMapped: null,
              packaging: '5 KG',
              packagingWeight: null,
              unitsInPackaging: 5,
              price: 480,
              dealPrice: null,
              purchasingPrice: 0,
              dealIsActive: false,
              originalPrice: 480,
              discount: null,
              productType: {
                _id: '63eddd72ad4ce800132db416',
                readableId: '__default__',
                name: null,
                subTitle: null,
                description: null,
                icon: null,
                iconColor: null,
                hideAtProduct: true,
                isDefault: true,
                cutOffTime: {
                  enabled: false,
                  generic: {
                    hour: 0,
                    minute: 0,
                    leadTime: {
                      days: 0,
                      __typename: 'CutOffTimeLeadTime_v2',
                    },
                    __typename: 'CutOffTimeGenericRule_v2',
                  },
                  perDay: {
                    _0: {
                      enabled: false,
                      dayOfWeekIndex: 0,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _1: {
                      enabled: false,
                      dayOfWeekIndex: 1,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _2: {
                      enabled: false,
                      dayOfWeekIndex: 2,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _3: {
                      enabled: false,
                      dayOfWeekIndex: 3,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _4: {
                      enabled: false,
                      dayOfWeekIndex: 4,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _5: {
                      enabled: false,
                      dayOfWeekIndex: 5,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _6: {
                      enabled: false,
                      dayOfWeekIndex: 6,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    __typename: 'CutOffTimePerDayList_v2',
                  },
                  __typename: 'CutOffTime_v2',
                },
                __typename: 'ProductType',
              },
              __typename: 'Variant',
              dealDetails: null,
            },
            __typename: 'CartProductV2',
          },
          {
            _id: '63ff4ba6556bbe001380124f',
            variantId: '5 KG',
            qty: 3,
            vendorId: 'e76d48970971564e560d4aa4',
            deliveryOptionId: '668bdc8c792008001b5838e7',
            price: 480,
            baseUnit: 'Packung',
            unitsInPackaging: 5,
            productType: '__default__',
            productCopy: {
              _id: '63ff4ba6556bbe001380124f',
              name: 'Trüffel Box',
              description: '',
              vendorId: 'e76d48970971564e560d4aa4',
              vendorSpecificId: '0999',
              image:
                'https://storage.googleapis.com/chfslst-product-images/e76d48970971564e560d4aa4%2Fperigord-black-truffle-50g.jpg',
              imageUrl: null,
              categories: ['selection_3'],
              existsInUserLists: false,
              __typename: 'Product',
            },
            variantCopy: {
              _id: '9367ca94-6e4d-45e4-9211-fc242031a23f',
              minOrderQuantity: 1,
              cartQuantityStep: 1,
              recommendedRetailPrice: 0,
              baseUnit: 'Packung',
              baseUnitMapped: {
                en: 'Packung',
                de: 'Packung',
                __typename: 'MappedTranslation',
              },
              packagingMapped: null,
              packaging: '5 KG',
              packagingWeight: null,
              unitsInPackaging: 5,
              price: 480,
              dealPrice: null,
              purchasingPrice: 0,
              dealIsActive: false,
              originalPrice: 480,
              discount: null,
              productType: {
                _id: '63eddd72ad4ce800132db416',
                readableId: '__default__',
                name: null,
                subTitle: null,
                description: null,
                icon: null,
                iconColor: null,
                hideAtProduct: true,
                isDefault: true,
                cutOffTime: {
                  enabled: false,
                  generic: {
                    hour: 0,
                    minute: 0,
                    leadTime: {
                      days: 0,
                      __typename: 'CutOffTimeLeadTime_v2',
                    },
                    __typename: 'CutOffTimeGenericRule_v2',
                  },
                  perDay: {
                    _0: {
                      enabled: false,
                      dayOfWeekIndex: 0,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _1: {
                      enabled: false,
                      dayOfWeekIndex: 1,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _2: {
                      enabled: false,
                      dayOfWeekIndex: 2,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _3: {
                      enabled: false,
                      dayOfWeekIndex: 3,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _4: {
                      enabled: false,
                      dayOfWeekIndex: 4,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _5: {
                      enabled: false,
                      dayOfWeekIndex: 5,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _6: {
                      enabled: false,
                      dayOfWeekIndex: 6,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    __typename: 'CutOffTimePerDayList_v2',
                  },
                  __typename: 'CutOffTime_v2',
                },
                __typename: 'ProductType',
              },
              __typename: 'Variant',
              dealDetails: null,
            },
            __typename: 'CartProductV2',
          },
          {
            _id: '63ff4ba6556bbe001380124f',
            variantId: '5 KG',
            qty: 3,
            vendorId: 'e76d48970971564e560d4aa4',
            deliveryOptionId: '668bdc8c792008001b5838e7',
            price: 480,
            baseUnit: 'Packung',
            unitsInPackaging: 5,
            productType: '__default__',
            productCopy: {
              _id: '63ff4ba6556bbe001380124f',
              name: 'Trüffel Box',
              description: '',
              vendorId: 'e76d48970971564e560d4aa4',
              vendorSpecificId: '0999',
              image:
                'https://storage.googleapis.com/chfslst-product-images/e76d48970971564e560d4aa4%2Fperigord-black-truffle-50g.jpg',
              imageUrl: null,
              categories: ['selection_3'],
              existsInUserLists: false,
              __typename: 'Product',
            },
            variantCopy: {
              _id: '9367ca94-6e4d-45e4-9211-fc242031a23f',
              minOrderQuantity: 1,
              cartQuantityStep: 1,
              recommendedRetailPrice: 0,
              baseUnit: 'Packung',
              baseUnitMapped: {
                en: 'Packung',
                de: 'Packung',
                __typename: 'MappedTranslation',
              },
              packagingMapped: null,
              packaging: '5 KG',
              packagingWeight: null,
              unitsInPackaging: 5,
              price: 480,
              dealPrice: null,
              purchasingPrice: 0,
              dealIsActive: false,
              originalPrice: 480,
              discount: null,
              productType: {
                _id: '63eddd72ad4ce800132db416',
                readableId: '__default__',
                name: null,
                subTitle: null,
                description: null,
                icon: null,
                iconColor: null,
                hideAtProduct: true,
                isDefault: true,
                cutOffTime: {
                  enabled: false,
                  generic: {
                    hour: 0,
                    minute: 0,
                    leadTime: {
                      days: 0,
                      __typename: 'CutOffTimeLeadTime_v2',
                    },
                    __typename: 'CutOffTimeGenericRule_v2',
                  },
                  perDay: {
                    _0: {
                      enabled: false,
                      dayOfWeekIndex: 0,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _1: {
                      enabled: false,
                      dayOfWeekIndex: 1,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _2: {
                      enabled: false,
                      dayOfWeekIndex: 2,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _3: {
                      enabled: false,
                      dayOfWeekIndex: 3,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _4: {
                      enabled: false,
                      dayOfWeekIndex: 4,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _5: {
                      enabled: false,
                      dayOfWeekIndex: 5,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _6: {
                      enabled: false,
                      dayOfWeekIndex: 6,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    __typename: 'CutOffTimePerDayList_v2',
                  },
                  __typename: 'CutOffTime_v2',
                },
                __typename: 'ProductType',
              },
              __typename: 'Variant',
              dealDetails: null,
            },
            __typename: 'CartProductV2',
          },
          {
            _id: '63ff4ba6556bbe001380124f',
            variantId: '5 KG',
            qty: 3,
            vendorId: 'e76d48970971564e560d4aa4',
            deliveryOptionId: '668bdc8c792008001b5838e7',
            price: 480,
            baseUnit: 'Packung',
            unitsInPackaging: 5,
            productType: '__default__',
            productCopy: {
              _id: '63ff4ba6556bbe001380124f',
              name: 'Trüffel Box',
              description: '',
              vendorId: 'e76d48970971564e560d4aa4',
              vendorSpecificId: '0999',
              image:
                'https://storage.googleapis.com/chfslst-product-images/e76d48970971564e560d4aa4%2Fperigord-black-truffle-50g.jpg',
              imageUrl: null,
              categories: ['selection_3'],
              existsInUserLists: false,
              __typename: 'Product',
            },
            variantCopy: {
              _id: '9367ca94-6e4d-45e4-9211-fc242031a23f',
              minOrderQuantity: 1,
              cartQuantityStep: 1,
              recommendedRetailPrice: 0,
              baseUnit: 'Packung',
              baseUnitMapped: {
                en: 'Packung',
                de: 'Packung',
                __typename: 'MappedTranslation',
              },
              packagingMapped: null,
              packaging: '5 KG',
              packagingWeight: null,
              unitsInPackaging: 5,
              price: 480,
              dealPrice: null,
              purchasingPrice: 0,
              dealIsActive: false,
              originalPrice: 480,
              discount: null,
              productType: {
                _id: '63eddd72ad4ce800132db416',
                readableId: '__default__',
                name: null,
                subTitle: null,
                description: null,
                icon: null,
                iconColor: null,
                hideAtProduct: true,
                isDefault: true,
                cutOffTime: {
                  enabled: false,
                  generic: {
                    hour: 0,
                    minute: 0,
                    leadTime: {
                      days: 0,
                      __typename: 'CutOffTimeLeadTime_v2',
                    },
                    __typename: 'CutOffTimeGenericRule_v2',
                  },
                  perDay: {
                    _0: {
                      enabled: false,
                      dayOfWeekIndex: 0,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _1: {
                      enabled: false,
                      dayOfWeekIndex: 1,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _2: {
                      enabled: false,
                      dayOfWeekIndex: 2,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _3: {
                      enabled: false,
                      dayOfWeekIndex: 3,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _4: {
                      enabled: false,
                      dayOfWeekIndex: 4,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _5: {
                      enabled: false,
                      dayOfWeekIndex: 5,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _6: {
                      enabled: false,
                      dayOfWeekIndex: 6,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    __typename: 'CutOffTimePerDayList_v2',
                  },
                  __typename: 'CutOffTime_v2',
                },
                __typename: 'ProductType',
              },
              __typename: 'Variant',
              dealDetails: null,
            },
            __typename: 'CartProductV2',
          },
          {
            _id: '63ff4ba6556bbe001380124f',
            variantId: '5 KG',
            qty: 3,
            vendorId: 'e76d48970971564e560d4aa4',
            deliveryOptionId: '668bdc8c792008001b5838e7',
            price: 480,
            baseUnit: 'Packung',
            unitsInPackaging: 5,
            productType: '__default__',
            productCopy: {
              _id: '63ff4ba6556bbe001380124f',
              name: 'Trüffel Box',
              description: '',
              vendorId: 'e76d48970971564e560d4aa4',
              vendorSpecificId: '0999',
              image:
                'https://storage.googleapis.com/chfslst-product-images/e76d48970971564e560d4aa4%2Fperigord-black-truffle-50g.jpg',
              imageUrl: null,
              categories: ['selection_3'],
              existsInUserLists: false,
              __typename: 'Product',
            },
            variantCopy: {
              _id: '9367ca94-6e4d-45e4-9211-fc242031a23f',
              minOrderQuantity: 1,
              cartQuantityStep: 1,
              recommendedRetailPrice: 0,
              baseUnit: 'Packung',
              baseUnitMapped: {
                en: 'Packung',
                de: 'Packung',
                __typename: 'MappedTranslation',
              },
              packagingMapped: null,
              packaging: '5 KG',
              packagingWeight: null,
              unitsInPackaging: 5,
              price: 480,
              dealPrice: null,
              purchasingPrice: 0,
              dealIsActive: false,
              originalPrice: 480,
              discount: null,
              productType: {
                _id: '63eddd72ad4ce800132db416',
                readableId: '__default__',
                name: null,
                subTitle: null,
                description: null,
                icon: null,
                iconColor: null,
                hideAtProduct: true,
                isDefault: true,
                cutOffTime: {
                  enabled: false,
                  generic: {
                    hour: 0,
                    minute: 0,
                    leadTime: {
                      days: 0,
                      __typename: 'CutOffTimeLeadTime_v2',
                    },
                    __typename: 'CutOffTimeGenericRule_v2',
                  },
                  perDay: {
                    _0: {
                      enabled: false,
                      dayOfWeekIndex: 0,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _1: {
                      enabled: false,
                      dayOfWeekIndex: 1,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _2: {
                      enabled: false,
                      dayOfWeekIndex: 2,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _3: {
                      enabled: false,
                      dayOfWeekIndex: 3,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _4: {
                      enabled: false,
                      dayOfWeekIndex: 4,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _5: {
                      enabled: false,
                      dayOfWeekIndex: 5,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _6: {
                      enabled: false,
                      dayOfWeekIndex: 6,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    __typename: 'CutOffTimePerDayList_v2',
                  },
                  __typename: 'CutOffTime_v2',
                },
                __typename: 'ProductType',
              },
              __typename: 'Variant',
              dealDetails: null,
            },
            __typename: 'CartProductV2',
          },
          {
            _id: '63ff4ba6556bbe001380124f',
            variantId: '5 KG',
            qty: 0.5,
            vendorId: 'e76d48970971564e560d4aa4',
            deliveryOptionId: '668bdc8c792008001b5838e7',
            price: 480,
            baseUnit: 'Packung',
            unitsInPackaging: 5,
            productType: '__default__',
            productCopy: {
              _id: '63ff4ba6556bbe001380124f',
              name: 'Trüffel Box',
              description: '',
              vendorId: 'e76d48970971564e560d4aa4',
              vendorSpecificId: '0999',
              image:
                'https://storage.googleapis.com/chfslst-product-images/e76d48970971564e560d4aa4%2Fperigord-black-truffle-50g.jpg',
              imageUrl: null,
              categories: ['selection_3'],
              existsInUserLists: false,
              __typename: 'Product',
            },
            variantCopy: {
              _id: '9367ca94-6e4d-45e4-9211-fc242031a23f',
              minOrderQuantity: 1,
              cartQuantityStep: 1,
              recommendedRetailPrice: 0,
              baseUnit: 'Packung',
              baseUnitMapped: {
                en: 'Packung',
                de: 'Packung',
                __typename: 'MappedTranslation',
              },
              packagingMapped: null,
              packaging: '5 KG',
              packagingWeight: null,
              unitsInPackaging: 5,
              price: 480,
              dealPrice: null,
              purchasingPrice: 0,
              dealIsActive: false,
              originalPrice: 480,
              discount: null,
              productType: {
                _id: '63eddd72ad4ce800132db416',
                readableId: '__default__',
                name: null,
                subTitle: null,
                description: null,
                icon: null,
                iconColor: null,
                hideAtProduct: true,
                isDefault: true,
                cutOffTime: {
                  enabled: false,
                  generic: {
                    hour: 0,
                    minute: 0,
                    leadTime: {
                      days: 0,
                      __typename: 'CutOffTimeLeadTime_v2',
                    },
                    __typename: 'CutOffTimeGenericRule_v2',
                  },
                  perDay: {
                    _0: {
                      enabled: false,
                      dayOfWeekIndex: 0,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _1: {
                      enabled: false,
                      dayOfWeekIndex: 1,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _2: {
                      enabled: false,
                      dayOfWeekIndex: 2,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _3: {
                      enabled: false,
                      dayOfWeekIndex: 3,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _4: {
                      enabled: false,
                      dayOfWeekIndex: 4,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _5: {
                      enabled: false,
                      dayOfWeekIndex: 5,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _6: {
                      enabled: false,
                      dayOfWeekIndex: 6,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    __typename: 'CutOffTimePerDayList_v2',
                  },
                  __typename: 'CutOffTime_v2',
                },
                __typename: 'ProductType',
              },
              __typename: 'Variant',
              dealDetails: null,
            },
            __typename: 'CartProductV2',
          },
          {
            _id: '63ff4ba6556bbe001380124f',
            variantId: '5 KG',
            qty: 3,
            vendorId: 'e76d48970971564e560d4aa4',
            deliveryOptionId: '668bdc8c792008001b5838e7',
            price: 480,
            baseUnit: 'Packung',
            unitsInPackaging: 5,
            productType: '__default__',
            productCopy: {
              _id: '63ff4ba6556bbe001380124f',
              name: 'Trüffel Box',
              description: '',
              vendorId: 'e76d48970971564e560d4aa4',
              vendorSpecificId: '0999',
              image:
                'https://storage.googleapis.com/chfslst-product-images/e76d48970971564e560d4aa4%2Fperigord-black-truffle-50g.jpg',
              imageUrl: null,
              categories: ['selection_3'],
              existsInUserLists: false,
              __typename: 'Product',
            },
            variantCopy: {
              _id: '9367ca94-6e4d-45e4-9211-fc242031a23f',
              minOrderQuantity: 5,
              cartQuantityStep: 2.5,
              recommendedRetailPrice: 0,
              baseUnit: 'Packung',
              baseUnitMapped: {
                en: 'Packung',
                de: 'Packung',
                __typename: 'MappedTranslation',
              },
              packagingMapped: null,
              packaging: '5 KG',
              packagingWeight: null,
              unitsInPackaging: 5,
              price: 480,
              dealPrice: null,
              purchasingPrice: 0,
              dealIsActive: false,
              originalPrice: 480,
              discount: null,
              productType: {
                _id: '63eddd72ad4ce800132db416',
                readableId: '__default__',
                name: null,
                subTitle: null,
                description: null,
                icon: null,
                iconColor: null,
                hideAtProduct: true,
                isDefault: true,
                cutOffTime: {
                  enabled: false,
                  generic: {
                    hour: 0,
                    minute: 0,
                    leadTime: {
                      days: 0,
                      __typename: 'CutOffTimeLeadTime_v2',
                    },
                    __typename: 'CutOffTimeGenericRule_v2',
                  },
                  perDay: {
                    _0: {
                      enabled: false,
                      dayOfWeekIndex: 0,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _1: {
                      enabled: false,
                      dayOfWeekIndex: 1,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _2: {
                      enabled: false,
                      dayOfWeekIndex: 2,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _3: {
                      enabled: false,
                      dayOfWeekIndex: 3,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _4: {
                      enabled: false,
                      dayOfWeekIndex: 4,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _5: {
                      enabled: false,
                      dayOfWeekIndex: 5,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _6: {
                      enabled: false,
                      dayOfWeekIndex: 6,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    __typename: 'CutOffTimePerDayList_v2',
                  },
                  __typename: 'CutOffTime_v2',
                },
                __typename: 'ProductType',
              },
              __typename: 'Variant',
              dealDetails: null,
            },
            __typename: 'CartProductV2',
          },
          {
            _id: '63ff4ba6556bbe001380124f',
            variantId: '5 KG',
            qty: 0.1,
            vendorId: 'e76d48970971564e560d4aa4',
            deliveryOptionId: '668bdc8c792008001b5838e7',
            price: 480,
            baseUnit: 'Packung',
            unitsInPackaging: 5,
            productType: '__default__',
            productCopy: {
              _id: '63ff4ba6556bbe001380124f',
              name: 'Trüffel Box',
              description: '',
              vendorId: 'e76d48970971564e560d4aa4',
              vendorSpecificId: '0999',
              image:
                'https://storage.googleapis.com/chfslst-product-images/e76d48970971564e560d4aa4%2Fperigord-black-truffle-50g.jpg',
              imageUrl: null,
              categories: ['selection_3'],
              existsInUserLists: false,
              __typename: 'Product',
            },
            variantCopy: {
              _id: '9367ca94-6e4d-45e4-9211-fc242031a23f',
              minOrderQuantity: 1,
              cartQuantityStep: 1,
              recommendedRetailPrice: 0,
              baseUnit: 'Packung',
              baseUnitMapped: {
                en: 'Packung',
                de: 'Packung',
                __typename: 'MappedTranslation',
              },
              packagingMapped: null,
              packaging: '5 KG',
              packagingWeight: null,
              unitsInPackaging: 5,
              price: 480,
              dealPrice: null,
              purchasingPrice: 0,
              dealIsActive: false,
              originalPrice: 480,
              discount: null,
              productType: {
                _id: '63eddd72ad4ce800132db416',
                readableId: '__default__',
                name: null,
                subTitle: null,
                description: null,
                icon: null,
                iconColor: null,
                hideAtProduct: true,
                isDefault: true,
                cutOffTime: {
                  enabled: false,
                  generic: {
                    hour: 0,
                    minute: 0,
                    leadTime: {
                      days: 0,
                      __typename: 'CutOffTimeLeadTime_v2',
                    },
                    __typename: 'CutOffTimeGenericRule_v2',
                  },
                  perDay: {
                    _0: {
                      enabled: false,
                      dayOfWeekIndex: 0,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _1: {
                      enabled: false,
                      dayOfWeekIndex: 1,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _2: {
                      enabled: false,
                      dayOfWeekIndex: 2,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _3: {
                      enabled: false,
                      dayOfWeekIndex: 3,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _4: {
                      enabled: false,
                      dayOfWeekIndex: 4,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _5: {
                      enabled: false,
                      dayOfWeekIndex: 5,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _6: {
                      enabled: false,
                      dayOfWeekIndex: 6,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    __typename: 'CutOffTimePerDayList_v2',
                  },
                  __typename: 'CutOffTime_v2',
                },
                __typename: 'ProductType',
              },
              __typename: 'Variant',
              dealDetails: null,
            },
            __typename: 'CartProductV2',
          },
          {
            _id: '63ff4ba6556bbe001380124f',
            variantId: '5 KG',
            qty: 0.25,
            vendorId: 'e76d48970971564e560d4aa4',
            deliveryOptionId: '668bdc8c792008001b5838e7',
            price: 480,
            baseUnit: 'Packung',
            unitsInPackaging: 5,
            productType: '__default__',
            productCopy: {
              _id: '63ff4ba6556bbe001380124f',
              name: 'Trüffel Box',
              description: '',
              vendorId: 'e76d48970971564e560d4aa4',
              vendorSpecificId: '0999',
              image:
                'https://storage.googleapis.com/chfslst-product-images/e76d48970971564e560d4aa4%2Fperigord-black-truffle-50g.jpg',
              imageUrl: null,
              categories: ['selection_3'],
              existsInUserLists: false,
              __typename: 'Product',
            },
            variantCopy: {
              _id: '9367ca94-6e4d-45e4-9211-fc242031a23f',
              minOrderQuantity: 1,
              cartQuantityStep: 1,
              recommendedRetailPrice: 0,
              baseUnit: 'Packung',
              baseUnitMapped: {
                en: 'Packung',
                de: 'Packung',
                __typename: 'MappedTranslation',
              },
              packagingMapped: null,
              packaging: '5 KG',
              packagingWeight: null,
              unitsInPackaging: 5,
              price: 480,
              dealPrice: null,
              purchasingPrice: 0,
              dealIsActive: false,
              originalPrice: 480,
              discount: null,
              productType: {
                _id: '63eddd72ad4ce800132db416',
                readableId: '__default__',
                name: null,
                subTitle: null,
                description: null,
                icon: null,
                iconColor: null,
                hideAtProduct: true,
                isDefault: true,
                cutOffTime: {
                  enabled: false,
                  generic: {
                    hour: 0,
                    minute: 0,
                    leadTime: {
                      days: 0,
                      __typename: 'CutOffTimeLeadTime_v2',
                    },
                    __typename: 'CutOffTimeGenericRule_v2',
                  },
                  perDay: {
                    _0: {
                      enabled: false,
                      dayOfWeekIndex: 0,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _1: {
                      enabled: false,
                      dayOfWeekIndex: 1,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _2: {
                      enabled: false,
                      dayOfWeekIndex: 2,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _3: {
                      enabled: false,
                      dayOfWeekIndex: 3,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _4: {
                      enabled: false,
                      dayOfWeekIndex: 4,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _5: {
                      enabled: false,
                      dayOfWeekIndex: 5,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _6: {
                      enabled: false,
                      dayOfWeekIndex: 6,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    __typename: 'CutOffTimePerDayList_v2',
                  },
                  __typename: 'CutOffTime_v2',
                },
                __typename: 'ProductType',
              },
              __typename: 'Variant',
              dealDetails: null,
            },
            __typename: 'CartProductV2',
          },
          {
            _id: '63ff4ba6556bbe001380124f',
            variantId: '5 KG',
            qty: 0.3,
            vendorId: 'e76d48970971564e560d4aa4',
            deliveryOptionId: '668bdc8c792008001b5838e7',
            price: 480,
            baseUnit: 'Packung',
            unitsInPackaging: 5,
            productType: '__default__',
            productCopy: {
              _id: '63ff4ba6556bbe001380124f',
              name: 'Trüffel Box',
              description: '',
              vendorId: 'e76d48970971564e560d4aa4',
              vendorSpecificId: '0999',
              image:
                'https://storage.googleapis.com/chfslst-product-images/e76d48970971564e560d4aa4%2Fperigord-black-truffle-50g.jpg',
              imageUrl: null,
              categories: ['selection_3'],
              existsInUserLists: false,
              __typename: 'Product',
            },
            variantCopy: {
              _id: '9367ca94-6e4d-45e4-9211-fc242031a23f',
              minOrderQuantity: 1,
              cartQuantityStep: 1,
              recommendedRetailPrice: 0,
              baseUnit: 'Packung',
              baseUnitMapped: {
                en: 'Packung',
                de: 'Packung',
                __typename: 'MappedTranslation',
              },
              packagingMapped: null,
              packaging: '5 KG',
              packagingWeight: null,
              unitsInPackaging: 5,
              price: 480,
              dealPrice: null,
              purchasingPrice: 0,
              dealIsActive: false,
              originalPrice: 480,
              discount: null,
              productType: {
                _id: '63eddd72ad4ce800132db416',
                readableId: '__default__',
                name: null,
                subTitle: null,
                description: null,
                icon: null,
                iconColor: null,
                hideAtProduct: true,
                isDefault: true,
                cutOffTime: {
                  enabled: false,
                  generic: {
                    hour: 0,
                    minute: 0,
                    leadTime: {
                      days: 0,
                      __typename: 'CutOffTimeLeadTime_v2',
                    },
                    __typename: 'CutOffTimeGenericRule_v2',
                  },
                  perDay: {
                    _0: {
                      enabled: false,
                      dayOfWeekIndex: 0,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _1: {
                      enabled: false,
                      dayOfWeekIndex: 1,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _2: {
                      enabled: false,
                      dayOfWeekIndex: 2,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _3: {
                      enabled: false,
                      dayOfWeekIndex: 3,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _4: {
                      enabled: false,
                      dayOfWeekIndex: 4,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _5: {
                      enabled: false,
                      dayOfWeekIndex: 5,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _6: {
                      enabled: false,
                      dayOfWeekIndex: 6,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    __typename: 'CutOffTimePerDayList_v2',
                  },
                  __typename: 'CutOffTime_v2',
                },
                __typename: 'ProductType',
              },
              __typename: 'Variant',
              dealDetails: null,
            },
            __typename: 'CartProductV2',
          },
          {
            _id: '63ff4ba6556bbe001380124f',
            variantId: '5 KG',
            qty: 0.3,
            vendorId: 'e76d48970971564e560d4aa4',
            deliveryOptionId: '668bdc8c792008001b5838e7',
            price: 480,
            baseUnit: 'Packung',
            unitsInPackaging: 5,
            productType: '__default__',
            productCopy: {
              _id: '63ff4ba6556bbe001380124f',
              name: 'Trüffel Box',
              description: '',
              vendorId: 'e76d48970971564e560d4aa4',
              vendorSpecificId: '0999',
              image:
                'https://storage.googleapis.com/chfslst-product-images/e76d48970971564e560d4aa4%2Fperigord-black-truffle-50g.jpg',
              imageUrl: null,
              categories: ['selection_3'],
              existsInUserLists: false,
              __typename: 'Product',
            },
            variantCopy: {
              _id: '9367ca94-6e4d-45e4-9211-fc242031a23f',
              minOrderQuantity: 1,
              cartQuantityStep: 1,
              recommendedRetailPrice: 0,
              baseUnit: 'Packung',
              baseUnitMapped: {
                en: 'Packung',
                de: 'Packung',
                __typename: 'MappedTranslation',
              },
              packagingMapped: null,
              packaging: '5 KG',
              packagingWeight: null,
              unitsInPackaging: 5,
              price: 480,
              dealPrice: null,
              purchasingPrice: 0,
              dealIsActive: false,
              originalPrice: 480,
              discount: null,
              productType: {
                _id: '63eddd72ad4ce800132db416',
                readableId: '__default__',
                name: null,
                subTitle: null,
                description: null,
                icon: null,
                iconColor: null,
                hideAtProduct: true,
                isDefault: true,
                cutOffTime: {
                  enabled: false,
                  generic: {
                    hour: 0,
                    minute: 0,
                    leadTime: {
                      days: 0,
                      __typename: 'CutOffTimeLeadTime_v2',
                    },
                    __typename: 'CutOffTimeGenericRule_v2',
                  },
                  perDay: {
                    _0: {
                      enabled: false,
                      dayOfWeekIndex: 0,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _1: {
                      enabled: false,
                      dayOfWeekIndex: 1,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _2: {
                      enabled: false,
                      dayOfWeekIndex: 2,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _3: {
                      enabled: false,
                      dayOfWeekIndex: 3,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _4: {
                      enabled: false,
                      dayOfWeekIndex: 4,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _5: {
                      enabled: false,
                      dayOfWeekIndex: 5,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _6: {
                      enabled: false,
                      dayOfWeekIndex: 6,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    __typename: 'CutOffTimePerDayList_v2',
                  },
                  __typename: 'CutOffTime_v2',
                },
                __typename: 'ProductType',
              },
              __typename: 'Variant',
              dealDetails: null,
            },
            __typename: 'CartProductV2',
          },
          {
            _id: '63ff4ba6556bbe001380124f',
            variantId: '5 KG',
            qty: 0.3,
            vendorId: 'e76d48970971564e560d4aa4',
            deliveryOptionId: '668bdc8c792008001b5838e7',
            price: 480,
            baseUnit: 'Packung',
            unitsInPackaging: 5,
            productType: '__default__',
            productCopy: {
              _id: '63ff4ba6556bbe001380124f',
              name: 'Trüffel Box',
              description: '',
              vendorId: 'e76d48970971564e560d4aa4',
              vendorSpecificId: '0999',
              image:
                'https://storage.googleapis.com/chfslst-product-images/e76d48970971564e560d4aa4%2Fperigord-black-truffle-50g.jpg',
              imageUrl: null,
              categories: ['selection_3'],
              existsInUserLists: false,
              __typename: 'Product',
            },
            variantCopy: {
              _id: '9367ca94-6e4d-45e4-9211-fc242031a23f',
              minOrderQuantity: 1,
              cartQuantityStep: 1,
              recommendedRetailPrice: 0,
              baseUnit: 'Packung',
              baseUnitMapped: {
                en: 'Packung',
                de: 'Packung',
                __typename: 'MappedTranslation',
              },
              packagingMapped: null,
              packaging: '5 KG',
              packagingWeight: null,
              unitsInPackaging: 5,
              price: 480,
              dealPrice: null,
              purchasingPrice: 0,
              dealIsActive: false,
              originalPrice: 480,
              discount: null,
              productType: {
                _id: '63eddd72ad4ce800132db416',
                readableId: '__default__',
                name: null,
                subTitle: null,
                description: null,
                icon: null,
                iconColor: null,
                hideAtProduct: true,
                isDefault: true,
                cutOffTime: {
                  enabled: false,
                  generic: {
                    hour: 0,
                    minute: 0,
                    leadTime: {
                      days: 0,
                      __typename: 'CutOffTimeLeadTime_v2',
                    },
                    __typename: 'CutOffTimeGenericRule_v2',
                  },
                  perDay: {
                    _0: {
                      enabled: false,
                      dayOfWeekIndex: 0,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _1: {
                      enabled: false,
                      dayOfWeekIndex: 1,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _2: {
                      enabled: false,
                      dayOfWeekIndex: 2,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _3: {
                      enabled: false,
                      dayOfWeekIndex: 3,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _4: {
                      enabled: false,
                      dayOfWeekIndex: 4,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _5: {
                      enabled: false,
                      dayOfWeekIndex: 5,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _6: {
                      enabled: false,
                      dayOfWeekIndex: 6,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    __typename: 'CutOffTimePerDayList_v2',
                  },
                  __typename: 'CutOffTime_v2',
                },
                __typename: 'ProductType',
              },
              __typename: 'Variant',
              dealDetails: null,
            },
            __typename: 'CartProductV2',
          },
          {
            _id: '63ff4ba6556bbe001380124f',
            variantId: '5 KG',
            qty: 3,
            vendorId: 'e76d48970971564e560d4aa4',
            deliveryOptionId: '668bdc8c792008001b5838e7',
            price: 480,
            baseUnit: 'Packung',
            unitsInPackaging: 5,
            productType: '__default__',
            productCopy: {
              _id: '63ff4ba6556bbe001380124f',
              name: 'Trüffel Box',
              description: '',
              vendorId: 'e76d48970971564e560d4aa4',
              vendorSpecificId: '0999',
              image:
                'https://storage.googleapis.com/chfslst-product-images/e76d48970971564e560d4aa4%2Fperigord-black-truffle-50g.jpg',
              imageUrl: null,
              categories: ['selection_3'],
              existsInUserLists: false,
              __typename: 'Product',
            },
            variantCopy: {
              _id: '9367ca94-6e4d-45e4-9211-fc242031a23f',
              minOrderQuantity: 1,
              cartQuantityStep: 1,
              recommendedRetailPrice: 0,
              baseUnit: 'Packung',
              baseUnitMapped: {
                en: 'Packung',
                de: 'Packung',
                __typename: 'MappedTranslation',
              },
              packagingMapped: null,
              packaging: '5 KG',
              packagingWeight: null,
              unitsInPackaging: 5,
              price: 480,
              dealPrice: null,
              purchasingPrice: 0,
              dealIsActive: false,
              originalPrice: 480,
              discount: null,
              productType: {
                _id: '63eddd72ad4ce800132db416',
                readableId: '__default__',
                name: null,
                subTitle: null,
                description: null,
                icon: null,
                iconColor: null,
                hideAtProduct: true,
                isDefault: true,
                cutOffTime: {
                  enabled: false,
                  generic: {
                    hour: 0,
                    minute: 0,
                    leadTime: {
                      days: 0,
                      __typename: 'CutOffTimeLeadTime_v2',
                    },
                    __typename: 'CutOffTimeGenericRule_v2',
                  },
                  perDay: {
                    _0: {
                      enabled: false,
                      dayOfWeekIndex: 0,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _1: {
                      enabled: false,
                      dayOfWeekIndex: 1,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _2: {
                      enabled: false,
                      dayOfWeekIndex: 2,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _3: {
                      enabled: false,
                      dayOfWeekIndex: 3,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _4: {
                      enabled: false,
                      dayOfWeekIndex: 4,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _5: {
                      enabled: false,
                      dayOfWeekIndex: 5,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _6: {
                      enabled: false,
                      dayOfWeekIndex: 6,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    __typename: 'CutOffTimePerDayList_v2',
                  },
                  __typename: 'CutOffTime_v2',
                },
                __typename: 'ProductType',
              },
              __typename: 'Variant',
              dealDetails: null,
            },
            __typename: 'CartProductV2',
          },
          {
            _id: '63ff4ba6556bbe001380124f',
            variantId: '5 KG',
            qty: 3,
            vendorId: 'e76d48970971564e560d4aa4',
            deliveryOptionId: '668bdc8c792008001b5838e7',
            price: 480,
            baseUnit: 'Packung',
            unitsInPackaging: 5,
            productType: '__default__',
            productCopy: {
              _id: '63ff4ba6556bbe001380124f',
              name: 'Trüffel Box',
              description: '',
              vendorId: 'e76d48970971564e560d4aa4',
              vendorSpecificId: '0999',
              image:
                'https://storage.googleapis.com/chfslst-product-images/e76d48970971564e560d4aa4%2Fperigord-black-truffle-50g.jpg',
              imageUrl: null,
              categories: ['selection_3'],
              existsInUserLists: false,
              __typename: 'Product',
            },
            variantCopy: {
              _id: '9367ca94-6e4d-45e4-9211-fc242031a23f',
              minOrderQuantity: 1,
              cartQuantityStep: 1,
              recommendedRetailPrice: 0,
              baseUnit: 'Packung',
              baseUnitMapped: {
                en: 'Packung',
                de: 'Packung',
                __typename: 'MappedTranslation',
              },
              packagingMapped: null,
              packaging: '5 KG',
              packagingWeight: null,
              unitsInPackaging: 5,
              price: 480,
              dealPrice: null,
              purchasingPrice: 0,
              dealIsActive: false,
              originalPrice: 480,
              discount: null,
              productType: {
                _id: '63eddd72ad4ce800132db416',
                readableId: '__default__',
                name: null,
                subTitle: null,
                description: null,
                icon: null,
                iconColor: null,
                hideAtProduct: true,
                isDefault: true,
                cutOffTime: {
                  enabled: false,
                  generic: {
                    hour: 0,
                    minute: 0,
                    leadTime: {
                      days: 0,
                      __typename: 'CutOffTimeLeadTime_v2',
                    },
                    __typename: 'CutOffTimeGenericRule_v2',
                  },
                  perDay: {
                    _0: {
                      enabled: false,
                      dayOfWeekIndex: 0,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _1: {
                      enabled: false,
                      dayOfWeekIndex: 1,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _2: {
                      enabled: false,
                      dayOfWeekIndex: 2,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _3: {
                      enabled: false,
                      dayOfWeekIndex: 3,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _4: {
                      enabled: false,
                      dayOfWeekIndex: 4,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _5: {
                      enabled: false,
                      dayOfWeekIndex: 5,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _6: {
                      enabled: false,
                      dayOfWeekIndex: 6,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    __typename: 'CutOffTimePerDayList_v2',
                  },
                  __typename: 'CutOffTime_v2',
                },
                __typename: 'ProductType',
              },
              __typename: 'Variant',
              dealDetails: null,
            },
            __typename: 'CartProductV2',
          },
          {
            _id: '63ff4ba6556bbe001380124f',
            variantId: '5 KG',
            qty: 3,
            vendorId: 'e76d48970971564e560d4aa4',
            deliveryOptionId: '668bdc8c792008001b5838e7',
            price: 480,
            baseUnit: 'Packung',
            unitsInPackaging: 5,
            productType: '__default__',
            productCopy: {
              _id: '63ff4ba6556bbe001380124f',
              name: 'Trüffel Box',
              description: '',
              vendorId: 'e76d48970971564e560d4aa4',
              vendorSpecificId: '0999',
              image:
                'https://storage.googleapis.com/chfslst-product-images/e76d48970971564e560d4aa4%2Fperigord-black-truffle-50g.jpg',
              imageUrl: null,
              categories: ['selection_3'],
              existsInUserLists: false,
              __typename: 'Product',
            },
            variantCopy: {
              _id: '9367ca94-6e4d-45e4-9211-fc242031a23f',
              minOrderQuantity: 1,
              cartQuantityStep: 1,
              recommendedRetailPrice: 0,
              baseUnit: 'Packung',
              baseUnitMapped: {
                en: 'Packung',
                de: 'Packung',
                __typename: 'MappedTranslation',
              },
              packagingMapped: null,
              packaging: '5 KG',
              packagingWeight: null,
              unitsInPackaging: 5,
              price: 480,
              dealPrice: null,
              purchasingPrice: 0,
              dealIsActive: false,
              originalPrice: 480,
              discount: null,
              productType: {
                _id: '63eddd72ad4ce800132db416',
                readableId: '__default__',
                name: null,
                subTitle: null,
                description: null,
                icon: null,
                iconColor: null,
                hideAtProduct: true,
                isDefault: true,
                cutOffTime: {
                  enabled: false,
                  generic: {
                    hour: 0,
                    minute: 0,
                    leadTime: {
                      days: 0,
                      __typename: 'CutOffTimeLeadTime_v2',
                    },
                    __typename: 'CutOffTimeGenericRule_v2',
                  },
                  perDay: {
                    _0: {
                      enabled: false,
                      dayOfWeekIndex: 0,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _1: {
                      enabled: false,
                      dayOfWeekIndex: 1,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _2: {
                      enabled: false,
                      dayOfWeekIndex: 2,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _3: {
                      enabled: false,
                      dayOfWeekIndex: 3,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _4: {
                      enabled: false,
                      dayOfWeekIndex: 4,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _5: {
                      enabled: false,
                      dayOfWeekIndex: 5,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _6: {
                      enabled: false,
                      dayOfWeekIndex: 6,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    __typename: 'CutOffTimePerDayList_v2',
                  },
                  __typename: 'CutOffTime_v2',
                },
                __typename: 'ProductType',
              },
              __typename: 'Variant',
              dealDetails: null,
            },
            __typename: 'CartProductV2',
          },
          {
            _id: '63ff4ba6556bbe001380124f',
            variantId: '5 KG',
            qty: 3,
            vendorId: 'e76d48970971564e560d4aa4',
            deliveryOptionId: '668bdc8c792008001b5838e7',
            price: 480,
            baseUnit: 'Packung',
            unitsInPackaging: 5,
            productType: '__default__',
            productCopy: {
              _id: '63ff4ba6556bbe001380124f',
              name: 'Trüffel Box',
              description: '',
              vendorId: 'e76d48970971564e560d4aa4',
              vendorSpecificId: '0999',
              image:
                'https://storage.googleapis.com/chfslst-product-images/e76d48970971564e560d4aa4%2Fperigord-black-truffle-50g.jpg',
              imageUrl: null,
              categories: ['selection_3'],
              existsInUserLists: false,
              __typename: 'Product',
            },
            variantCopy: {
              _id: '9367ca94-6e4d-45e4-9211-fc242031a23f',
              minOrderQuantity: 1,
              cartQuantityStep: 1,
              recommendedRetailPrice: 0,
              baseUnit: 'Packung',
              baseUnitMapped: {
                en: 'Packung',
                de: 'Packung',
                __typename: 'MappedTranslation',
              },
              packagingMapped: null,
              packaging: '5 KG',
              packagingWeight: null,
              unitsInPackaging: 5,
              price: 480,
              dealPrice: null,
              purchasingPrice: 0,
              dealIsActive: false,
              originalPrice: 480,
              discount: null,
              productType: {
                _id: '63eddd72ad4ce800132db416',
                readableId: '__default__',
                name: null,
                subTitle: null,
                description: null,
                icon: null,
                iconColor: null,
                hideAtProduct: true,
                isDefault: true,
                cutOffTime: {
                  enabled: false,
                  generic: {
                    hour: 0,
                    minute: 0,
                    leadTime: {
                      days: 0,
                      __typename: 'CutOffTimeLeadTime_v2',
                    },
                    __typename: 'CutOffTimeGenericRule_v2',
                  },
                  perDay: {
                    _0: {
                      enabled: false,
                      dayOfWeekIndex: 0,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _1: {
                      enabled: false,
                      dayOfWeekIndex: 1,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _2: {
                      enabled: false,
                      dayOfWeekIndex: 2,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _3: {
                      enabled: false,
                      dayOfWeekIndex: 3,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _4: {
                      enabled: false,
                      dayOfWeekIndex: 4,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _5: {
                      enabled: false,
                      dayOfWeekIndex: 5,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    _6: {
                      enabled: false,
                      dayOfWeekIndex: 6,
                      hour: 0,
                      minute: 0,
                      leadTime: {
                        days: 0,
                        __typename: 'CutOffTimeLeadTime_v2',
                      },
                      __typename: 'CutOffTimePerDayRule_v2',
                    },
                    __typename: 'CutOffTimePerDayList_v2',
                  },
                  __typename: 'CutOffTime_v2',
                },
                __typename: 'ProductType',
              },
              __typename: 'Variant',
              dealDetails: null,
            },
            __typename: 'CartProductV2',
          },
          {
            _id: '5f11a39b2a3429001253687d',
            variantId: 'Kiste (20x0,33L)',
            qty: 3,
            vendorId: 'e76d48970971564e560d4aa4',
            deliveryOptionId: '668bdc94792008001b5838fd',
            price: 16.99,
            baseUnit: 'L',
            unitsInPackaging: 6.6,
            productType: '15 Days',
            productCopy: {
              _id: '5f11a39b2a3429001253687d',
              name: 'CREW Drunken Sailor',
              description: '20x0,33 EW',
              vendorId: 'e76d48970971564e560d4aa4',
              vendorSpecificId: '1',
              image: '',
              imageUrl: null,
              categories: ['Nice Meal'],
              existsInUserLists: false,
              __typename: 'Product',
            },
            variantCopy: {
              _id: '5f4e3266caf4db41288b11ed',
              minOrderQuantity: 1,
              cartQuantityStep: 1,
              recommendedRetailPrice: 0,
              baseUnit: 'L',
              baseUnitMapped: {
                en: 'Liter',
                de: 'Liter',
                __typename: 'MappedTranslation',
              },
              packagingMapped: null,
              packaging: 'Kiste (20x0,33L)',
              packagingWeight: null,
              unitsInPackaging: 6.6,
              price: 16.99,
              dealPrice: null,
              purchasingPrice: 0,
              dealIsActive: false,
              originalPrice: 16.99,
              discount: null,
              productType: {
                _id: '64901ca23331f900139693f3',
                readableId: '15 Days',
                name: {
                  en: null,
                  de: '15 days delivery',
                  fr: null,
                  es: null,
                  ve: null,
                  __typename: 'Translations',
                },
                subTitle: {
                  en: null,
                  de: '15_Days',
                  fr: null,
                  es: null,
                  ve: null,
                  __typename: 'Translations',
                },
                description: {
                  en: null,
                  de: 'Delivery takes around 15-18 business days',
                  fr: null,
                  es: null,
                  ve: null,
                  __typename: 'Translations',
                },
                icon:
                  'https://storage.googleapis.com/chfslst-product-images/e76d48970971564e560d4aa4/productType-images/15-days-no-reason-to-return.png',
                iconColor: null,
                hideAtProduct: null,
                isDefault: null,
                __typename: 'ProductType',
              },
              __typename: 'Variant',
              dealDetails: null,
            },
            __typename: 'CartProductV2',
          },
          {
            _id: '5f27ddc1e8d1d5001979795c',
            variantId: 'Flasche (0.3L)',
            qty: 0.5,
            vendorId: 'e76d48970971564e560d4aa4',
            deliveryOptionId: '668bdc8c792008001b5838e7',
            price: 0.7,
            baseUnit: 'L',
            unitsInPackaging: 0.3,
            productType: '__default__',
            productCopy: {
              _id: '5f27ddc1e8d1d5001979795c',
              name: 'Maisels weisse  0,3 l',
              description:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. End of the TEXT',
              vendorId: 'e76d48970971564e560d4aa4',
              vendorSpecificId: '101',
              image:
                'https://storage.googleapis.com/chfslst-product-images/e76d48970971564e560d4aa4%2F2823330_digital-image.avif',
              imageUrl: null,
              categories: [],
              existsInUserLists: false,
              __typename: 'Product',
            },
            variantCopy: {
              _id: '5f4e3271caf4db41288b2b4c',
              minOrderQuantity: 1,
              cartQuantityStep: 1,
              recommendedRetailPrice: 0,
              baseUnit: 'L',
              baseUnitMapped: {
                en: 'Liter',
                de: 'Liter',
                __typename: 'MappedTranslation',
              },
              packagingMapped: null,
              packaging: 'Flasche (0.3L)',
              packagingWeight: null,
              unitsInPackaging: 0.3,
              price: 0.7,
              dealPrice: null,
              purchasingPrice: 0,
              dealIsActive: false,
              originalPrice: 0.7,
              discount: null,
              productType: {
                _id: '63eddd72ad4ce800132db416',
                readableId: '__default__',
                name: null,
                subTitle: null,
                description: null,
                icon: null,
                iconColor: null,
                hideAtProduct: true,
                isDefault: true,
                __typename: 'ProductType',
              },
              __typename: 'Variant',
              dealDetails: null,
            },
            __typename: 'CartProductV2',
          },
        ],
        deliveryOptions: TEMP_DELIVERY_OPTIONS,
        totalPrice: 1492.37,
        totalItems: 1,
        totalQty: 8,
        __typename: 'CartV2',
      },
    ],
  },
};
