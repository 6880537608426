import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';

const useStyles = makeStyles(({ typography, spacing, palette }) => ({
  container: {
    width: 'fit-content',
    maxWidth: 'max-content',
  },
  switchOrderRouteButton: {
    fontWeight: typography.fontWeightBold,
    textTransform: 'none',
    display: 'flex',
    whiteSpace: 'nowrap',
    height: 40,
    borderRadius: 40,
    boxShadow: 'none',
    '&:not(:first-child)': {
      margin: spacing(0, 1),
    },
  },
  tabTitleExtraLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: typography.pxToRem(10),
    lineHeight: '14px',
    color: '#009E23',
    background: 'rgba(0, 158, 35, 0.16)',
    padding: spacing(0.25, 0.75),
    marginLeft: spacing(1),
    borderRadius: 32,
  },
  inActiveButton: {
    border: palette.border.grey,
    background: palette.background.mutedLight,
    color: palette.text.primary,
  },
}));

export default function SwitchTablesActionButtons({
  isAiOrdersActive,
  isOrdersActive,
}) {
  const classes = useStyles();

  const { t } = useTranslation();

  const history = useHistory();

  const handleChangeOrderRoute = (newValue) => () => {
    history.push(newValue);
  };

  return (
    <Grid container className={classes.container} wrap="nowrap">
      <Button
        variant="contained"
        color={isAiOrdersActive ? 'secondary' : 'default'}
        onClick={handleChangeOrderRoute('/orders/ai')}
        className={classNames(
          classes.switchOrderRouteButton,
          !isAiOrdersActive && classes.inActiveButton
        )}
      >
        {t('aiOrders.title')}
        <span className={classes.tabTitleExtraLabel}>
          {t('aiOrders.new')}
        </span>
      </Button>
      <Button
        variant="contained"
        color={isOrdersActive ? 'secondary' : 'default'}
        onClick={handleChangeOrderRoute('/orders')}
        className={classNames(
          classes.switchOrderRouteButton,
          !isOrdersActive && classes.inActiveButton
        )}
      >
        {t('orders.title')}
      </Button>
    </Grid>
  );
}

SwitchTablesActionButtons.propTypes = {
  isAiOrdersActive: PropTypes.bool,
  isOrdersActive: PropTypes.bool,
};

SwitchTablesActionButtons.defaultProps = {
  isAiOrdersActive: false,
  isOrdersActive: false,
};
