import React, { memo, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import HelpRoundedIcon from '@material-ui/icons/HelpRounded';
import CustomPopover from 'components/shared/Popover/CustomPopover';
import { AI_ORDER_STATUSES } from 'helpers/constants';

const useStyles = makeStyles(({ spacing, typography }) => ({
  statusLegendIcon: {
    padding: spacing(1),
  },
  aiOrderLegendTitle: {
    fontWeight: typography.fontWeightBold,
    margin: spacing(1.5, 0),
  },
  statusContainer: {
    padding: spacing(0.5, 1),
    cursor: 'default',
  },
  statusCircle: {
    width: 20,
    height: 20,
    borderRadius: '50%',
    marginRight: spacing(1),
  },
}));

function AIOrderTableStatusLegend() {
  const { t } = useTranslation();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(false);

  const handleOpenLegend = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseLegend = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const statuses = useMemo(() => Object.values(AI_ORDER_STATUSES), []);

  return (
    <>
      <IconButton className={classes.statusLegendIcon} onClick={handleOpenLegend}>
        <HelpRoundedIcon />
      </IconButton>
      <CustomPopover
        selectorIsOpen={open}
        anchorEl={anchorEl}
        handleCloseSelector={handleCloseLegend}
      >
        <Grid container wrap="nowrap" direction="column">
          <Typography
            align="center"
            className={classes.aiOrderLegendTitle}
            variant="body1"
          >
            {t('AI Orders Legend')}
          </Typography>
          {statuses.map(({ key, color, icon }) => (
            <Grid key={key} container item className={classes.statusContainer}>
              <div
                className={classes.statusCircle}
                style={{ background: color }}
              >
                {icon?.render()}
              </div>
              <Typography variant="body2" gutterBottom>
                {t(`aiOrders.statuses.${key}`)}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </CustomPopover>
    </>
  );
}

export default memo(AIOrderTableStatusLegend);
