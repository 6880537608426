import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import NOOP from 'lodash/noop';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
// import IconButton from '@material-ui/core/IconButton';
// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemText from '@material-ui/core/ListItemText';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

// import CheckIcon from '@material-ui/icons/CheckOutlined';
// import DeleteIcon from '@material-ui/icons/DeleteOutlineOutlined';
// import ReviewIcon from '@material-ui/icons/FindInPageOutlined';
// import ReprocessIcon from '@material-ui/icons/RestorePageOutlined';
// import MoreVertRoundedIcon from '@material-ui/icons/MoreVertRounded';

import EmptyTableField from 'components/shared/EmptyTableField';
import { useSharedTableStyles } from 'components/shared/styles/sharedTableStyles';
import { AI_ORDER_STATUSES } from 'helpers/constants';
// import CustomPopover from 'components/shared/Popover/CustomPopover';

const useStyles = makeStyles(({ spacing }) => ({
  orderCustomerTitle: {
    width: 300,
  },
  businessName: {
    whiteSpace: 'break-spaces',
  },
  statusCircle: {
    width: 20,
    height: 20,
    borderRadius: '50%',
  },
  deliveryDateTitle: {
    paddingLeft: spacing(0.5),
  },
  isClickableTitle: {
    cursor: 'pointer',
  },
}));

const AIOrdersTableRow = React.memo(
  ({ rowData, handleCancelOrder, handleAcceptOrder }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const sharedTableStyles = useSharedTableStyles();

    const history = useHistory();

    // const [anchorEl, setAnchorEl] = useState(false);

    const {
      customer,
      receivedAt,
      status,
      // deliveryOptions,
      products,
    } = rowData;

    const { contactData, vendorClientId } = customer || {};

    const parsedStatus = AI_ORDER_STATUSES[status];
    // const isClickable =
    //   parsedStatus !== AI_ORDER_STATUSES.accept &&
    //   parsedStatus !== AI_ORDER_STATUSES.autoAccepted;
    const isClickable = parsedStatus !== AI_ORDER_STATUSES.received;
    // && parsedStatus !== AI_ORDER_STATUSES.accepted;

    // const handleOpenActionMenu = event => {
    //   setAnchorEl(event.currentTarget);
    // };

    // const handleCloseActionMenu = () => {
    //   setAnchorEl(null);
    // };

    const handleStartOrderReview = () => {
      history.push(`/orders/ai-order-review?orderId=${rowData._id}`);
    };

    // const isActionMenuOpen = Boolean(anchorEl);

    return (
      <TableRow
        role="button"
        onClick={isClickable ? handleStartOrderReview : NOOP}
        className={classNames(
          sharedTableStyles.tableBodyRowV2,
          isClickable && classes.isClickableTitle
        )}
      >
        <TableCell
          className={classNames(
            sharedTableStyles.tableCell,
            classes.orderCustomerTitle
          )}
        >
          <Grid container direction="column" wrap="nowrap">
            <Typography className={classes.businessName} variant="body2">
              {contactData?.businessName || 'processing...'}
            </Typography>
          </Grid>
        </TableCell>
        <TableCell className={sharedTableStyles.tableCell}>
          <Typography variant="body2">
            {vendorClientId || 'processing...'}
          </Typography>
        </TableCell>
        <TableCell className={sharedTableStyles.tableCell}>
          <Typography className={classes.deliveryDateTitle} variant="body2">
            {moment(receivedAt).format('ddd DD.MM.YY / HH:mm')}
          </Typography>
        </TableCell>
        <TableCell className={sharedTableStyles.tableCell}>
          {/* FIXME: WHICH DELIVERY OPTION WE NEED TO USE ? */}
          <Typography className={classes.deliveryDateTitle} variant="body2">
            {moment(moment().add(2, 'days')).format('ddd DD.MM.YY')}
          </Typography>
          {/* {deliveryOptions[0]?.deliveryDate ? (
            <Typography className={classes.deliveryDateTitle} variant="body2">
              {moment(deliveryOptions[0].deliveryDate).format(
                'ddd DD.MM.YY / HH:mm'
              )}
            </Typography>
          ) : (
            <EmptyTableField />
          )} */}
        </TableCell>
        {/* <TableCell className={sharedTableStyles.tableCell}>
          {products?.totalResults ? (
            <Typography align="center" variant="body2">
              {products.totalResults}
            </Typography>
          ) : (
            <EmptyTableField align="center" />
          )}
        </TableCell> */}
        <TableCell className={sharedTableStyles.tableCell} align="center">
          {parsedStatus && (
            <Grid container justifyContent="center">
              <Tooltip title={t(`aiOrders.statuses.${parsedStatus.key}`)}>
                <div
                  key={parsedStatus.key}
                  className={classes.statusCircle}
                  style={{
                    background: parsedStatus.color,
                  }}
                >
                  {parsedStatus.icon?.render()}
                </div>
              </Tooltip>
            </Grid>
          )}
        </TableCell>
        {/* DEMO: Disable action column to hide reprocess button, etc. */}
        {/* <TableCell
          className={classNames(
            sharedTableStyles.tableCell,
            sharedTableStyles.actionButtonContainer
          )}
        >
          <Grid
            container
            wrap="nowrap"
            justifyContent="center"
            alignItems="center"
          >
            <IconButton onClick={handleOpenActionMenu} size="medium">
              <MoreVertRoundedIcon />
            </IconButton>
          </Grid>
          <CustomPopover
            selectorIsOpen={isActionMenuOpen}
            anchorEl={anchorEl}
            handleCloseSelector={handleCloseActionMenu}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <List
              component="nav"
              aria-label="list titles"
              disablePadding
              onClick={handleCloseActionMenu}
            >
              <ListItem
                className={classes.newsletterActionListItem}
                button
                onClick={handleStartOrderReview}
              >
                <ListItemIcon>
                  <ReviewIcon />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.newsletterActionListItemText }}
                  primary={t('aiOrders.review')}
                />
              </ListItem>
              <ListItem
                className={classes.newsletterActionListItem}
                button
                onClick={NOOP}
              >
                <ListItemIcon>
                  <ReprocessIcon />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.newsletterActionListItemText }}
                  primary={t('aiOrders.reprocess')}
                />
              </ListItem>
              <ListItem
                className={classes.newsletterActionListItem}
                button
                onClick={() => handleAcceptOrder(rowData)}
              >
                <ListItemIcon>
                  <CheckIcon />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.newsletterActionListItemText }}
                  primary={t('common.confirm')}
                />
              </ListItem>
              <ListItem
                className={classes.newsletterActionListItem}
                button
                onClick={() => handleCancelOrder(rowData)}
              >
                <ListItemIcon>
                  <DeleteIcon />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.newsletterActionListItemText }}
                  primary={t('common.delete')}
                />
              </ListItem>
            </List>
          </CustomPopover>
        </TableCell> */}
      </TableRow>
    );
  },
  (prevProps, nextProps) => isEqual(prevProps.rowData, nextProps.rowData)
);

AIOrdersTableRow.propTypes = {
  rowData: PropTypes.object.isRequired,
  handleCancelOrder: PropTypes.func.isRequired,
  handleAcceptOrder: PropTypes.func.isRequired,
};

export default AIOrdersTableRow;
