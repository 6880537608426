import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useQuery, useMutation } from '@apollo/client';
import { GET_NOTIFICATION } from 'graphql/query/user';
import { SET_NOTIFICATION } from 'graphql/mutation/user';

import { makeStyles } from '@material-ui/styles';
import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';

import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import { APPBAR_HEIGHT } from 'helpers/constants';
import ProfileMenu from 'components/ProfileMenu';
import HeaderLogo from 'components/HeaderLogo';
import MenuSections from 'components/MenuSections';
import NotificationBar from 'components/shared/NotificationBar';

const useStyles = makeStyles(theme => ({
  appContainer: {
    minHeight: '100vh',
    background: theme.palette.background.default,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 100,
    borderBottom: theme.palette.border.lightGrey,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: 200,
    width: `calc(100% - ${200}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolbar: ({ isDrawerOpen }) => ({
    height: APPBAR_HEIGHT,
    alignItems: 'center',
    justifyContent: 'space-between',
    background: theme.palette.background.paper,
    paddingRight: 0,
    paddingLeft: isDrawerOpen ? 0 : theme.spacing(3),
  }),
  menuItemsContainer: {
    height: '100%',
    width: 'auto',
  },
  drawer: {
    width: 200,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: 200,
    zIndex: theme.zIndex.modal + 1,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(6),
  },
  menuButton: {
    marginRight: theme.spacing(4.5),
  },
  hideMenuButton: {
    display: 'none',
  },
  childContainer: {
    width: '100%',
    height: '100%',
    minHeight: `calc(100vh - ${APPBAR_HEIGHT + 1}px)`,
    // marginTop: APPBAR_HEIGHT,
    marginTop: theme.spacing(10.5),
    background: theme.palette.background.mutedLight,
    padding: theme.spacing(2, 4, 9, 9),
    color: theme.palette.text.primary,
    [theme.breakpoints.up('xl')]: {
      paddingRight: theme.spacing(3),
    },
    [theme.breakpoints.down('md')]: {
      paddingRight: theme.spacing(2),
    },
  },
  welcomePageChildren: {
    width: '100%',
    height: '100%',
    minHeight: '100vh',
    background: theme.palette.background.default,
    color: theme.palette.text.primary,
  },
  insideDrawerToolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(1.25, 1),
    ...theme.mixins.toolbar,
  },
}));

const AppLayout = ({ children, noHeader, isAdmin, isUnauthorized }) => {
  const [isDrawerOpen, setDrawerOpen] = React.useState(false);

  const classes = useStyles({ isDrawerOpen });

  const {
    data: { notification },
  } = useQuery(GET_NOTIFICATION);
  const [setNotification] = useMutation(SET_NOTIFICATION);

  const closeNotification = () => {
    setNotification({
      variables: {
        isOpen: false,
        timeout: null,
        message: null,
        type: null,
        isManual: false,
      },
    });
  };

  return (
    <Grid
      className={classes.appContainer}
      container
      direction="column"
      alignItems="center"
      wrap="nowrap"
    >
      {!noHeader && (
        <>
          <AppBar
            position="fixed"
            className={classNames(
              classes.appBar,
              isDrawerOpen && classes.appBarShift
            )}
            color="inherit"
            elevation={1}
          >
            <Toolbar className={classes.toolbar}>
              <Grid
                container
                alignItems="center"
                className={classes.menuItemsContainer}
                wrap="nowrap"
              >
                <Grid container>
                  <IconButton
                    aria-label="open drawer"
                    onClick={() => setDrawerOpen(true)}
                    edge="start"
                    className={classNames(
                      classes.menuButton,
                      isDrawerOpen && classes.hideMenuButton
                    )}
                  >
                    <MenuIcon />
                  </IconButton>
                </Grid>
                <HeaderLogo isAdmin={isAdmin} />
              </Grid>
              <ProfileMenu />
            </Toolbar>
          </AppBar>
          <Drawer
            variant="permanent"
            className={classNames(
              classes.drawer,
              isDrawerOpen ? classes.drawerOpen : classes.drawerClose
            )}
            classes={{
              paper: classNames(
                isDrawerOpen ? classes.drawerOpen : classes.drawerClose
              ),
            }}
          >
            <div className={classes.insideDrawerToolbar}>
              <IconButton onClick={() => setDrawerOpen(false)}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <Divider />
            <MenuSections
              handleCloseDrawer={() => setDrawerOpen(false)}
              isAdmin={isAdmin}
            />
          </Drawer>
        </>
      )}
      {isUnauthorized ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className={classes.welcomePageChildren}
        >
          {children}
        </Grid>
      ) : (
        <Grid
          container
          direction="column"
          className={classes.childContainer}
        >
          {children}
        </Grid>
      )}
      {notification && (
        <NotificationBar
          timeout={notification.timeout}
          message={notification.message}
          type={notification.type}
          closeNotification={closeNotification}
          isOpen={notification.isOpen}
          isManual={notification.isManual}
        />
      )}
    </Grid>
  );
};

AppLayout.propTypes = {
  children: PropTypes.node.isRequired,
  noHeader: PropTypes.bool,
  isAdmin: PropTypes.bool,
  isUnauthorized: PropTypes.bool,
};
AppLayout.defaultProps = {
  noHeader: false,
  isAdmin: false,
  isUnauthorized: false,
};

export default AppLayout;
