import { gql } from '@apollo/client';
import {
  CUTOFFTIME_FRAGMENT,
  TRANSLATION_FRAGMENT,
  VARIANT_DEAL_DETAILS_FRAGMENT,
  VARIANT_FRAGMENT,
} from 'graphql/fragments';

export const UPDATE_INCOMING_ORDER_CUSTOMER = gql`
  mutation updateIncomingOrderCustomer(
    $customerId: String!
    $orderId: String!
  ) {
    updateIncomingOrderCustomer(customerId: $customerId, orderId: $orderId) {
      _id
      customer {
        _id
        contactData {
          businessName
          street
          houseNumber
          zip
          city
          image
          website
          country
          label
        }
        contacts {
          _id
          name
          role
          phone
          additionalPhones
          email
          inviteLink
          registrationCode
          status
          userMeta {
            name
            email
          }
          disabled
        }
      }
      customerId
      deliveryOptions {
        _id
        availableDeliveryDates
        blockedDeliveryDates
        blockedDeliveryDaysIndexes
        defaultDeliveryDate
        deliveryAddress {
          zip
          city
          houseNumber
          street
          country
          lines
        }
        deliveryDate
        deliveryOption {
          optionId
          locationId
          locationOptionId
        }
        deliveryTerms {
          discount
          deliveryFee {
            value
            description {
              en
              de
              fr
              es
              ve
            }
            rule {
              _id
              periodType
              description
              translatedDescription {
                en
                de
                fr
                es
                ve
              }
              globalStartDate
              globalFinishDate
              enabled
              oldValue
              rule {
                ruleType
                valueType
                value
              }
              priceWithDiscountValue
              conditions {
                conditionType
                ids {
                  productId
                  packaging
                }
                operator
                qty {
                  from
                  to
                }
                itemValue
                totalPrice
                totalWeight
                promoCode
              }
            }
          }
          minSurcharge {
            value
          }
        }
        isSelected
        message
        productType {
          readableId
          name {
            en
            de
            fr
            es
            ve
          }
          _id
        }
        totalItems
        totalPrice
        totalQty
      }
    }
  }
`;

export const UPDATE_INCOMING_ORDER_PRODUCTS = gql`
  ${VARIANT_FRAGMENT}
  ${VARIANT_DEAL_DETAILS_FRAGMENT}
  ${CUTOFFTIME_FRAGMENT}
  ${TRANSLATION_FRAGMENT}
  mutation updateIncomingOrderProducts(
    $orderId: String!
    $productsInput: [IncomingOrderProductInput]!
  ) {
    updateIncomingOrderProducts(
      orderId: $orderId
      productsInput: $productsInput
    ) {
      _id
      products {
        page
        totalPages
        hasNextPage
        nextPage
        hasPrevPage
        prevPage
        totalResults
        products {
          _id
          selectedProduct {
            _id
            variantId
            vendorId
            baseUnit
            cartQuantityStep
            deliveryOptionId
            unitsInPackaging
            message
            price
            productType
            qty
            attributesKey
            minOrderQuantity
            productCopy {
              name
              vendorSpecificId
            }
            variantCopy {
              ...VariantFragment
              ...VariantDealDetailsFragment
            }
          }
          allMatchedProducts {
            product {
              _id
              qty
              baseUnit
              attributesKey
              variantId
              unitsInPackaging
              vendorId
              productType
              price
              deliveryOptionId
            }
            packaging
            confidenceLevel
          }
          matchingResult {
            confidenceLevel
            customerSpecificRule
            product {
              _id
              variantId
              productType
              attributesKey
              baseUnit
              deliveryOptionId
              price
              qty
              unitsInPackaging
              vendorId
            }
            reason
            type
            vendorSpecificRule
          }
          parsedProduct {
            attributesKey
            documentCoordinates {
              x
              y
              page
            }
            name
            packaging
            qty
            vendorSpecificId
          }
        }
      }

      deliveryOptions {
        _id
        defaultDeliveryDate
        deliveryDate
        blockedDeliveryDaysIndexes
        availableDeliveryDates
        blockedDeliveryDates

        deliveryOption {
          optionId
          locationId
          locationOptionId
        }
        isSelected
        productType {
          _id
          readableId
          name {
            ...TranslationFragment
          }
          subTitle {
            ...TranslationFragment
          }
          description {
            ...TranslationFragment
          }
          icon
          iconColor
          hideAtProduct
          isDefault
          cutOffTime {
            ...CutOffTimeFragment
          }
        }
        deliveryAddress {
          zip
          city
          houseNumber
          street
          country
          lines
        }
        deliveryTerms {
          discount
          deliveryFee {
            value
            description {
              en
              de
            }
          }
          minSurcharge {
            value
            description {
              en
              de
            }
          }
        }
        vendorId
        totalPrice
        totalItems
        totalQty
      }

      aiOrderData {
        documentId
        message
        orderedPersonName
        aiConfidenceLevel
      }
      createdAt
      customer {
        _id
        clientId
        vendorClientId
        contactData {
          businessName
          street
          houseNumber
          zip
          city
          image
          website
          country
          label
        }
        contacts {
          _id
          name
          role
          phone
          additionalPhones
          email
          inviteLink
          registrationCode
          status
          userMeta {
            name
            email
          }
          disabled
        }
      }
      customerId
      documents {
        _id
        type
        documentNumber
        documentName
        url
        createdAt
        deletedAt
      }
      orderNumber
      receivedAt
      relatedOrders {
        _id
        orderNumber
      }
      sender {
        email
        phoneNumber
        meta {
          subject
          email
          ipAddress
          messageId
          message
        }
      }
      sourceType
      status
      vendorId
    }
  }
`;

export const UPDATE_INCOMING_ORDER_DELIVERY_DATE = gql`
  mutation updateIncomingOrderDeliveryDate(
    $orderId: String!
    $deliveryOptionId: String!
    $date: String!
  ) {
    updateIncomingOrderDeliveryDate(
      orderId: $orderId
      deliveryOptionId: $deliveryOptionId
      date: $date
    ) {
      _id
      customerId
      orderNumber
      deliveryOptions {
        _id
        availableDeliveryDates
        blockedDeliveryDates
        blockedDeliveryDaysIndexes
        defaultDeliveryDate
        deliveryDate
        deliveryOption {
          optionId
          locationId
          locationOptionId
        }
      }
    }
  }
`;

export const UPDATE_INCOMING_ORDER_DELIVERY_METHOD = gql`
  mutation updateIncomingOrderDeliveryMethod(
    $orderId: String!
    $deliveryOptionId: String!
    $optionId: String
    $locationId: String
    $locationOptionId: String
  ) {
    updateIncomingOrderDeliveryMethod(
      orderId: $orderId
      deliveryOptionId: $deliveryOptionId
      optionId: $optionId
      locationId: $locationId
      locationOptionId: $locationOptionId
    ) {
      _id
      customerId
      orderNumber
      deliveryOptions {
        _id
        availableDeliveryDates
        blockedDeliveryDates
        blockedDeliveryDaysIndexes
        defaultDeliveryDate
        deliveryAddress {
          zip
          city
          houseNumber
          street
          country
          lines
        }
        deliveryDate
        deliveryOption {
          optionId
          locationId
          locationOptionId
        }
        extraDeliveryDates
        message
        productType {
          readableId
          _id
          name {
            en
            de
            fr
            es
            ve
          }
        }
      }
    }
  }
`;

export const UPDATE_INCOMING_ORDER_STATUS = gql`
  mutation UpdateIncomingOrderStatus($orderId: String!, $status: OrderStatus!) {
    updateIncomingOrderStatus(orderId: $orderId, status: $status) {
      _id
      customerId
      orderNumber
      status
    }
  }
`;

export const ACCEPT_INCOMING_ORDER = gql`
  mutation acceptIncomingOrder(
    $orderId: String!
    $deliveryOptionIds: [String!]
    $confirmationType: IncomingOrderConfirmationType
  ) {
    acceptIncomingOrder(
      orderId: $orderId
      deliveryOptionIds: $deliveryOptionIds
      confirmationType: $confirmationType
    ) {
      incomingOrder {
        _id
        status
        customerId
        orderNumber
      }
      errors
      orders {
        _id
        status
        orderNumber
        orderType
        orderedAt
        pdf
      }
    }
  }
`;
