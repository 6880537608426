import React from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { DatePicker } from '@material-ui/pickers';
import Typography from '@material-ui/core/Typography';

import CustomPopover from 'components/shared/Popover/CustomPopover';

const useMaterialStyles = makeStyles(({ spacing, typography, palette }) => ({
  orderDetailsCalendarTitle: {
    fontSize: typography.pxToRem(16),
    fontWeight: 600,
    marginTop: spacing(1),
  },
  orderDeliveryCalendarPaper: {
    maxWidth: 360,
    width: 360,
    height: 450,
    padding: spacing(3, 3, 1.5),
    borderRadius: 8,
    background: palette.background.paper,
    border: palette.border.grey,
  },
  customPopoverPaperClass: {
    borderRadius: 8,
    marginTop: spacing(0.5),
  },
  orderDeliveryCalendarButtons: {
    height: 40,
    borderRadius: 40,
    marginTop: spacing(1),
    marginRight: spacing(1),
  },
}));

function OrderDetailsDeliveryCalendar({
  anchorEl,
  selectedDate,
  optionId,
  handleDateChange,
  handleChangeDeliveryDate,
  disabledDates,
  handleClose,
}) {
  const classes = useMaterialStyles();
  const { t } = useTranslation();

  const minDate = moment();
  const maxDate = moment().add(3, 'M');

  const isOpen = Boolean(anchorEl);

  return (
    <CustomPopover
      selectorIsOpen={isOpen}
      anchorEl={anchorEl}
      handleCloseSelector={handleClose}
      popoverContainerStyling={classes.orderDeliveryCalendarPaper}
      customPopoverPaperClass={classes.customPopoverPaperClass}
    >
      <Grid container>
        <Typography className={classes.orderDetailsCalendarTitle} gutterBottom>
          {t('aiOrders.delivery date')}
        </Typography>
        <DatePicker
          variant="static"
          orientation="portrait"
          value={selectedDate}
          onChange={date => handleDateChange(date.toISOString(), optionId)}
          disableToolbar
          maxDate={maxDate}
          minDate={minDate}
          shouldDisableDate={date => {
            if (!disabledDates) return false;
            return (
              disabledDates.includes(date.day()) ||
              disabledDates.includes(date.toISOString())
            );
          }}
        />

        <Grid container>
          <Button
            variant="outlined"
            className={classes.orderDeliveryCalendarButtons}
            onClick={handleClose}
          >
            {t('common.cancel')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.orderDeliveryCalendarButtons}
            onClick={() => handleChangeDeliveryDate(selectedDate, optionId)}
          >
            {t('common.save')}
          </Button>
        </Grid>
      </Grid>
    </CustomPopover>
  );
}

OrderDetailsDeliveryCalendar.defaultProps = {
  selectedDate: new Date(Date.now()),
  anchorEl: undefined,
  disabledDates: null,
};

OrderDetailsDeliveryCalendar.propTypes = {
  anchorEl: PropTypes.object,
  selectedDate: PropTypes.any,
  optionId: PropTypes.string.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  handleChangeDeliveryDate: PropTypes.func.isRequired,
  disabledDates: PropTypes.array,
  handleClose: PropTypes.func.isRequired,
};

export default OrderDetailsDeliveryCalendar;
