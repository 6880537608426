import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { ReactComponent as CalendarIcon } from 'assets/aiOrders/calendar.svg';

import OrderDetailsDeliveryCalendar from 'components/AIOrderReview/OrderDetailsDeliveryCalendar';
import getProductTypeTitleAndColor from 'helpers/getProductTypeTitle';

const useStyles = makeStyles(({ spacing, palette }) => ({
  orderDetailMainPaper: {
    width: '100%',
    height: 40,
    border: palette.border.grey,
    padding: spacing(1, 1.5),
    display: 'flex',
    alignItems: 'center',
    background: palette.background.paper,
    borderRadius: 8,
    boxSizing: 'border-box',
    marginBottom: spacing(2),
    overflow: 'hidden',
    flexWrap: 'nowrap',
  },
  orderDetailsCalendarIcon: {
    marginRight: spacing(1),
  },
  clickablePaper: {
    cursor: 'pointer',
    '&:hover': {
      background: palette.action.hover,
    },
  },
  calendarPaper: {
    marginLeft: spacing(2),
  },
  productTypePreview: {
    height: 20,
    minWidth: 20,
    borderRadius: '50%',
    margin: spacing(0, 1),
  },
}));

export default function OrderDetailsProductType({
  deliveryOption,
  handleUpdateIncomingOrderDeliveryDate,
}) {
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  const [calendarAnchorEl, setCalendarAnchorEl] = useState(null);
  const [selectedDate, setSelectedDate] = useState(
    deliveryOption.deliveryDate || new Date().toISOString()
  );

  const handleOpenCalendar = event => {
    setCalendarAnchorEl(event.currentTarget);
  };

  const handleCloseCalendar = () => {
    setCalendarAnchorEl(null);
  };

  const handleChangeDeliveryDate = (newDate, optionId) => {
    handleUpdateIncomingOrderDeliveryDate(newDate, optionId);
    handleCloseCalendar();
  };

  if (!deliveryOption) return null;

  const { _id: optionId, deliveryDate, blockedDeliveryDates } = deliveryOption;

  const { productTypeName, color } = getProductTypeTitleAndColor(
    deliveryOption,
    t,
    i18n
  );

  return (
    <Grid container item xs={12} justifyContent="space-between" wrap="nowrap">
      <Grid
        container
        className={classes.orderDetailMainPaper}
        alignItems="center"
        justifyContent="space-between"
      >
        <div
          style={{ background: color }}
          className={classes.productTypePreview}
        />

        <Typography variant="body2" noWrap>
          {productTypeName}
        </Typography>
      </Grid>
      <Grid
        className={classNames(
          classes.orderDetailMainPaper,
          classes.clickablePaper,
          classes.calendarPaper
        )}
        role="button"
        onClick={handleOpenCalendar}
      >
        <CalendarIcon className={classes.orderDetailsCalendarIcon} />
        <Typography variant="body2" noWrap>
          {moment(deliveryDate).format('MMMM Do YYYY')}
        </Typography>
      </Grid>
      <OrderDetailsDeliveryCalendar
        anchorEl={calendarAnchorEl}
        selectedDate={selectedDate}
        optionId={optionId}
        handleDateChange={setSelectedDate}
        handleClose={handleCloseCalendar}
        handleChangeDeliveryDate={handleChangeDeliveryDate}
        disabledDates={blockedDeliveryDates}
      />
    </Grid>
  );
}

OrderDetailsProductType.propTypes = {
  deliveryOption: PropTypes.object,
  handleUpdateIncomingOrderDeliveryDate: PropTypes.func.isRequired,
};

OrderDetailsProductType.defaultProps = {
  deliveryOption: {},
};
