import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/styles';
import { CircularProgress } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

import { getCustomerAddress } from 'helpers/getCustomerAddress';
import { GET_VENDOR_DELIVERY_OPTIONS } from 'graphql/query/vendors';
import { useQuery } from '@apollo/client';
import { activeAccountIdVar } from 'graphql/apolloReactiveVariables';
import OrderDetailsCustomerSearch from './OrderDetailsCustomerSearch';
import OrderDetailsProductType from './OrderDetailsProductTypes';

const useStyles = makeStyles(({ spacing, palette }) => ({
  orderDetailsMainInfoContainer: {
    marginTop: spacing(1),
  },
  orderDetailMainPaper: {
    width: '100%',
    height: 40,
    border: palette.border.grey,
    padding: spacing(1, 1.5),
    display: 'flex',
    alignItems: 'center',
    background: palette.background.paper,
    borderRadius: 8,
    boxSizing: 'border-box',
    marginBottom: spacing(2),
  },
  orderDetailsCalendarIcon: {
    marginRight: spacing(1),
  },
  clickablePaper: {
    cursor: 'pointer',
    '&:hover': {
      background: palette.action.hover,
    },
  },
  deliveryMethod: {
    marginLeft: spacing(2),
  },
  updateCustomerLoader: {
    marginLeft: spacing(2),
  },
  calendarPaper: {
    marginLeft: spacing(2),
  },
  productTypePreview: {
    height: 20,
    width: 20,
    borderRadius: '50%',
    marginRight: spacing(1),
  },
}));

export default function OrderDetailsMainInfo({
  customer,
  sender,
  deliveryOptions,
  orderComment,
  isUpdateCustomerLoading,
  handleUpdateIncomingOrderCustomer,
  handleUpdateIncomingOrderDeliveryDate,
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  const vendorId = activeAccountIdVar();

  const [customerAnchorEl, setCustomerAnchorEl] = useState(null);
  const [address, setAddress] = useState('');

  const { data: vendorDeliveryOptionsData } = useQuery(
    GET_VENDOR_DELIVERY_OPTIONS,
    {
      variables: {
        vendorId,
      },
      fetchPolicy: 'cache-and-network',
    }
  );

  const handleOpenCustomerSearch = event => {
    setCustomerAnchorEl(event.currentTarget);
  };

  const handleCloseCustomerSearch = () => {
    setCustomerAnchorEl(null);
  };

  const handleUpdateCustomer = customerId => {
    handleUpdateIncomingOrderCustomer(customerId);
    handleCloseCustomerSearch();
  };

  useEffect(() => {
    if (customer?.contactData) {
      setAddress(getCustomerAddress(customer?.contactData));
    }
  }, [customer]);

  const orderSender = sender?.email || sender?.phoneNumber;

  // DEMO: to be used for Pickup or Default delivery methods
  // const allAvailableDeliveryOptions =
  //   vendorDeliveryOptionsData?.vendorDeliveryOptions || [];

  // console.log('ORDER REVIEW ---> CUSTOMER', {
  //   customer,
  //   sender,
  //   contactData,
  //   deliveryOptions,
  //   orderComment,
  //   allAvailableDeliveryOptions,
  //   t,
  // });

  return (
    <Grid
      className={classes.orderDetailsMainInfoContainer}
      container
      item
      xs={12}
      direction="column"
    >
      <Grid container item xs={12} justifyContent="space-between" wrap="nowrap">
        <Grid container item xs={12}>
          <Typography variant="caption" color="textSecondary" gutterBottom>
            {t('aiOrders.customer')}
          </Typography>
          <Grid
            className={classNames(
              classes.orderDetailMainPaper,
              classes.clickablePaper
            )}
            role="button"
            onClick={handleOpenCustomerSearch}
          >
            <Typography variant="body2" noWrap>
              {customer?.vendorClientId || `Customer not found`}{' '}
              {/* {orderSender && `- ${orderSender}`} */}
            </Typography>
            {isUpdateCustomerLoading && (
              <Grid className={classes.updateCustomerLoader}>
                <CircularProgress size={20} color="primary" />
              </Grid>
            )}
          </Grid>
        </Grid>

        {/* DEMO: add delivery methods here, pickup or default */}
        {/* <Grid className={classes.deliveryMethod} container item xs={3}>
          <Typography variant="caption" color="textSecondary" gutterBottom>
            {t('aiOrders.requested delivery')}
          </Typography>
          <Grid
            className={classNames(
              classes.orderDetailMainPaper,
              classes.clickablePaper
            )}
            role="button"
            onClick={handleOpenCalendar}
          >
            <CalendarIcon className={classes.orderDetailsCalendarIcon} />
            <Typography variant="body2" noWrap>
              {moment(deliveryDate).format('MMMM Do YYYY')}
            </Typography>
          </Grid>
        </Grid> */}
      </Grid>

      <Grid container item xs={12}>
        <Typography variant="caption" color="textSecondary" gutterBottom>
          {t('aiOrders.location')}
        </Typography>
        <Grid className={classes.orderDetailMainPaper}>
          <Tooltip title={address}>
            <Typography variant="body2" noWrap>
              {address}
            </Typography>
          </Tooltip>
          {isUpdateCustomerLoading && (
            <Grid className={classes.updateCustomerLoader}>
              <CircularProgress size={20} color="primary" />
            </Grid>
          )}
        </Grid>
      </Grid>

      {deliveryOptions?.length > 0 && (
        <Grid container item xs={12}>
          <Grid container item xs={6}>
            <Typography variant="caption" color="textSecondary" gutterBottom>
              {t('product types.name')}
            </Typography>
          </Grid>
          <Grid container item xs={6}>
            <Typography variant="caption" color="textSecondary" gutterBottom>
              {t('aiOrders.requested delivery')}
            </Typography>
          </Grid>

          {deliveryOptions.map(deliveryOption => (
            <OrderDetailsProductType
              key={deliveryOption._id}
              deliveryOption={deliveryOption}
              handleUpdateIncomingOrderDeliveryDate={
                handleUpdateIncomingOrderDeliveryDate
              }
            />
          ))}
        </Grid>
      )}

      <Grid container item xs={12}>
        <Typography variant="caption" color="textSecondary" gutterBottom>
          {t('aiOrders.comments')}
        </Typography>
        <Grid className={classes.orderDetailMainPaper}>
          <Tooltip title={orderComment}>
            <Typography variant="body2" noWrap>
              {orderComment}
            </Typography>
          </Tooltip>
        </Grid>
      </Grid>

      <OrderDetailsCustomerSearch
        anchorEl={customerAnchorEl}
        handleClose={handleCloseCustomerSearch}
        handleSetCustomer={handleUpdateCustomer}
      />
    </Grid>
  );
}

OrderDetailsMainInfo.propTypes = {
  customer: PropTypes.object,
  sender: PropTypes.object,
  deliveryOptions: PropTypes.array,
  orderComment: PropTypes.string,
  isUpdateCustomerLoading: PropTypes.bool.isRequired,
  handleUpdateIncomingOrderCustomer: PropTypes.func.isRequired,
  handleUpdateIncomingOrderDeliveryDate: PropTypes.func.isRequired,
};

OrderDetailsMainInfo.defaultProps = {
  customer: {},
  sender: {},
  deliveryOptions: [],
  orderComment: '',
};
