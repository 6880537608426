import React from 'react';
import PropTypes from 'prop-types';

import MailRoundedIcon from '@material-ui/icons/MailOutlineRounded';
import { ReactComponent as PdfIcon } from 'assets/aiOrders/pdf.svg';
import { ReactComponent as AudioIcon } from 'assets/aiOrders/speaker.svg';
import { ReactComponent as XlsIcon } from 'assets/aiOrders/xls.svg';

const DOCUMENT_TYPES = {
  PDF: 'pdf',
  AUDIO: 'audio',
  XLS: 'xls',
  EMAIL: 'email',
};

const DocumentIconReducer = (documentType, ...props) => {
  switch (documentType) {
    case DOCUMENT_TYPES.PDF:
      return <PdfIcon {...props} />;

    case DOCUMENT_TYPES.AUDIO:
      return <AudioIcon {...props} />;

    case DOCUMENT_TYPES.XLS:
      return <XlsIcon {...props} />;

    case DOCUMENT_TYPES.EMAIL:
      return <MailRoundedIcon {...props} />;

    default:
      return <MailRoundedIcon {...props} />;
  }
};

DocumentIconReducer.propTypes = {
  documentType: PropTypes.string,
  props: PropTypes.object,
};

DocumentIconReducer.defaultProps = {
  documentType: DOCUMENT_TYPES.EMAIL,
  props: {},
};

export default DocumentIconReducer;
