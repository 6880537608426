import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import ConfirmIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import CancelIcon from '@material-ui/icons/CancelOutlined';
import Collapse from '@material-ui/core/Collapse';

import OrdersTableRowCells from 'components/OrdersTable/OrdersTableRowCells';
import { useSharedTableStyles } from 'components/shared/styles/sharedTableStyles';
import DetailPanel from './DetailPanel';

const useStyles = makeStyles(theme => ({
  tableCellDetailPanel: {
    padding: 0,
  },
  cancelButton: {
    color: theme.palette.error.main,
  },
  confirmButton: {
    color: theme.palette.success.main
  },
  cancelRow: {
    background: theme.palette.type === 'dark' ?
      theme.palette.action.disabledBackground
      : theme.palette.background.default
  },
  actionsCell: {
    maxWidth: 170
  }
}));

const MONDU = 'mondu';
const STATUS_DRAFT = 'draft';

const OrdersTableRow = React.memo(({
  rowData,
  acceptOrder,
  cancelOrder,
  isCanceled,
  isPending,
}) => {
  const classes = useStyles();
  const sharedTableStyles = useSharedTableStyles();
  const { t } = useTranslation();

  const [isPanelOpen, setPanelOpen] = React.useState(false);

  const isPendingOrderMondu = rowData?.paymentMethod?.name === MONDU && rowData?.status !== STATUS_DRAFT;

  return (
    <>
      <TableRow
        className={classNames(
          sharedTableStyles.tableBodyRowV2,
          isCanceled && classes.cancelRow)}
        data-attr="table-body-row"
      >
        {/* <TableCell className={isPendingOrderMondu ? classNames(sharedTableStyles.tableCell, sharedTableStyles.expandMoreCell) : ''}>
          { isPendingOrderMondu &&
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setPanelOpen(!isPanelOpen)}
          >
            {isPanelOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>}
        </TableCell> */}
        <OrdersTableRowCells
          rowData={rowData}
          isPendingOrderMondu={isPendingOrderMondu}
          setPanelOpen={setPanelOpen}
          isPanelOpen={isPanelOpen}
        />
        <TableCell className={classNames(sharedTableStyles.tableCell, classes.actionsCell)}>
          {isPending && (
            <Grid container wrap="nowrap" alignItems="center">
              <IconButton
                className={classNames(
                  sharedTableStyles.tableActionButton,
                  classes.confirmButton
                )}
                onClick={() => acceptOrder(rowData)}
                title={t('orders.confirm')}
                size="small"
              >
                <ConfirmIcon/>
              </IconButton>
              <IconButton
                className={classNames(
                  sharedTableStyles.tableActionButton,
                  classes.cancelButton
                )}
                onClick={() => cancelOrder(rowData)}
                title={t('orders.order title')}
                size="small"
              >
                <CancelIcon/>
              </IconButton>
            </Grid>
          )}
        </TableCell>
      </TableRow>
      {isPendingOrderMondu && (
        <TableRow>
          <TableCell className={classes.tableCellDetailPanel} colSpan={13}>
            <Collapse in={isPanelOpen} timeout="auto" unmountOnExit>
              <DetailPanel
                isEditModeActive
                orderId={rowData._id}
                order={rowData}
                historyData={rowData?.paymentMethod?.documents ?? []}
                bic={rowData?.paymentMethod?.paymentInfo?.bic}
                receiver={rowData?.paymentMethod?.paymentInfo?.receiver}
                key={`order-detail-${rowData._id}`}
              />
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
},
(prevProps, nextProps) => isEqual(prevProps.rowData, nextProps.rowData));

OrdersTableRow.propTypes = {
  rowData: PropTypes.object.isRequired,
  acceptOrder: PropTypes.func.isRequired,
  cancelOrder: PropTypes.func.isRequired,
  isCanceled: PropTypes.bool.isRequired,
  isPending: PropTypes.bool.isRequired,
};
export default OrdersTableRow;
