/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { useLazyQuery, useMutation } from '@apollo/client';
import { SET_NOTIFICATION } from 'graphql/mutation/user';
import { GET_INCOMING_ORDERS } from 'graphql/query/incomingOrders';
import { UPDATE_ORDER_STATUS } from 'graphql/mutation/orders';

import { makeStyles, withStyles } from '@material-ui/styles';
import MuiButton from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import { ReactComponent as AcceptedIcon } from 'assets/accepted.svg';
import { ReactComponent as InstructionsIcon } from 'assets/instructions.svg';

import TableLayout from 'components/shared/TableLayout';
import CancelOrderModal from 'components/OrdersTable/CancelOrderModal';
import {
  INCOMING_ORDER_SORTING_OPTIONS,
  NOTIFICATION_STATUS,
  ORDER_STATUS,
  ROWS_PER_PAGE_OPTIONS,
  TABLE_SKELETON_TYPES,
} from 'helpers/constants';
import useMixPanel from 'helpers/useMixPanel';
import AIOrdersTableRow from 'components/OrdersTable/AIProcessedOrders/AIOrderTableRow';
import AIOrderTableStatusLegend from 'components/OrdersTable/AIProcessedOrders/AIOrderTableStatusLegend';
import SwitchTablesActionButtons from 'components/OrdersTable/SwitchTablesActionButtons';

const Button = withStyles(({ spacing }) => ({
  root: {
    height: 40,
    borderRadius: 40,
    marginLeft: spacing(1),
  },
}))(MuiButton);

const useStyles = makeStyles(({ palette }) => ({
  orderCustomerTitle: {
    width: 300,
  },
  statusLegend: {
    flexDirection: 'row-reverse',
    textAlign: 'center',
  },
  actionButton: {
    background: palette.background.paper,
  },
  numberOfProducts: {
    textAlign: 'center',
  },
}));

export default function AIProcessedOrders() {
  const { t } = useTranslation();
  const classes = useStyles();
  const mixPanel = useMixPanel();

  const history = useHistory();
  const location = useLocation();
  const queries = queryString.parse(location.search);
  const currentPath = location.pathname;

  const [state, setState] = React.useState({
    page: 0,
    rowsPerPage: 10,
    sortBy: INCOMING_ORDER_SORTING_OPTIONS.CreatedAt,
    sortOrder: -1,
  });
  const [selectedOrder, setSelectedOrder] = React.useState(null);
  const [isOpenCancelOrderModal, setCancelOrderModal] = React.useState(false);
  const [showSearchResults, setShowSearchResult] = React.useState(false);

  const [setNotification] = useMutation(SET_NOTIFICATION);
  // FIXME: here is order update from Order methods
  const [updateOrderStatus] = useMutation(UPDATE_ORDER_STATUS);

  const [
    getOrders,
    { loading: ordersLoading, error: ordersError, data },
  ] = useLazyQuery(GET_INCOMING_ORDERS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
  });

  const ordersData = data && data.getIncomingOrders;

  React.useEffect(() => {
    const pageParam = +queries.page;
    const rowsParam = +queries.rowsPerPage;
    const sortByParam = queries.sortBy;
    const sortOrderParam = +queries.sortOrder;

    getOrders({
      variables: {
        after: pageParam + 1 || state.page + 1,
        pageSize: rowsParam || state.rowsPerPage,
        sortBy: sortByParam || state.sortBy,
        sortOrder: sortOrderParam || state.sortOrder,
      },
    });

    if (Object.keys(queries).length) {
      setState({
        ...state,
        page: pageParam || state.page,
        rowsPerPage: rowsParam || state.rowsPerPage,
        sortBy: sortByParam || state.sortBy,
        sortOrder: sortOrderParam || state.sortOrder,
      });
    }
  }, []);

  React.useEffect(() => {
    const totalPages = ordersData?.totalPages ?? 0;
    const pageNumberExceeded = !!totalPages && totalPages <= state.page;
    const rowsPerPageExceeded = !ROWS_PER_PAGE_OPTIONS.includes(
      state.rowsPerPage
    );

    if (ordersData && (pageNumberExceeded || rowsPerPageExceeded)) {
      history.replace({
        pathname: currentPath,
        search: queryString.stringify({
          ...queryString.parse(location.search),
          page: state.page !== 0 && pageNumberExceeded ? 0 : state.page,
          rowsPerPage: rowsPerPageExceeded ? 10 : state.rowsPerPage,
        }),
      });
      window.location.reload();
    }
  }, [ordersData]);

  const handleChangePage = async (event, page, search) => {
    const searchPhrase = search || queries.search;
    if (
      ordersData &&
      (ordersData.hasNextPage || page + 1 < ordersData.totalPages)
    ) {
      try {
        await getOrders({
          variables: {
            searchPhrase,
            after: page + 1,
            pageSize: state.rowsPerPage,
            sortBy: state.sortBy,
            sortOrder: state.sortOrder,
          },
        });
      } catch (error) {
        console.error(error.message);
      }
    }
    setState({ ...state, page });
    history.push({
      pathname: currentPath,
      search: queryString.stringify({
        ...queryString.parse(location.search),
        page,
      }),
    });
  };

  const handleChangeRowsPerPage = async event => {
    const rowsPerPage = +event.target.value;
    try {
      await getOrders({
        variables: {
          pageSize: rowsPerPage,
          after: 1,
          sortBy: state.sortBy,
          sortOrder: state.sortOrder,
        },
      });
    } catch (error) {
      console.error(error.message);
    }
    setState({ ...state, rowsPerPage, page: 0 });
    history.push({
      pathname: currentPath,
      search: queryString.stringify({
        ...queryString.parse(location.search),
        rowsPerPage,
        page: 0,
      }),
    });
  };

  const handleSortRows = sortLabel => async () => {
    const isAsc = state.sortBy === sortLabel && state.sortOrder === 1;
    const sortOrder = isAsc ? -1 : 1;
    try {
      await getOrders({
        variables: {
          pageSize: state.rowsPerPage,
          after: 1,
          sortBy: sortLabel,
          sortOrder,
        },
      });
    } catch (error) {
      console.error(error.message);
    }
    setState({
      ...state,
      sortOrder,
      sortBy: sortLabel,
      page: 0,
    });
    history.push({
      pathname: currentPath,
      search: queryString.stringify({
        rowsPerPage: state.rowsPerPage,
        page: 0,
        sortBy: sortLabel,
        sortOrder,
      }),
    });
  };

  const showCancelOrderModal = order => {
    setSelectedOrder(order);
    setCancelOrderModal(true);
  };

  const handleAcceptOrder = async order => {
    try {
      await updateOrderStatus({
        variables: {
          orderId: order._id,
          status: ORDER_STATUS.ACCEPTED,
        },
        refetchQueries: [{ query: GET_INCOMING_ORDERS }],
      });
    } catch (error) {
      console.error(error.message);
    }
    mixPanel.track('Update Order Status', {
      orderId: order._id,
      internalOrderId: order.internalOrderId,
      status: ORDER_STATUS.ACCEPTED,
    });
    setNotification({
      variables: {
        timeout: 4000,
        message: t('orders.acceptOrder', { orderId: order.internalOrderId }),
        type: NOTIFICATION_STATUS.SUCCESS,
        isOpen: true,
      },
    });
  };

  const handleCancelOrder = async () => {
    try {
      await updateOrderStatus({
        variables: {
          orderId: selectedOrder._id,
          status: ORDER_STATUS.CANCELED,
        },
        refetchQueries: [{ query: GET_INCOMING_ORDERS }],
      });
      setNotification({
        variables: {
          timeout: 4000,
          message: t('orders.cancelOrder', {
            orderId: selectedOrder.internalOrderId,
          }),
          type: NOTIFICATION_STATUS.SUCCESS,
          isOpen: true,
        },
      });
    } catch (error) {
      console.error(error.message);
    }
  };

  const tableColumns = [
    {
      title: t('aiOrders.customer'),
      field: 'customer',
      columnStyles: classes.orderCustomerTitle,
      sortable: true,
    },
    {
      title: t('aiOrders.customer number'),
      field: 'customerNumber',
      sortable: true,
    },
    {
      title: t('aiOrders.ordered date and time'),
      field: 'orderedAt',
      sortable: true,
    },
    {
      title: t('aiOrders.delivery date'),
      field: 'deliveryDate',
      sortable: true,
    },
    // {
    //   title: t('aiOrders.number of products'),
    //   field: 'numberOfProducts',
    //   sortable: false,
    //   columnStyles: classes.numberOfProducts,
    // },
    // {
    //   title: t('aiOrders.confidenceLevel'),
    //   field: 'deadline',
    //   sortable: false,
    // },
    {
      title: t('aiOrders.status'),
      field: 'status',
      sortable: true,
      titleCustomNode: <AIOrderTableStatusLegend />,
      columnStyles: classes.statusLegend,
    },
  ];

  const { page, rowsPerPage, sortBy, sortOrder } = state;

  const handleSearchBar = async searchPhrase => {
    if (!showSearchResults) {
      setShowSearchResult(true);
    }
    setState({ ...state, page: 0 });
    try {
      await getOrders({
        variables: {
          searchPhrase,
          pageSize: state.rowsPerPage,
          after: 1,
          sortOrder,
        },
      });
    } catch (error) {
      console.error('SEARCH_CUSTOMERS_ERROR', error.message);
    }
  };

  const handleResetSearch = () => {
    setShowSearchResult(false);
    handleSearchBar('');
  };

  const hasError = ordersError || (!ordersLoading && !ordersData);
  console.log('AI___ORDERS___', { ordersData });

  return (
    <>
      <TableLayout
        withTableLayoutV2Styles
        title={t('aiOrders.title')}
        titleDirection="row"
        // subtitleNode={
        //   <Grid container alignItems="center" justifyContent="flex-end">
        //     <Button
        //       className={classes.actionButton}
        //       variant="outlined"
        //       startIcon={<InstructionsIcon />}
        //       onClick={() => history.push('/orders/ai-instructions')}
        //     >
        //       {t('aiOrders.instructions for ai')}
        //     </Button>
        //     <Button
        //       className={classes.actionButton}
        //       variant="outlined"
        //       startIcon={<AcceptedIcon />}
        //       onClick={() => history.push('/orders/ai-auto-accept-rules')}
        //     >
        //       {t('aiOrders.auto accept rules')}
        //     </Button>
        //   </Grid>
        // }
        columns={tableColumns}
        skeletonType={TABLE_SKELETON_TYPES.ORDERS}
        emptyTableData={hasError || !ordersData?.totalResults}
        emptyTableDataMessage={t('orders.empty orders')}
        tableIsLoading={ordersLoading}
        // DEMO: Disable action column to hide reprocess button, etc.
        // withActions
        withSearchBar
        headerActionsBeforeSearchBar={
          <SwitchTablesActionButtons isAiOrdersActive />
        }
        searchPlaceholder={t('orders.search placeholder')}
        handleSearchBar={handleSearchBar}
        handleShowSearchResult={handleResetSearch}
        // headerActions={
        //   <Grid container alignItems="center" justifyContent="flex-end">
        //     <Button
        //       className={classes.actionButton}
        //       variant="outlined"
        //       endIcon={<ExpandMoreOutlinedIcon />}
        //     >
        //       {t('aiOrders.filter by')}:
        //     </Button>
        //     <Button
        //       variant="contained"
        //       color="primary"
        //       onClick={() => history.push('/orders/ai-order-review')}
        //     >
        //       {t('aiOrders.start reviewing')}
        //     </Button>
        //   </Grid>
        // }
        page={page}
        sortBy={sortBy}
        sortOrder={sortOrder}
        handleSortRows={handleSortRows}
        rowsPerPage={rowsPerPage}
        totalResults={ordersData?.totalResults ?? 0}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      >
        {ordersData?.results?.map(rowData => (
          <AIOrdersTableRow
            key={rowData._id}
            rowData={rowData}
            handleCancelOrder={showCancelOrderModal}
            handleAcceptOrder={handleAcceptOrder}
          />
        ))}
      </TableLayout>
      <CancelOrderModal
        isOpen={isOpenCancelOrderModal}
        onConfirm={handleCancelOrder}
        handleCloseModal={() => setCancelOrderModal(false)}
      />
    </>
  );
}
