import i18next from 'i18next';

const parseValidationErrors = error => {
  let errorMsg;
  try {
    const errJson = JSON.parse(error?.message);
    if (errJson?.length) {
      errorMsg = errJson?.map(m => m?.[i18next.language])?.join('\n');
    }
    return errorMsg;
  } catch (e) {
    return error;
  }
};

export default parseValidationErrors;
