import { gql } from '@apollo/client';
import {
  CUTOFFTIME_FRAGMENT,
  TRANSLATION_FRAGMENT,
  VARIANT_DEAL_DETAILS_FRAGMENT,
  VARIANT_FRAGMENT,
} from 'graphql/fragments';

export const GET_INCOMING_ORDERS = gql`
  query getIncomingOrders(
    $search: String
    $filters: IncomingOrderFilterInput
    $sortBy: IncomingOrderSortingOption
    $sortOrder: Int
    $pageSize: Int
    $after: Int
  ) {
    getIncomingOrders(
      search: $search
      filters: $filters
      sortBy: $sortBy
      sortOrder: $sortOrder
      pageSize: $pageSize
      after: $after
    ) {
      page
      totalPages
      hasNextPage
      hasPrevPage
      nextPage
      prevPage
      totalResults
      results {
        _id
        customerId
        customer {
          _id
          clientId
          vendorClientId
          contactData {
            businessName
            street
            houseNumber
            zip
            city
            image
            website
            country
            label
          }
        }
        orderNumber
        status
        sourceType
        sender {
          email
          phoneNumber
          meta {
            subject
            email
            ipAddress
            messageId
            message
          }
        }
        receivedAt
        products {
          totalResults
        }
        aiOrderData {
          documentId
          message
          orderedPersonName
          aiConfidenceLevel
        }
        relatedOrders {
          _id
          orderNumber
        }
        createdAt
      }
    }
  }
`;

export const GET_INCOMING_ORDER_BY_ID = gql`
  ${VARIANT_FRAGMENT}
  ${VARIANT_DEAL_DETAILS_FRAGMENT}
  ${CUTOFFTIME_FRAGMENT}
  ${TRANSLATION_FRAGMENT}
  query getIncomingOrderById(
    $_id: String!
    $after: Int
    $pageSize: Int
    $search: String
    $sortBy: ProductSortingOption
    $sortOrder: Int
  ) {
    getIncomingOrderById(
      _id: $_id
      after: $after
      pageSize: $pageSize
      search: $search
      sortBy: $sortBy
      sortOrder: $sortOrder
    ) {
      _id
      products {
        page
        totalPages
        hasNextPage
        nextPage
        hasPrevPage
        prevPage
        totalResults
        products {
          _id
          selectedProduct {
            _id
            variantId
            vendorId
            baseUnit
            cartQuantityStep
            deliveryOptionId
            unitsInPackaging
            message
            price
            productType
            qty
            attributesKey
            minOrderQuantity
            productCopy {
              name
              vendorSpecificId
            }
            variantCopy {
              ...VariantFragment
              ...VariantDealDetailsFragment
            }
          }
          allMatchedProducts {
            product {
              _id
              qty
              baseUnit
              attributesKey
              variantId
              unitsInPackaging
              vendorId
              productType
              price
              deliveryOptionId
            }
            packaging
            confidenceLevel
          }
          matchingResult {
            confidenceLevel
            customerSpecificRule
            product {
              _id
              variantId
              productType
              attributesKey
              baseUnit
              deliveryOptionId
              price
              qty
              unitsInPackaging
              vendorId
            }
            reason
            type
            vendorSpecificRule
          }
          parsedProduct {
            attributesKey
            documentCoordinates {
              x
              y
              page
            }
            name
            packaging
            qty
            vendorSpecificId
          }
        }
      }

      deliveryOptions {
        _id
        defaultDeliveryDate
        deliveryDate
        blockedDeliveryDaysIndexes
        availableDeliveryDates
        blockedDeliveryDates

        deliveryOption {
          optionId
          locationId
          locationOptionId
        }
        isSelected
        productType {
          _id
          readableId
          name {
            ...TranslationFragment
          }
          subTitle {
            ...TranslationFragment
          }
          description {
            ...TranslationFragment
          }
          icon
          iconColor
          hideAtProduct
          isDefault
          cutOffTime {
            ...CutOffTimeFragment
          }
        }
        deliveryAddress {
          zip
          city
          houseNumber
          street
          country
          lines
        }
        deliveryTerms {
          discount
          deliveryFee {
            value
            description {
              en
              de
            }
          }
          minSurcharge {
            value
            description {
              en
              de
            }
          }
        }
        vendorId
        totalPrice
        totalItems
        totalQty
      }

      aiOrderData {
        documentId
        message
        orderedPersonName
        aiConfidenceLevel
      }
      createdAt
      customer {
        _id
        clientId
        vendorClientId
        contactData {
          businessName
          street
          houseNumber
          zip
          city
          image
          website
          country
          label
        }
        contacts {
          _id
          name
          role
          phone
          additionalPhones
          email
          inviteLink
          registrationCode
          status
          userMeta {
            name
            email
          }
          disabled
        }
      }
      customerId
      documents {
        _id
        type
        documentNumber
        documentName
        url
        createdAt
        deletedAt
      }
      orderNumber
      receivedAt
      relatedOrders {
        _id
        orderNumber
      }
      sender {
        email
        phoneNumber
        meta {
          subject
          email
          ipAddress
          messageId
          message
        }
      }
      sourceType
      status
      vendorId
    }
  }
`;
