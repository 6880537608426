import generateStringToHexColor from './generateStringToHexColor';

export default function getProductTypeTitleAndColor(deliveryOption, t, i18n) {
  const productTypeReadableId =
    deliveryOption?.productType?.readableId === '__default__'
      ? t('product types.default')
      : deliveryOption?.productType?.readableId;

  const color = generateStringToHexColor(
    deliveryOption?.productType?.readableId
  );

  let productTypeName =
    deliveryOption?.productType?.name?.[i18n.language] || productTypeReadableId;
  if (productTypeName === '__default__') {
    productTypeName = t('product types.default');
  }

  if (productTypeReadableId !== productTypeName) {
    productTypeName = `${productTypeReadableId} - ${productTypeName}`;
  }

  return { productTypeName, color };
}
