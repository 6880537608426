/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { useLazyQuery } from '@apollo/client';
import { GET_ORDERS } from 'graphql/query/orders';

import { makeStyles } from '@material-ui/styles';

import { ROWS_PER_PAGE_OPTIONS } from 'helpers/constants';
import TempAiOrders from 'components/OrdersTable/AIProcessedOrders/tempData';

const useStyles = makeStyles(({ spacing }) => ({
  orderCustomerTitle: {
    width: 300,
  },
  actionButton: {
    height: 40,
    borderRadius: 40,
    marginLeft: spacing(1),
  },
}));

export default function AIAutoAcceptRules() {
  const { t } = useTranslation();
  const classes = useStyles();

  const history = useHistory();
  const location = useLocation();
  const queries = queryString.parse(location.search);
  const currentPath = location.pathname;

  const [state, setState] = React.useState({
    page: 0,
    rowsPerPage: 10,
    sortBy: 'orderedAt',
    sortOrder: -1,
  });
  const [showSearchResults, setShowSearchResult] = React.useState(false);

  const [
    getOrders,
    { loading: ordersLoading, error: ordersError, data },
  ] = useLazyQuery(GET_ORDERS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
  });

  const ordersData = data && TempAiOrders.vendorOrders;
  // const ordersData = data && data.vendorOrders;

  React.useEffect(() => {
    const pageParam = +queries.page;
    const rowsParam = +queries.rowsPerPage;
    const sortByParam = queries.sortBy;
    const sortOrderParam = +queries.sortOrder;

    getOrders({
      variables: {
        after: pageParam + 1 || state.page + 1,
        pageSize: rowsParam || state.rowsPerPage,
        sortBy: sortByParam || state.sortBy,
        sortOrder: sortOrderParam || state.sortOrder,
      },
    });

    if (Object.keys(queries).length) {
      setState({
        ...state,
        page: pageParam || state.page,
        rowsPerPage: rowsParam || state.rowsPerPage,
        sortBy: sortByParam || state.sortBy,
        sortOrder: sortOrderParam || state.sortOrder,
      });
    }
  }, []);

  React.useEffect(() => {
    const totalPages = ordersData?.totalPages ?? 0;
    const pageNumberExceeded = !!totalPages && totalPages <= state.page;
    const rowsPerPageExceeded = !ROWS_PER_PAGE_OPTIONS.includes(
      state.rowsPerPage
    );

    if (ordersData && (pageNumberExceeded || rowsPerPageExceeded)) {
      history.replace({
        pathname: currentPath,
        search: queryString.stringify({
          ...queryString.parse(location.search),
          page: state.page !== 0 && pageNumberExceeded ? 0 : state.page,
          rowsPerPage: rowsPerPageExceeded ? 10 : state.rowsPerPage,
        }),
      });
      window.location.reload();
    }
  }, [ordersData]);

  const handleChangePage = async (event, page, search) => {
    const searchPhrase = search || queries.search;
    if (
      ordersData &&
      (ordersData.hasNextPage || page + 1 < ordersData.totalPages)
    ) {
      try {
        await getOrders({
          variables: {
            searchPhrase,
            after: page + 1,
            pageSize: state.rowsPerPage,
            sortBy: state.sortBy,
            sortOrder: state.sortOrder,
          },
        });
      } catch (error) {
        console.error(error.message);
      }
    }
    setState({ ...state, page });
    history.push({
      pathname: currentPath,
      search: queryString.stringify({
        ...queryString.parse(location.search),
        page,
      }),
    });
  };

  const handleChangeRowsPerPage = async event => {
    const rowsPerPage = +event.target.value;
    try {
      await getOrders({
        variables: {
          pageSize: rowsPerPage,
          after: 1,
          sortBy: state.sortBy,
          sortOrder: state.sortOrder,
        },
      });
    } catch (error) {
      console.error(error.message);
    }
    setState({ ...state, rowsPerPage, page: 0 });
    history.push({
      pathname: currentPath,
      search: queryString.stringify({
        ...queryString.parse(location.search),
        rowsPerPage,
        page: 0,
      }),
    });
  };

  const handleSortRows = sortLabel => async () => {
    const isAsc = state.sortBy === sortLabel && state.sortOrder === 1;
    const sortOrder = isAsc ? -1 : 1;
    try {
      await getOrders({
        variables: {
          pageSize: state.rowsPerPage,
          after: 1,
          sortBy: sortLabel,
          sortOrder,
        },
      });
    } catch (error) {
      console.error(error.message);
    }
    setState({
      ...state,
      sortOrder,
      sortBy: sortLabel,
      page: 0,
    });
    history.push({
      pathname: currentPath,
      search: queryString.stringify({
        rowsPerPage: state.rowsPerPage,
        page: 0,
        sortBy: sortLabel,
        sortOrder,
      }),
    });
  };

  const tableColumns = [];

  const { page, rowsPerPage, sortBy, sortOrder } = state;

  const handleSearchBar = async searchPhrase => {
    if (!showSearchResults) {
      setShowSearchResult(true);
    }
    setState({ ...state, page: 0 });
    try {
      await getOrders({
        variables: {
          searchPhrase,
          pageSize: state.rowsPerPage,
          after: 1,
          sortOrder,
        },
      });
    } catch (error) {
      console.error('SEARCH_CUSTOMERS_ERROR', error.message);
    }
  };

  const handleResetSearch = () => {
    setShowSearchResult(false);
    handleSearchBar('');
  };

  const hasError = ordersError || (!ordersLoading && !ordersData);

  return 'AUTO_ACCEPT_RULES_TABLE';
}
