import { makeStyles } from '@material-ui/core/styles';

export const useSharedTableStyles = makeStyles(
  ({ spacing, palette, transitions, breakpoints }) => ({
    tableBodyRow: {
      height: 56,
      borderBottom: palette.border.lightGrey,
    },
    tableBodyRowV2: {
      height: 56,
      // '&[class*="tableBodyRowV2"]:nth-child(odd)': {
      //   backgroundColor: palette.background.mutedLight,
      // },
      '&:first-child': {
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        borderTop: `1px solid ${palette.background.mutedLight}`,
        '& td:first-child': {
          borderTopLeftRadius: 8,
        },
        '& td:last-child': {
          borderTopRightRadius: 8,
        },
        '& td': {
          borderTop: `1px solid ${palette.background.mutedLight}`,
        },
      },
      '&:last-child': {
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
        borderBottom: `1px solid ${palette.background.mutedLight}`,
        '& td:first-child': {
          borderBottomLeftRadius: 8,
        },
        '& td:last-child': {
          borderBottomRightRadius: 8,
        },
        '& td': {
          borderBottom: `1px solid ${palette.background.mutedLight}`,
        },
      },
      '& td:first-child': {
        borderLeft: `1px solid ${palette.background.mutedLight}`,
      },
      '& td:last-child': {
        borderRight: `1px solid ${palette.background.mutedLight}`,
      },
    },
    tableCell: {
      padding: spacing(1, 1.5),
      maxWidth: 150,
      color: palette.secondary.textBlack,
      borderBottom: 'none',
    },
    tableLeftPadding: {
      paddingLeft: `${spacing(4)}px !important`,
    },
    actionButtonContainer: {
      width: 120,
    },
    tableActionButton: {
      minWidth: 0,
      width: 32,
      height: 32,
      boxShadow: 'none',
      borderRadius: '50%',
      padding: 0,
      margin: spacing(0, 0.5),
      backgroundColor: 'transparent',
    },
    tableActionButtonIcon: {
      width: 20,
      height: 20,
      color: palette.secondary.darkGray,
    },
    highlightRow: {
      animation: `$highlighting 1000ms ${transitions.easing.easeOut}`,
    },
    '@keyframes highlighting': {
      '0%': {
        backgroundColor: palette.primary.main,
      },
      '100%': {
        backgroundColor: palette.background.default,
      },
    },
    expandMoreCell: {
      width: 30,
      borderTop: 'none',
      paddingLeft: spacing(1),
      [breakpoints.up('lg')]: {
        paddingLeft: spacing(2),
      },
    },
    tabs: {
      margin: spacing(2, 0),
      '& .Mui-selected': {
        '& .MuiTab-wrapper': {
          backgroundColor: '#0F1217',
          color: '#fff',
        }
      },
      '& .MuiTabs-indicator': {
        display: 'none',
      },
      '& .MuiTab-root': {
        padding: spacing(0),
        maxWidth: 'none',
        minWidth: 'auto',
        marginRight: spacing(1),
        opacity: '1',
        justifyContent: 'flex-start'
      },
      '& .MuiTab-wrapper': {
        border: '1px solid #C7C9CC',
        borderRadius: '48px',
        padding: spacing(1, 2),
        backgroundColor: '#F6F6F6',
        color: '#0F1217',
        width: 'auto',
      },
    },
  })
);
